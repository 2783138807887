import React from "react";
import SetPassword from "../../../components/section-components/registration/SetPasswordPage";


function WLSetPassword() {
  return <>
    <SetPassword />
  </>
}

export default WLSetPassword;
