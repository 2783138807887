import React from "react";
import ChangePasswordForm from "./change-password-form";
import AppLayout from "../../../../components/layouts/app-layout";
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";

const ChangePassword = () => {
  const { t } = useTranslation();

  return (
    <AppLayout pageTitle={t("ChangePassword")} needAuth>
      <div className="page-wrapper page-width">
        <div className="row page-wrapper-without-top">
          <div className="col-12 col-lg-6 details-wrapper scrolled">
            <div style={{ maxHeight: '100vh', overflowY: 'auto', padding: '20px 10% 70px' }}>
              <div className="container" >
                <h4 className="page-heading">{t("ChangePassword")}</h4>
                <ChangePasswordForm />
              </div>
            </div>
          </div>
          <div className="col-12 d-none d-lg-flex col-lg-6 bg-cover py-5 py-lg-0" style={{ background: `url(${publicUrl}assets/img/pattern.png) repeat` }}>
            <div className="img-container">
              <img src={publicUrl + 'assets/img/others/others/2.png'} alt="whitelabel" />
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default ChangePassword;
