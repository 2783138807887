import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useState } from 'react'
import LoadingData from '../../../../components/global-components/loading-data';
import NoData from '../../../../components/global-components/no-data';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { toast } from 'react-toastify';
import LoadingButton from '../../../../components/global-components/loading-btn';
import { useTranslation } from 'react-i18next';

const InterestsModal = ({ data, open, onClose, loadingData, projectName }) => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const exportToExcel = (projectName, data) => {
        setLoading(true);
        return new Promise(function (resolve, reject) {
            var req = new XMLHttpRequest();
            var url = '/assets/excel-templates/PROJECT_INTERESTS.xlsx';
            req.open("GET", url, true);
            req.responseType = "arraybuffer";
            req.onreadystatechange = function () {
                if (req.readyState === 4) {
                    if (req.status === 200) {
                        resolve(XlsxPopulate.fromDataAsync(req.response));
                    } else {
                        reject("Received a " + req.status + " HTTP code.");
                    }
                }
            };
            req.send();
        })
            .then(function (workbook) {
                let i = 7;
                // const arr = [...Array(30000).map((x, index) => ({ 'name': `user ${index}`, 'phone': `user ${index}`, 'unit': '' }))];
                if (data && data.length > 0) {
                    workbook.sheet(0).cell('A1').value(`${t("InterestedIn")} ${projectName}`);

                    data.map(line => {
                        workbook.sheet(0).cell(`A${i}`).value(line?.name);
                        workbook.sheet(0).cell(`B${i}`).value(line?.phone);
                        workbook.sheet(0).cell(`C${i}`).value(line?.unit || t("Undefined"));
                        i++;
                        return null;
                    });
                }
                return workbook.outputAsync();
            })
            .then(function (blob) {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.href = url;
                a.download = `${t("InterestedIn")} ${projectName}.xlsx`;
                setLoading(false);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
                toast.success(t("TheFileWasExportedSuccessfully"));
            })
            .catch(function (err) {
                console.log(err);
                toast.error(t("TheFileWasNotExported"));
                setLoading(false);
            });
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={open}
            className="custom-dialog model "
            onClose={onClose}>
            <DialogTitle>
                <div className="dialog-head">
                    <div>
                        <h2>{t("InterestsInThisProject")}</h2>
                    </div>
                    <div className="dialog-actions">
                        <span onClick={onClose} style={{ cursor: 'pointer' }}>
                            <Close />
                        </span>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className='row justify-content-start p-0'>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '400px' }}>
                        {!loadingData && data.length > 0 && (<>
                            <LoadingButton
                                loading={loading}
                                classes="primary-btn btn export-btn px-3 mb-3 py-3"
                                style={{ width: 'fit-content' }}
                                handleClick={() => exportToExcel(projectName, data)}
                                label={t("ExportToExcel")}
                                icon={<InsertDriveFileIcon />}
                            />
                        </>)}
                        <TableContainer className="table-container" component={Paper}>
                            <Table sx={{ minWidth: '100%' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="right">{t("Name")}</TableCell>
                                        <TableCell align="right">{t("PhoneNumber")}</TableCell>
                                        <TableCell align="right" style={{ minWidth: '150px' }}>{t("AdministrativeUnitModel")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loadingData ? <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    ><TableCell colSpan={5}><LoadingData /></TableCell></TableRow> :
                                        data.length > 0 ? data.map((row) => (
                                            <TableRow
                                                key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="right" component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="right">{row.phone}</TableCell>
                                                <TableCell align="right">{row.unit || t("Undefined")}</TableCell>
                                            </TableRow>
                                        )) :
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell colSpan={5}>
                                                    <NoData msg={t("ThereAreNoInterestsInThisProjectYet")} />
                                                </TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default InterestsModal;
