import React, { useState } from "react";
import SearchMapSection from "../../components/section-components/search-map";
import { useParams } from "react-router-dom";
import AppLayout from "../../components/layouts/app-layout";
import { useAuth } from "../../helpers/context";
import { useTranslation } from "react-i18next";

const SearchMap = () => {
  const { vendor } = useAuth();
  let { lat = "24.716199523004914", long = "46.671776478222675", sort = "createdAt", zoom = "11", listedFor = "3", propertyType = "all", page = "1" } = useParams();
  const [hideNavbar, setHideNavbar] = useState(false);
  const { t } = useTranslation();

  return (
    <AppLayout withoutNav={hideNavbar} pageTitle={vendor === 'amakkn' ? "البحث" : t("OurAds")}>
      <SearchMapSection
        propertyType={propertyType}
        listedFor={listedFor}
        lat={lat}
        long={long}
        sort={sort}
        zoom={zoom}
        page={page}
        setHideNavbar={setHideNavbar}
      />
    </AppLayout>
  );
}

export default SearchMap;
