import React, { useRef } from 'react'
import { useEffect } from 'react';
import { GreenCheck, RedClose } from '../../constants/icons';
import { useTranslation } from 'react-i18next';

export default function OnlineDetection() {
    const detectionRef = useRef();
    const onlineRef = useRef();
    const offlineRef = useRef();
    const { t } = useTranslation();
    
    useEffect(() => {
        window.addEventListener('offline', checkConnectionStatus);
        window.addEventListener('online', checkConnectionStatus);
        return () => {
            window.removeEventListener('offline', checkConnectionStatus);
            window.removeEventListener('online', checkConnectionStatus);
        }
    }, [])
    
    const checkConnectionStatus = () => {
        const isOnline = navigator.onLine;
        detectionRef.current.style.display = "flex";
        if (isOnline) {
            onlineRef.current.style.display = "flex";
            offlineRef.current.style.display = "none";
        } else {
            onlineRef.current.style.display = "none";
            offlineRef.current.style.display = "flex";
        }
        setTimeout(() => {
            if (isOnline)
                detectionRef.current.style.display = "none";
        }, 4000);
    };

    return (
        <div className='online-detection' ref={detectionRef}>
            <div className='status offline-status' ref={offlineRef}>
                <span>{t("NoNetworkConnection")}</span>
                <RedClose />
            </div>
            <div className='status online-status' ref={onlineRef}>
                <span>{t("NetworkConnectionReestablished")}</span>
                <GreenCheck />
            </div>
        </div>
    );
}
