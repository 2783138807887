import React, {
  useEffect,
  forwardRef,
  useImperativeHandle} from "react";
import { toast } from "react-toastify";
import ColorPicker from "./color-picker";
import { uploadWlFileBase64 } from "../../../../api/userApi";
import { InputLabel, MenuItem, Select, Slider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import { customTheme, useAuth } from "../../../../helpers/context";
import { ArrowBack } from "@mui/icons-material";
import LoadingButton from "../../../../components/global-components/loading-btn";
import { DEMO_VENDORS } from "../../../../constants";
import { useTranslation } from "react-i18next";

const ColorPalette = forwardRef(({CSS, setCSS, ...props}, ref) => {
  const { setTheme, vendor } = useAuth();
  const { t, i18n } = useTranslation();
  
  useImperativeHandle(ref, () => ({
    onSubmit() {
      onSubmit();
    },
    onReset() {
      onReset();
    },
  }));
  const onSubmit = () => {
    if (
      CSS.mainColorOne &&
      CSS.mainColorTwo &&
      CSS.paragraphColor &&
      CSS.headingColor &&
      CSS.btnColor &&
      CSS.bodyFontSize &&
      CSS.bodyFont
    ) {
      props.setLoading(true);
      let CSSText = CSSString.css.replace("{MainCOLOR1}", CSS.mainColorOne);
      CSSText = CSSText.replace("{MainCOLOR2}", CSS.mainColorTwo);
      CSSText = CSSText.replace(/{ParagraphCOLOR}/g, CSS.paragraphColor);
      CSSText = CSSText.replace("{HeadingCOLOR}", CSS.headingColor);
      CSSText = CSSText.replace("{BtnCOLOR}", CSS.btnColor);
      CSSText = CSSText.replace("{BodyFontSize}", CSS.bodyFontSize);
      CSSText = CSSText.replace("{BodyFont}", CSS.bodyFont);
      if (DEMO_VENDORS.includes(vendor)) {
        localStorage?.setItem(`palette-${vendor}`, JSON.stringify({
          mainColorOne: CSS.mainColorOne,
          mainColorTwo: CSS.mainColorTwo,
          headingColor: CSS.headingColor,
          paragraphColor: CSS.paragraphColor,
          btnColor: CSS.btnColor,
          bodyFont: CSS.bodyFont,
          bodyFontSize: CSS.bodyFontSize
        }));

        setTheme(customTheme(CSS.mainColorOne.trim(), CSS.bodyFont.trim()));
       
        var r = document.querySelector(':root');
        r.style.setProperty('--main-color-one', CSS.mainColorOne);
        r.style.setProperty('--main-color-two', CSS.mainColorTwo);
        r.style.setProperty('--heading-color', CSS.headingColor);
        r.style.setProperty('--paragraph-color', CSS.paragraphColor);
        r.style.setProperty('--btn-color', CSS.btnColor);
        r.style.setProperty('--body-font', CSS.bodyFont);
        r.style.setProperty('--body-font-size', CSS.bodyFontSize);
        
        props.setLoading(false);

      } else {
        let CSSFile = new File([CSSText], "colors.css", {
          type: "text/css",
        });
        let reader = new FileReader();
        reader.readAsDataURL(CSSFile);
        reader.onload = () => {
          let bs64 = reader.result;
          uploadWlFileBase64({
            base64Data: bs64,
            pathWithFileName: "/assets/css/colors.css",
            typeOfFile: "css",
            userId: props.token,
            language: i18n.language === 'ar' ? '0' : '1',
          }).then((resp) => {
            if (resp.resCode === 0) {
              if (props.goNext) {
                props.goNext();
              } else {
                toast.success(t("SavedSuccessfully"));
              }
              const link = resp.response.url;
              const mainStyle = document.getElementById("main-stylesheet");
              if (mainStyle)
                mainStyle.href = link;
              
              setTheme(customTheme(CSS.mainColorOne.trim(), CSS.bodyFont.trim()));
            } else {
              toast.error(resp.resStr);
            }
            props.setLoading(false);
          }).catch(e => props.setLoading(false));
        };
      }
      
    } else {
      toast.error(t("AnErrorOccurred"));
    }
  }

  const onReset = () => { }

  useEffect(() => {
    if (CSS.mainColorOne === "")
      setCSS({
        mainColorOne: getComputedStyle(document.body, null).getPropertyValue(
          "--main-color-one"
        ),
        mainColorTwo: getComputedStyle(document.body, null).getPropertyValue(
          "--main-color-two"
        ),
        headingColor: getComputedStyle(document.body, null).getPropertyValue(
          "--heading-color"
        ),
        paragraphColor: getComputedStyle(document.body, null).getPropertyValue(
          "--paragraph-color"
        ),
        btnColor: getComputedStyle(document.body, null).getPropertyValue(
          "--btn-color"
        ),
        bodyFontSize: getComputedStyle(document.body, null).getPropertyValue(
          "--body-font-size"
        ),
        bodyFont: getComputedStyle(document.body, null).getPropertyValue(
          "--body-font"
        ) ? getComputedStyle(document.body, null).getPropertyValue("--body-font").replace(/"/g, '') : '',
      });
  },// eslint-disable-next-line
    [CSS,onReset]);


  var CSSString = {
    css: [
      ":root {",
      "--main-color-one:{MainCOLOR1};",
      "--main-color-two:{MainCOLOR2};",
      "--font-color:{ParagraphCOLOR};",
      "--heading-color:{HeadingCOLOR};",
      "--paragraph-color:{ParagraphCOLOR};",
      "--btn-color:{BtnCOLOR};",
      // "--heading-font: 'Tajawal', sans-serif;",
      '--body-font:"{BodyFont}";',
      "--body-font-size:{BodyFontSize};}",
      // " --line-height30: 1.8;}",
    ].join(""),
  };


  return (
    <>
      <div className='colors-wrapper'>
        {CSS.mainColorOne ? (
          <ColorPicker
            name={t("PrimaryColor")}
            onChange={(color) => setCSS({ ...CSS, mainColorOne: color })}
            color={CSS.mainColorOne}
          />
        ) : (
          <></>
        )}

        {CSS.mainColorTwo && (
          <ColorPicker
            name={t("WebsiteBackgroundColor")}
            onChange={(color) => setCSS({ ...CSS, mainColorTwo: color })}
            color={CSS.mainColorTwo}
          />
        )}

        {CSS.headingColor && (
          <ColorPicker
            name={t("HeadingFontColor")}
            onChange={(color) => setCSS({ ...CSS, headingColor: color })}
            color={CSS.headingColor}
          />
        )}

        {CSS.paragraphColor && (
          <ColorPicker
            name={t("ParagraphFontColor")}
            onChange={(color) => setCSS({ ...CSS, paragraphColor: color })}
            color={CSS.paragraphColor}
          />
        )}

        {CSS.btnColor && (
          <ColorPicker
            name={t("ButtonsFontColor")}
            onChange={(color) => setCSS({ ...CSS, btnColor: color })}
            color={CSS.btnColor}
          />
        )}
      </div>
      <hr />
      <div className='colors-wrapper colors-wrapper2'>
        {CSS.bodyFont && (
          <>
            <div className="color-wrapper">
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>{t("FontType")}</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={CSS.bodyFont.trim() + ""}
                  defaultValue={CSS.bodyFont.trim() + ""}
                  size='small'
                  label={t("FontType")}
                  onChange={(e) => {
                    setCSS({ ...CSS, bodyFont: e.target.value });
                  }}>
                  <MenuItem value={"Dubai"} key={"Dubai"}>
                    Dubai
                  </MenuItem>
                  <MenuItem value={"Arial"} key={"Arial"}>
                    Arial
                  </MenuItem>
                  <MenuItem value={"Tajawal"} key={"Tajawal"}>
                    Tajawal
                  </MenuItem>
                  <MenuItem value={"Baloo Bhaijaan 2"} key={"Baloo Bhaijaan 2"}>
                    Baloo Bhaijaan 2
                  </MenuItem>
                  <MenuItem value={"Cairo"} key={"Cairo"}>
                    Cairo
                  </MenuItem>
                  <MenuItem value={"Amazon Ember"} key={"Amazon Ember"}>
                    Amazon Ember
                  </MenuItem>
                  <MenuItem value={"Roboto"} key={"Roboto"}>
                    Roboto
                  </MenuItem>
                  <MenuItem value={"sans-serif"} key={"sans-serif"}>
                    sans-serif
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </>
        )}
        {CSS.bodyFontSize && (
          <>
            <div className="color-wrapper">
              <div style={{
                width: "40px",
                height: "40px",
                borderRadius: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0 0 1px 1px rgba(0,0,0,.3)",
              }}>
                {CSS.bodyFontSize}
              </div>
              <ThemeProvider
                theme={(outerTheme) => ({ ...outerTheme, direction: "ltr" })}>
                <Slider
                  value={
                    +CSS.bodyFontSize.substring(
                      0,
                      CSS.bodyFontSize.indexOf("px")
                    )
                  }
                  getAriaValueText={(value) => `${value}px`}
                  step={1}
                  marks
                  min={12}
                  max={18}
                  sx={{ width: "60px", flex: 1 }}
                  valueLabelDisplay='auto'
                  onChange={(e) => {
                    setCSS({ ...CSS, bodyFontSize: e.target.value + "px" });
                  }}
                />
              </ThemeProvider>
              <h5 className="color-text">{t("FontSize")}</h5>
            </div>
          </>
        )}
      </div>
      {props.goNext && (
        <div className='col-12 mt-5 text-center next-back-btns'>
          {/* <button className="btn outlined-btn " onClick={props.goBack}>
            <ArrowBack style={{transform:'rotate(180deg)'}} /> السابق
        </button> */}
          <LoadingButton
            classes="btn primary-btn"
            handleClick={onSubmit}
            label={props.loadingButton ? t("Next") : <>{t("Next")}  <ArrowBack /></>}
            loading={props.loadingButton}
          />
        </div>
      )}
    </>
  );
});

export default ColorPalette;
