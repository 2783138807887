import React, { Component } from "react";
import ChatList from "./ChatList/ChatList";
import ChatHistory from "./ChatHistory/ChatHistory";
import AppLayout from "../layouts/app-layout";
import { t } from "i18next";

class Chats extends Component {
  state = {
    mainVisible: false,
    isAdmin: window.location.href.includes('admin')
  };


  handleChatClick = () => {
    this.setState({ ...this.state, mainVisible: true });
  };

  handleBackToList = () => {
    this.setState({ ...this.state, mainVisible: false });
  };

  render() {
    return (
      <AppLayout needAuth pageTitle={t("Messages")} withoutNav={this.state.isAdmin}>
        <div style={{ height: this.state.isAdmin ? 'calc(100vh - 130px)' : 'calc(100vh - 70px)', marginTop: this.state.isAdmin ? 0 : '70px', position: this.state.isAdmin ? 'static' : 'fixed', width: '100%' }}>
          <div className='chats-tab-open h-100'>
            <div className="main-layout h-100" style={{ padding: this.state.isAdmin ? 0 : '0 calc(var(--px-meduim) / 2)' }}>
              <ChatList handleChatClick={this.handleChatClick} />
              <ChatHistory
                mainVisible={this.state.mainVisible}
                BackToListClicked={this.handleBackToList}
              />
              <div className='backdrop'></div>
            </div>
          </div>
        </div>
      </AppLayout>
    );
  }
}

export default Chats;
