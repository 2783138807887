import { Box } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import AppLayout from "../../components/layouts/app-layout";
import { ListArrow } from "../../constants/icons";
import { useAuth } from "../../helpers/context";
import PageHeader from "../../components/global-components/page-header";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { vendor } = useAuth();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  
  return (
    <AppLayout pageTitle={t("PrivacyPolicy")} withFooter>
      {vendor !== "amakkn" && <PageHeader name={t("PrivacyPolicy")} />}
      <div className="page-width favorite-area pd-top-90 mg-bottom-100 text-center">
        {vendor === "amakkn" && (
          <Box
            sx={{
              marginBottom: "40px",
              textAlign: "center !important",
              fontSize: "28px",
              fontWeight: 600,
              color: "#000"
            }}>
           {t("PrivacyPolicy")}
          </Box>
        )}

        <div className={`row align-items-center justify-content-end ${vendor === "amakkn" ? "pd-top-30 m-0" : "mx-0"}`}
          style={vendor !== "amakkn" ? { marginTop: '-50px !important' } : {}}
         >
          <div className="col-12 p-0 mb-2">
            <div className='custom-card' style={{ minHeight: 'auto' }}>
              <div className="card-header">
                <h4 style={{ color: 'var(--main-color-one)' }}>مقدمة</h4>
              </div>
              <div className="card-body">
                <p>نحن في موقع أماكن نقدر خصوصيتك ونلتزم بحماية البيانات الشخصية التي نجمعها منك. توضح سياسة الخصوصية هذه كيف نتعامل مع البيانات الشخصية التي نجمعها منك أو عنك عند استخدام موقعنا الإلكتروني www.amakkn.com والخدمات المرتبطة به.</p>
              </div>
            </div>
          </div>
          <div className="col-12 p-0 mb-2">
            <div className='custom-card' style={{ minHeight: 'auto' }}>
              <div className="card-header">
                <h4 style={{ color: 'var(--main-color-one)' }}>البيانات الشخصية التي نجمعها</h4>
              </div>
              <div className="card-body">
                <ul className="custom-list">
                  <li><ListArrow style={i18n.language ==="en"? {transform:'rotate(180deg)'}:{}} />
                    قد نجمع معلومات شخصية عنك تشمل:
                    <ul className="pt-3">
                      <li><b>المعلومات التعريفية</b>: مثل الاسم والعنوان ورقم الهاتف والبريد الإلكتروني.</li>
                      <li>المعلومات التي نحصل عليها من خدمة نفاذ لأغراض التحقق من الهوية.</li>
                      <li><b>معلومات تسجيل الدخول</b>: مثل اسم المستخدم وكلمة المرور.</li>
                      <li><b>المعلومات التفصيلية</b>: مثل الجنس وتاريخ الميلاد والجنسية.</li>
                      <li><b>معلومات الاتصال</b>: المراسلات داخل موقع وتطبيق أماكن والمواقع التابعة لها.</li>
                      <li><b>معلومات تقنية</b>: مثل عنوان IP ونوع المتصفح والجهاز المستخدم والبيانات المتعلقة بالتفاعلات والاستخدام.</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 p-0 mb-2">
            <div className='custom-card' style={{ minHeight: 'auto' }}>
              <div className="card-header">
                <h4 style={{ color: 'var(--main-color-one)' }}>كيف نستخدم البيانات الشخصية</h4>
              </div>
              <div className="card-body">
                <ul className="custom-list">
                  <li><ListArrow style={i18n.language ==="en"? {transform:'rotate(180deg)'}:{}} />
                    نستخدم البيانات الشخصية التي نجمعها للأغراض التالية:
                    <ul className="pt-3">
                      <li>توفير الخدمات والمنتجات التي تطلبها.</li>
                      <li>إدارة وتحسين موقعنا الإلكتروني وتجربة المستخدم.</li>
                      <li>معالجة معاملات الدفع والشحن.</li>
                      <li>التواصل معك والرد على استفساراتك وطلباتك.</li>
                      <li>التسويق لمنتجات وخدمات قد تكون ذات صلة بك</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-12 p-0 mb-2">
            <div className='custom-card' style={{ minHeight: 'auto' }}>
              <div className="card-header">
                <h4 style={{ color: 'var(--main-color-one)' }}>الإفصاح عن البيانات الشخصية</h4>
              </div>
              <div className="card-body">
                <ul className="custom-list">
                  <li><ListArrow style={i18n.language ==="en"? {transform:'rotate(180deg)'}:{}} />
                    قد نكشف البيانات الشخصية التي نجمعها للجهات الثالثة في الحالات التالية:
                    <ul className="pt-3">
                      <li><b>الشركات التابعة والشركاء</b>: لتوفير الخدمات والمنتجات التي تطلبها.</li>
                      <li><b>مقدمي الخدمات الخارجيين</b>: مثل معالجي المدفوعات وشركات الشحن.</li>
                      <li><b>الجهات الرقابية والتنظيمية</b>: عند الطلب وفقط عندما يكون ذلك مطلوبًا بموجب القانون.</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 p-0 mb-2">
            <div className='custom-card' style={{ minHeight: 'auto' }}>
              <div className="card-header">
                <h4 style={{ color: 'var(--main-color-one)' }}>حقوقك بخصوص البيانات الشخصية</h4>
              </div>
              <div className="card-body">
                <ul className="custom-list">
                  <li><ListArrow style={i18n.language ==="en"? {transform:'rotate(180deg)'}:{}} />
                    تتوفر لك حقوق معينة بموجب نظام حماية البيانات الشخصية في المملكة العربية السعودية بخصوص البيانات الشخصية التي نتعامل معها، وتشمل:
                    <ul className="pt-3">
                      <li><b>حق الوصول</b>: يمكنك طلب الوصول إلى البيانات الشخصية التي نحتفظ بها عنك.</li>
                      <li><b>حق التصحيح</b>: إذا كنت تعتقد أن البيانات الشخصية التي نحتفظ بها عنك غير صحيحة أو غير مكتملة، يمكنك طلب تصحيحها.</li>
                      <li><b>حق الحذف</b>: يمكنك طلب حذف البيانات الشخصية التي نحتفظ بها عنك، إلا إذا كان يجب علينا الاحتفاظ بها لأغراض قانونية أو نظامية.</li>
                      <li><b>حق الاعتراض</b>: يمكنك الاعتراض على معالجة البيانات الشخصية التي تتعلق بك في بعض الحالات.</li>
                      <li><b>حق التقييد</b>: يمكنك طلب تقييد معالجة البيانات الشخصية التي تتعلق بك..</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 p-0 mb-2">
            <div className='custom-card' style={{ minHeight: 'auto' }}>
              <div className="card-header">
                <h4 style={{ color: 'var(--main-color-one)' }}>أمان البيانات الشخصية</h4>
              </div>
              <div className="card-body">
                <p>نحن ملتزمون بحماية البيانات الشخصية التي نجمعها عن طريق اتخاذ إجراءات أمان تقنية وإدارية مناسبة للحد من أخطار الفقد أو السرقة أو الوصول غير المصرح به أو الاستخدام غير المشروع.</p>
              </div>
            </div>
          </div>
          <div className="col-12 p-0 mb-2">
            <div className='custom-card' style={{ minHeight: 'auto' }}>
              <div className="card-header">
                <h4 style={{ color: 'var(--main-color-one)' }}>نقل البيانات عبر الحدود</h4>
              </div>
              <div className="card-body">
                <p>قد ننقل البيانات الشخصية التي نجمعها عنك إلى جهات ثالثة موجودة خارج المملكة العربية السعودية. عند القيام بذلك، سنحرص على ضمان وجود مستوى حماية مناسب للبيانات الشخصية.</p>
              </div>
            </div>
          </div>
          <div className="col-12 p-0 mb-2">
            <div className='custom-card' style={{ minHeight: 'auto' }}>
              <div className="card-header">
                <h4 style={{ color: 'var(--main-color-one)' }}>التعديلات على سياسة الخصوصية</h4>
              </div>
              <div className="card-body">
                <p>قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. سنعلن عن أي تغييرات على سياسة الخصوصية من خلال نشرها على موقعنا الإلكتروني</p>
              </div>
            </div>
          </div>
          
          <div className="col-12 p-0 mb-2">
            <div className='custom-card' style={{ minHeight: 'auto' }}>
              <div className="card-header">
                <h4 style={{ color: 'var(--main-color-one)' }}>كيفية التواصل معنا</h4>
              </div>
              <div className="card-body">
                <p>إذا كان لديك أسئلة حول سياسة الخصوصية هذه أو ترغب في ممارسة حقوقك بشأن البيانات الشخصية، يمكنك التواصل معنا
                  {" "} <a href="https://www.amakkn.com/CSChats" rel="noreferrer" target="_blank" style={{ color: 'var(--main-color-one)', textDecoration: 'underline' }}>من هنا</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default PrivacyPolicy;
