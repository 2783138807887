import React from "react";
import { useParams } from "react-router-dom";
import UserChat from "./UserChat";
import { useAuth } from "../../../helpers/context";

// Chat History with different Routing
function ChatHistory(props) {
  const { token } = useAuth();
  let { id } = useParams();

  return token && id && <UserChat id={id} />;
}
export default ChatHistory;
