import React from "react";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { verifyWLUser } from "../../api/userApi";
import AppLayout from "../../components/layouts/app-layout";
import { useTranslation } from "react-i18next";

const EmailVerification = () => {
    const history = useNavigate();
    let { code } = useParams();
    const { t } = useTranslation();
   
    useEffect(() => {
        if (code)
            verifyWLUser({ code }).then((response) => {
                if (response.resCode === 0) {
                    toast.success(t("ThankYouYourEmailHasBeenConfirmedSuccessfully"));
                } else {
                    toast.error(response.resStr);
                }
                const path = "https://" +
                    (response.response.domain ? response.response.domain : 'amakkn.com');
                 
                window.location.href = path;
            }).catch(() => {
                toast.error(t("YourEmailAddressHasNotBeenConfirmedPleaseTryAgainLater"));
                history('/');
            });
    },// eslint-disable-next-line
        [history, code]);

    return (
        <AppLayout pageTitle={t("VerifyYourEmail")}>
            <div className="login-wrapper"><CircularProgress /></div>
        </AppLayout>
    );
}

export default EmailVerification;
