import React from "react";
import { useTranslation } from "react-i18next";

const T06WhyUS = ({ data }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="col-12 justify-content-center align-items-center flex-column d-flex" >
                <div className="row mx-0">
                    <div className="col-12  d-flex flex-column justify-content-center align-items-center">
                        <span className="section-prefix tag" data-aos="fade-up" data-aos-duration="1500">{t("OurAdvantages")}</span>
                        <h2 className='sec-title text-center border-lg-start' data-aos="fade-up" data-aos-duration="1500">{t("WhatMakesUsDifferentFromOthers")}</h2>
                    </div>
                    <div className={`col-12`} data-aos="fade-up" data-aos-duration="1500">
                        <p className="sec-desc text-center">{data.desc}</p>
                    </div>
                </div>
            </div>
            <div className="row features-row mx-0 mx-lg-auto mt-5" >
                {(data.title1?.trim() !== "" || data.content1?.trim() !== "") && (
                    <div className="feature col-11 col-lg-4 col-xl-3 shine-animate-item" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                        <div className="feature-icon shine-animate">01</div>
                        <h5 className="feature-title">{data.title1}</h5>
                        <p className="feature-desc">{data.content1}</p>
                    </div>
                )}
                {(data.title2?.trim() !== "" || data.content2?.trim() !== "") && (
                    <div className="feature col-11 col-lg-4 col-xl-3 shine-animate-item" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500">
                        <div className="feature-icon shine-animate">02</div>
                        <h5 className="feature-title">{data.title2}</h5>
                        <p className="feature-desc">{data.content2}</p>
                    </div>
                )}
                {(data.title3?.trim() !== "" || data.content3?.trim() !== "") && (
                    <div className="feature col-11 col-lg-4 col-xl-3 shine-animate-item" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="700">
                        <div className="feature-icon shine-animate">03</div>
                        <h5 className="feature-title">{data.title3}</h5>
                        <p className="feature-desc">{data.content3}</p>
                    </div>
                )}
            </div>
        </>
    );
}

export default T06WhyUS;
