import React, { useEffect, useRef, useState } from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  getNewMessages,
  getState,
  getUserProile,
  saveSupportLastMessage,
} from "../../../api/userApi";
import { getPropertiesOfOwnerNew } from "../../../api/propertyApi";
import Picker, { SKIN_TONE_MEDIUM_LIGHT } from "emoji-picker-react";
import { ThemeColor } from "../../../Config/Config";
import md5 from "md5";
import { useAuth } from "../../../helpers/context";
import { Avatar, Box, ClickAwayListener, IconButton, Popper } from "@mui/material";
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { useTranslation } from "react-i18next";

const UserCSChat = (props) => {
  const ENDPOINT = process.env.REACT_APP_CHAT_URL;
  const { token, UserId } = useAuth();
  const [UserName, setUserName] = useState('');
  const [Chats, setChats] = useState([]);
  const [Status, setStatus] = useState("");
  const [Receiver, setReceiver] = useState({
    name: "",
    avatar: "",
    companyIsVerified: "0",
  });
  const [Options, setOptions] = useState({
    showProfileDetail: false,
    chatSearchBox: false,
    showUserInformation: true,
    showLastMedia: true,
    showDocuments: true,
    emojiWidth: window.innerWidth / 16 + "rem",
    themeColor: Boolean(localStorage.getItem("theme"))
      ? parseInt(localStorage.getItem("theme"))
      : ThemeColor.Light,
    message: "",
  });
  // const [Property, setProperty] = useState();
  const socketRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const openEmojiSelector = Boolean(anchorEl);
  const emojiSelectorID = openEmojiSelector ? 'emoji-popper' : undefined;
  const { t, i18n } = useTranslation();

  const handleClickEmojiSelector = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  let timer = useRef();
  let chatRef = [];

  const channelID = props.id;

  const channelIDArr = channelID.split("-");
  const ReceiverId =
    channelIDArr[0] === UserId ? channelIDArr[2] : channelIDArr[0];

  useEffect(() => {
    if (socketRef.current)
      if (socketRef.current.connected) socketRef.current.disconnect();

    getNewMessages({ channelId: channelID, userId: UserId })
      .then((chat) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        chatRef = chat.response.messages;
        setChats(chat.response.messages);

        getState({ userId: ReceiverId }).then((_userInfo) => {
          setStatus(_userInfo.response.lastSeen ? t("LastSeen") + " " + getDateName2(_userInfo.response.lastSeen) : '');
        });

        socketRef.current = window.io(ENDPOINT);
        socketRef.current.on("chat message", (msg) => {
          if (chatRef.length === 0) chatRef = Chats;
          switch (msg.text) {
            case "":
              if (msg.receiverUid === UserId) {
                let chatArr = [];
                chatRef.forEach((element) => {
                  element.seen = "2";
                  chatArr.push(element);
                });
                setChats((Chats) => chatArr);
                chatRef = chatArr;
                setStatus(t("Online"));
              }
              break;
            case "D@]44vaJ@FM2xYG(Fu7v8fNt8raG]wm.":
              if (msg.receiverUid === UserId) setStatus(t("Typing"));
              break;
            case "4sVpfb393M>]tWR@LiqEZ,8[3UTZcht)":
              if (msg.receiverUid === UserId) setStatus(t("Online"));
              break;
            default:
              const index = chatRef.filter(function (item) {
                return item.mId === msg.mId;
              });
              if (index.length > 0) {
                if (msg.chatID === UserId) {
                  let chatArr = [];
                  chatRef.forEach((element) => {
                    element.seen = "2";
                    chatArr.push(element);
                  });
                  setChats((Chats) => chatArr);
                  chatRef = chatArr;
                }
                setStatus(t("Online"));
              } else {
                setChats((Chats) => [...Chats, msg]);
                chatRef.push(msg);
              }
              break;
          }
        });
        socketRef.current.emit("connectUser", {
          isOnline: "1",
          userId: UserId,
        });
        emitMsg("");
        return () => {
          socketRef.current.disconnect();
        };
      })
      .catch((error) => {
        throw error;
      });
  }, [UserId, channelID, UserId, i18n.language]);

  window.addEventListener("beforeunload", (ev) => {
    // ev.preventDefault();
    socketRef.current.emit("connectUser", { isOnline: "0", userId: UserId });
  });

  useEffect(() => {
    getPropertiesOfOwnerNew({
      ownerId: ReceiverId,
      page: "1",
      sortBy: "updatedAt",
      sortOrder: "desc",
      pageSize: "10",
      userId: token,
      language: i18n.language === 'ar' ? '0' : '1',
    }).then((_userInfo) => {
      if (_userInfo.resCode === 0) setReceiver(_userInfo.response.hostInfo);
    });
  }, [ReceiverId, token, i18n.language]);


  useEffect(() => {
    getUserProile({
      userId: token,
      language: i18n.language === 'ar' ? '0' : '1',
    }).then((_userInfo) => {
      if (_userInfo.resCode === 0) {
        setUserName(_userInfo.response.userProfile.name);
      }
    });
  }, [token, i18n.language]);

  function emitMsg(msg) {
    let unixTime = (+new Date()).toString();
    const mid = unixTime + channelID;
    let Mid = md5(mid);
    socketRef.current.emit("chat message", {
      receiverUid: ReceiverId,
      text: msg,
      userName: UserName,
      receiver: Receiver.name,
      chatID: UserId,
      channelId: channelID,
      updatedDate: unixTime,
      mId: Mid,
      seen: "0",
      inbox: "1",
    });
  }

  let messagesEndRef = React.createRef();

  window.onresize = () => {
    let width = window.innerWidth / 16;
    if (width >= 50) width = 50;
    setOptions({ emojiWidth: width + "rem" });
  };

  useEffect(() => {
    messagesEndRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }, [messagesEndRef, Chats]);

  function SendMessage() {
    // socket.emit("chat message", { abcd: "asd" });

    saveSupportLastMessage({
      userId1: token,
      userId2: ReceiverId,
      subjectId: channelIDArr[1],
      lastMessage: Options.message,
    }).then((_response) => { });
    emitMsg(Options.message);
    setOptions({ message: "" });
  }

  function timeConverter(unix_timestamp) {
    if (unix_timestamp)
      return new Intl.DateTimeFormat(i18n.language === "ar" ? "ar-SA" : "en", {
        hour: "2-digit",
        minute: "2-digit",
      }).format(new Date(parseFloat(unix_timestamp)));
  }

  function getDateName(unix_timestamp) {
    if (unix_timestamp) {
      let date = new Date(parseFloat(unix_timestamp));
      let todaysDate = new Date();
      let Yesterday = new Date(new Date().setDate(todaysDate.getDate() - 1));
      if (date.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0))
        return t("Today");
      else if (date.setHours(0, 0, 0, 0) === Yesterday.setHours(0, 0, 0, 0))
        return t("Yesterday");
      else
        return new Intl.DateTimeFormat(i18n.language === "ar" ? "ar-SY" : "en", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(parseFloat(unix_timestamp)));
    }
  }

  function getDateName2(unix_timestamp) {
    if (unix_timestamp) {
      let date = new Date(parseFloat(unix_timestamp));
      let todaysDate = new Date();
      let Yesterday = new Date(new Date().setDate(todaysDate.getDate() - 1));
      if (date.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0))
        return new Intl.DateTimeFormat(i18n.language === "ar" ? "ar-SY" : "en", {
          hour: "numeric",
          minute: "numeric",
        }).format(new Date(parseFloat(unix_timestamp)));
      else if (date.setHours(0, 0, 0, 0) === Yesterday.setHours(0, 0, 0, 0))
        return t("Yesterday");
      else
        return new Intl.DateTimeFormat(i18n.language === "ar" ? "ar-SY" : "en", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(parseFloat(unix_timestamp)));
    }
  }

  function isSameDate(unix_timestamp1, unix_timestamp2) {
    let date1 = new Date(parseFloat(unix_timestamp1));
    let date2 = new Date(parseFloat(unix_timestamp2));
    if (date1.setHours(0, 0, 0, 0) === date2.setHours(0, 0, 0, 0)) return true;
    else return false;
  }

  const handleMessageChange = (event) => {
    setOptions({ message: event.target.value });

    emitMsg("D@]44vaJ@FM2xYG(Fu7v8fNt8raG]wm.");
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(function () {
      emitMsg("4sVpfb393M>]tWR@LiqEZ,8[3UTZcht)");
    }, 2000);
  };

  const handleEmojiSelect = (event, emojiObject) => {
    setOptions({
      message: Options.message
        ? Options.message + emojiObject.emoji
        : emojiObject.emoji,
    });
  };

  return (
    <main className="main main-visible" style={{ margin: '0 auto', maxWidth: '1536px' }}>
      <div className='chats'>
        <div className='chat-body'>
          <div className='chat-header'>
            {/* <Link className='contacts-link' to='/CSChats'>
              <button
                className='btn btn-secondary btn-icon btn-minimal btn-sm text-muted d-xl-none ml-3'
                type='button'>
                <ArrowBack style={{ transform: 'rotate(180deg)' }} />
              </button>
            </Link> */}
            {/* {ReceiverId === "1" ? ( */}
            <div className='media chat-name d-flex align-items-center justify-content-between' style={{ gap: '25px' }}>
              <div className='d-sm-inline-block'>
                <Avatar src={Receiver.avatar}
                  sx={{
                    width: 56,
                    height: 56,
                    bgcolor: Receiver.avatar ? '#fff' : 'var(--main-color-one)'
                  }}
                  alt='user img'>{Receiver.name && Receiver.name.length > 4 ? Receiver.name.substring(0, 4) : Receiver.name}</Avatar>
              </div>
              <div className='media-body align-self-center'>
                <h6 className='mb-0' style={{ fontWeight: 500 }}>{Receiver.name}</h6>
                <small className='text-muted'>{Status}</small>
              </div>
            </div>
          </div>

          <div className='chat-content p-2'>
            <div className='container mt-2'>
              {Chats.length > 0 ? (
                Chats.map((element, idx) => (
                  <div key={idx}>
                    {idx === 0 ? (
                      <div
                        className='message-divider pb-2'
                        data-label={getDateName(element.updatedDate)}></div>
                    ) : isSameDate(
                      Chats[idx].updatedDate,
                      Chats[idx - 1].updatedDate
                    ) ? (
                      <></>
                    ) : (
                      <div
                        className='message-divider pb-2'
                        data-label={getDateName(element.updatedDate)}></div>
                    )}

                    <div
                      className={`message ${element.chatID === UserId ? "self" : ""}`}>
                      <div className='message-wrapper'>
                        <div className='message-content'>
                          <span>{element.text}</span>
                        </div>
                      </div>
                      <div className='message-options'>
                        {element.chatID === UserId ? (
                          <span className='message-status'>
                            {element.seen === "2" ? "\u2713\u2713" : "\u2713"}
                          </span>
                        ) : (
                          <></>
                        )}
                        {idx === Chats.length - 1 ? (
                          <span className='message-date' ref={messagesEndRef}>
                            {timeConverter(element.updatedDate)}
                          </span>
                        ) : (
                          <span className='message-date'>
                            {timeConverter(element.updatedDate)}
                          </span>
                        )}
                        {/* <MessageDropdown /> */}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  className='message-divider pb-2'
                  data-label=''
                  ref={messagesEndRef}></div>
              )}
            </div>

            <div className='chat-finished' id='chat-finished'></div>
          </div>

          <div className='chat-footer'>
            <div className='form-row align-items-center'>
              <div className='col'>
                <div className='input-group'>
                  <input
                    type='text'
                    className='form-control transparent-bg border-0 no-resize hide-scrollbar'
                    placeholder={t("WriteYourMessage")}
                    rows='1'
                    value={Options.message}
                    onKeyDown={(e) => {
                      if (e.code === "Enter" || e.code === "NumpadEnter") {
                        handleMessageChange(e);
                        setTimeout(() => {
                          SendMessage()
                        }, 400);
                      }
                    }}
                    onChange={handleMessageChange} />
                  
                  <div className={`input-group-prepend ${i18n.language === "ar" ? "mr-sm-2 mr-1" : "ml-sm-2 ml-1"}`}>
                    <IconButton onClick={handleClickEmojiSelector}>
                      <InsertEmoticonIcon sx={{ fontSize: '32px' }} />
                    </IconButton>
                    <Popper id={emojiSelectorID} open={openEmojiSelector} anchorEl={anchorEl}>
                      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                        <Box>
                          <Picker
                            onEmojiClick={handleEmojiSelect}
                            skinTone={SKIN_TONE_MEDIUM_LIGHT}
                          />
                        </Box>
                      </ClickAwayListener>
                    </Popper>
                  </div>
                </div>
              </div>
              <div className='col-auto'>
                <div
                  className='btn btn-primary btn-icon rounded-circle text-light'
                  role='button'
                  onClick={() => SendMessage()}>
                  <ChevronLeftIcon sx={{ fontSize: "34px", fontWeight: 700, transform: i18n.language === "ar" ? 'rotate(0)' : 'rotate(180deg)' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default UserCSChat;
