import React, { useEffect, useState } from "react";
import AppLayout from "../../components/layouts/app-layout";
import PageHeader from "../../components/global-components/page-header";
import { useAuth } from "../../helpers/context";
import { getNews } from "../../api/blogApi";
import LoadingData from "../../components/global-components/loading-data";
import NoData from "../../components/global-components/no-data";
import PostCard from "../admin/blog/components/post-card";
import { MODERN_TEMPLATES } from "../../constants";
import { useTranslation } from "react-i18next";

export default function Blog() {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const { vendor, templateId } = useAuth();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (vendor) {
      setLoadingData(true);
      getNews({
        userName: vendor,
        language: i18n.language === 'ar' ? '0' : '1',
      }).then((_response) => {
        if (_response.resCode === 0) {
          setData(_response.response.array);
        }
      }).finally(() => setLoadingData(false));
    }
  }, [vendor, i18n]);

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  
  return (
    <AppLayout withFooter pageTitle={t("Blog")}>
      <PageHeader name={t("Blog")} />
      <div className='featured-props projects-page'>
        <div className='row justify-content-center pd-top-60'>
          <div className='row align-items-center col-12 pd-bottom-60'>
            {loadingData ? <LoadingData /> : data.length > 0 ?
              <div className={`row m-0 ${MODERN_TEMPLATES.includes(+templateId) ? 'justify-content-center' : ''}`} style={{ gap: MODERN_TEMPLATES.includes(+templateId) ? 20 : 0 }}>
                {
                  data.map((item, i) => (
                    <PostCard item={item} key={i}
                      setOpenDemoSnackBar={() => { }}
                      handlePublishStatus={() => { }} />
                  ))
                }
              </div>
              : <NoData msg={t("ThereAreNoNews")} />}
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
