import React, { useRef } from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { MAX_VIDEO_SIZE } from '../../constants';
import { useTranslation } from 'react-i18next';

const DragAndDropFileUploader = ({ extra, buttonMode, handleChange, id, classes, darkIcon, icon, title, style, loading, singleFile, PDFOnly, ImageOnly, OnOK, uploading, VideosOnly }) => {
    const inputRef = useRef();
    const { t } = useTranslation();

    const checkSingleFile = (files) => {
        let file = files[0];
        if (PDFOnly && file.type !== 'application/pdf') {
            toast.error(t("PleaseSelectFile", { type: 'PDF' }));
        } else if (ImageOnly && file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
            toast.error(t("PleaseSelectFile", { type: 'PNG, JPG, JPEG' }));
        } else if (VideosOnly && file.type !== 'video/mp4') {
            toast.error(t("PleaseSelectFile", { type: 'MP4' }));
        } else if (!PDFOnly && !ImageOnly && file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg' && file.type !== 'application/pdf') {
            toast.error(t("PleaseSelectFile", { type: 'PNG, JPG, JPEG, PDF' }));
        }
        if ((VideosOnly && file.type === 'video/mp4') || (PDFOnly && file.type === 'application/pdf') ||
            (ImageOnly && (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg')) ||
            (!PDFOnly && !ImageOnly && (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'application/pdf'))) {
            return true;
        }
        return false;
    };

    const checkMultiFile = (files) => {
        let filesResult = [];
        let error = false;
        if (PDFOnly) {
            for (let file of files) {
                if (file.type === 'application/pdf')
                    filesResult.push(file);
                else {
                    error = true;
                }
            }
            if (error)
                toast.error(t("PleaseSelectFiles", { type: "PDF" }));
        } else if (ImageOnly) {
            for (let file of files) {
                if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg')
                    filesResult.push(file);
                else {
                    error = true;
                }
            }
            if (error)
                toast.error(t("PleaseSelectFiles", { type: "PNG, JPG, JPEG" }));

        } else if (VideosOnly) {
            for (let file of files) {
                if (file.type === 'video/mp4')
                    filesResult.push(file);
                else
                    error = true;
            }
            if (error)
                toast.error(t("PleaseSelectFiles", { type: "MP4" }));
            
        } else if (!ImageOnly && !PDFOnly) {
            for (let file of files) {
                if (file.type === 'application/pdf' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg')
                    filesResult.push(file);
                else {
                    error = true;
                }
            }
            if (error)
                toast.error(t("PleaseSelectFiles", { type: "PNG, JPG, JPEG, PDF" }));

        }
        return filesResult;
    };
   
    const handleDrop = (event) => {
        event.preventDefault();
        let dropzone = document.querySelector(`.dropzone#${id}`);
        if (dropzone)
            dropzone.classList.remove('highlight');
        
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            if (singleFile && checkSingleFile(event.dataTransfer.files))
                OnOK(event.dataTransfer.files);
            else if (!singleFile) {
                let files = checkMultiFile(event.dataTransfer.files);
                if (files.length > 0)
                    OnOK(files);
            }
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        let dropzone = document.querySelector(`.dropzone#${id}`);
        if (dropzone)
            dropzone.classList.add('highlight');
    };
    const handleDragLeave = (event) => {
        event.preventDefault();
        let dropzone = document.querySelector(`.dropzone#${id}`);
        if (dropzone)
            dropzone.classList.remove('highlight');
    };
    const handleDragEnter = (event) => {
        event.preventDefault();
        let dropzone = document.querySelector(`.dropzone#${id}`);
        if (dropzone)
            dropzone.classList.add('highlight');
    };
    
    
    return (
        <>
            {buttonMode ? (
                <label
                    htmlFor={id}
                    disabled={loading}
                    className={classes ? loading ? `${classes} disabled custom-btn` : `${classes} custom-btn` : loading ? "disabled custom-btn" : "custom-btn"}
                    style={style ? style : {}}
                >
                    <input
                        accept={VideosOnly ? 'video/mp4' : PDFOnly ? 'application/pdf' : ImageOnly ? 'image/x-png,image/jpeg' : 'image/x-png,image/jpeg,application/pdf'}
                        id={id}
                        onChange={(e) => {
                            e.preventDefault();
                            if (checkSingleFile(e.target.files))
                                handleChange(e);
                        }}
                        type='file'
                        style={{ display: 'none' }}
                    />
                    {title && <span style={{ width: 'auto', height: 'auto', maxWidth: 'none', margin: 0, border: 0 }}>{title}</span>}
                    {loading ? <CircularProgress size={20} style={{ padding: 0, margin: 0, border: 0 }} sx={{ color: darkIcon ? "var(--main-color-one)" : '#fff' }} /> : icon}
                </label>
            ) : (
                <div
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    id={id}
                    className='dropzone'>
                    {uploading && (
                        <div className="loading-bar">
                            <div className='loading-box' id='box1'></div>
                            <div className='loading-box' id='box2'></div>
                            <div className='loading-box' id='box3'></div>
                            <div className='loading-box' id='box4'></div>
                            <div className='loading-box' id='box5'></div>
                            <div className='loading-box' id='box6'></div>
                            <div className='loading-box' id='box7'></div>
                        </div>
                    )}
                    <CloudUploadIcon sx={{ color: 'var(--main-color-one)', fontSize: '30px' }} />
                    <p>{t("DragAndDrop")}</p>
                    <h5 className='mt-0'>{t("OR")}</h5>
                    <input
                        type="file"
                        multiple={!singleFile}
                        hidden
                        ref={inputRef}
                        accept={VideosOnly ? 'video/mp4' : PDFOnly ? 'application/pdf' : ImageOnly ? 'image/x-png,image/jpeg' : 'image/x-png,image/jpeg,application/pdf'}
                        onChange={(e) => {
                            e.preventDefault();
                            if (singleFile && checkSingleFile(e.target.files))
                                OnOK(e.target.files);
                            else if (!singleFile) {
                                let files = checkMultiFile(e.target.files);
                                if (files.length > 0)
                                    OnOK(files);
                            }
                        }}
                    />
                    <button className='primary-btn' onClick={(e) => {
                        e.preventDefault();
                        inputRef.current.click();
                    }
                    }>
                        {singleFile ? t("ClickToSelectFile") : t("ClickToSelectFiles")}
                    </button>
                    <p className='mt-2' style={{ fontSize: '12px' }}>{VideosOnly ? t("AllowedFormats1", { type: 'MP4' }) : PDFOnly ? t("AllowedFormats1", { type: 'PDF' }) : ImageOnly ? t("AllowedFormats1", { type: 'PNG, JPG, JPEG' }) : t("AllowedFormats1", { type: 'PNG, JPG, JPEG, PDF' })}</p>
                    {extra && <p className='mb-2' style={{ fontSize: '12px' }}>{extra}</p>}
                    {VideosOnly && <p className='mb-2' style={{ fontSize: '12px' }}>{t("MaximumVideoSize", { size: MAX_VIDEO_SIZE })}</p>}
                </div>
            )}
        </>
    );
}

export default DragAndDropFileUploader;