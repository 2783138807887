import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GoogleMapsContainer from "../../../../components/section-components/registration/GoogleMapsContainer";
import LoadingButton from '../../../../components/global-components/loading-btn';
import { toast } from 'react-toastify';
import { saveLocation } from '../../../../api/propertyApi';
import { useAuth } from '../../../../helpers/context';

const publicUrl = process.env.REACT_APP_URL + "/";

export default function SetPropertyLocationDialog({ openMapDialog, setOpenMapDialog, onOK }) {
    const { t } = useTranslation();
    const [isSubmittingData, setIsSubmittingData] = useState(false);
    const [Location, setLocation] = useState({
        latitude: '',
        longitude: '',
        address: ''
    });
    const { token } = useAuth();
    const [ZoomLevel, setZoomLevel] = useState(14);
    const [latLng, setLatLng] = useState({ lat: '', lng: '' });

    function handleZoomChange(NewZoom) {
        setZoomLevel(NewZoom);
    }
    
    const handleSave = () => {
        if (!Location.address) toast.error(t("PleaseSpecifyTheAddressCorrectly"));
        if (ZoomLevel < 17) {
            toast.error(t("PleaseZoomInForPreciseLocation"));
        } else {
            setIsSubmittingData(true);
            saveLocation({
                userId: token,
                propertyId: openMapDialog?.property?.propertyId,
                latitude: Location.latitude,
                longitude: Location.longitude,
            }).then((res) => {
                if (res.resCode === 0) {
                    toast.success(t("ThePropertyHasBeenSuccessfullyLocated"));
                    onOK?.();
                    setOpenMapDialog({ visible: false, property: undefined });
                }
            }).finally(() => setIsSubmittingData(false))
        }
    };

    useEffect(() => {
        if (openMapDialog.property) {
            let gecod = new window.google.maps.Geocoder();
            gecod.geocode(
                {
                    address: openMapDialog.property.address,
                    region: "SA",
                },
                (results, status) => {
                    if (status === "OK") {
                        let location = results?.[0]?.geometry?.location;
                        if (location?.lat() && location?.lng()) {
                            setLatLng({ lat: location.lat(), lng: location.lng() });
                        } else {
                            setLatLng({ lat: '24.716199523004914', lng: '46.671776478222675' })
                        }
                    } else {
                        setLatLng({ lat: '24.716199523004914', lng: '46.671776478222675' })
                    }
                }
            );
        }
    }, [openMapDialog]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="xl"
            open={openMapDialog.visible}
            className="custom-dialog"
            sx={{ '& .MuiPaper-root': { margin: 0, width: 'calc(100% - 20px)', padding: 0 } }}
            onClose={() => setOpenMapDialog({ visible: false, property: undefined })}>
            <DialogTitle>
                <div className="dialog-head">
                    <div>
                        <h2>{t("DeterminePropertyLocation")}</h2>
                    </div>
                    <div className="dialog-actions">
                        <LoadingButton
                            classes="px-3 primary-btn"
                            handleClick={() => handleSave()}
                            label={t("Save")}
                            loading={isSubmittingData}
                        />
                        <button className="px-3 outlined-btn" onClick={() => setOpenMapDialog({ visible: false, property: undefined })}>
                            {t("Cancel")}
                        </button>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent className='p-0'>
                <div className='p-0'>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div className="select-map" style={{ width: '100%', padding: '0', textAlign: 'center', height: '400px', position: 'relative' }}>
                            {latLng.lat && (
                                <>
                                    <GoogleMapsContainer
                                        lat={latLng.lat}
                                        lng={latLng.lng}
                                        onChange={(NewLocation) => setLocation({ ...Location, NewLocation })}
                                        user={Location}
                                        onZoomChange={handleZoomChange}
                                    />
                                    <img
                                        alt='pin'
                                        src={publicUrl + "assets/img/icons/BluePin.svg"}
                                        className='addPropPin'
                                    />
                                    <input
                                        id='address'
                                        name='address'
                                        className='form-control py-3 map-input'
                                        defaultValue={Location.address}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}