import React from "react";
import { useAuth } from "../../helpers/context";
import { DARK_TEMPLATES } from "../../constants";

const isAdmin = window?.location?.href?.includes("admin");

export default function LoadingData() {
  const { templateId } = useAuth();
  return (
    <div className="col-12 d-flex justify-content-center align-items-center flex-column loading-wrapper radius-16" style={{ background: '#fff' }}>
      <img src={`/assets/img/loader-${isAdmin ? '1' : DARK_TEMPLATES.includes(+templateId) ? '2' : '1'}.gif`} alt='loading' />
    </div>
  );
}
