import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../helpers/context';
import { checkNafath } from '../../../../api/userApi';
import { useNavigate } from 'react-router-dom';

export default function CheckNafazOtp({ random, transId, id, setIsSubmittingVerification }) {
    const { t, i18n } = useTranslation();
    const [status, setStatus] = useState('WAITING');
    const { token } = useAuth();
    const [numberOfTries, setNumberOfTries] = useState(0);
    const history = useNavigate();

    useEffect(() => {
        if (status !== 'EXPIRED' && status !== 'REJECTED') {
            setTimeout(() => {
                callEP();
            }, 1000);
        }
    },  // eslint-disable-next-line
        [numberOfTries]);
      
    const callEP = () => {
        checkNafath({ userId: token, language: i18n.language === 'ar' ? '0' : '1', national_id: id, transId: transId, randomCode: random }).then(res => {
            if (res.resCode === 0) {
                setNumberOfTries(old => old + 1);
                setStatus(res.response.status);
                if (res.response.status === "COMPLETED") {
                    // history(`/iamlink/${id}`);
                }
                if (res.response.status === "EXPIRED" || res.response.status === "REJECTED") {
                    toast.error(t("VerificationFailed"));
                    setIsSubmittingVerification(false);
                }
            } else {
                toast.error(res.resStr);
            }
        }).finally(() => {
              
        });
    }
    
    return (
        <>
            {status !== "COMPLETED" && <>
                <div className='col-12 p-0'>
                    <h5 className='mb-4'>{t("PleaseOpenTheNationalUnifiedAccessApplication")}</h5>
                </div>
                <div className="col-12 col-md-3 base-tag">{random}</div>
            </>
            }
            
            {status === "COMPLETED" &&
                <>
                    <div className='col-12 p-0'>
                        <h5 className='mb-4'>{t("AuthenticationNafazSuccessfully")}</h5>
                    </div>
                    <button className='py-3 btn primary-btn px-5' onClick={() => history(`/iamlink/${id}`)}>{t("Next")}</button>
                </>
            }
        </>
    );
}
