import React, { useState } from "react";
import ChatList from "./ChatList/ChatList";
import ChatHistory from "./ChatHistory/ChatHistory";
import { useLocation, useParams } from "react-router-dom";
import AppLayout from "../layouts/app-layout";
import { useTranslation } from "react-i18next";

const CSChats = () => {
  const [mainVisible, setmainVisible] = useState(false);
  let { propertyId } = useParams();
  const propertyid = propertyId || "";
  const location = useLocation();
  const isAdmin = location.pathname.includes('admin');
  const { t } = useTranslation();

  return (
    <AppLayout needAuth pageTitle={t("HelpCenter")} withoutNav={isAdmin}>
      <div style={{ height: isAdmin ? 'calc(100vh - 130px)' : 'calc(100vh - 70px)', marginTop: isAdmin ? 0 : '70px' }}>
        <div className='chats-tab-open h-100'>
          <div className="main-layout h-100" style={{ padding: isAdmin ? 0 : '0 calc(var(--px-meduim) / 2)' }}>
            <ChatList
              handleChatClick={(e) => setmainVisible(true)}
              propertyId={propertyid}
            />

            <ChatHistory
              mainVisible={mainVisible}
              BackToListClicked={(e) => setmainVisible(false)}
            />
            <div className='backdrop'></div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default CSChats;
