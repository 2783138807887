import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { resolvePersianAndArabicNumbers } from "../../helpers";
import LoadingButton from "../global-components/loading-btn";
import { useAuth } from "../../helpers/context";
import { CLASSIC2_TEMPLATES, CLASSIC_TEMPLATES, DARK_TEMPLATES, MODERN2_TEMPLATES, MODERN_TEMPLATES } from "../../constants";
import { useTranslation } from "react-i18next";
import T04AboutUS from "../pro/template-04/about-us";
import T05AboutUS from "../pro/template-05/about-us";
import T06AboutUS from "../pro/template-06/about-us";

const publicUrl = process.env.REACT_APP_URL + "/";

function Process(props) {
  let imagealt = "image";
  const [Data, setData] = useState(props.data);
  const ImageTOView = publicUrl + "assets/img/logo/ejar-logo.svg";
  const { templateId } = useAuth();
  const { t } = useTranslation();

  return (
    <div className={`process-area ${props.isEdit ? 'admin' : ''}`}>
      <div className='row'>
        {MODERN_TEMPLATES.includes(+templateId) && <img src="/assets/img/shape-4.png" alt="sahpe" className="shape" />}
       
        <div className={`col-12 ${props.type === 'broker' ? 'col-lg-6 order-2 order-lg-1' : 'mb-4 mb-lg-0'}`}>
          {props.isEdit ? (
            <>
              {props.type !== "broker" && (
                <TextField
                  label={t("Title")}
                  variant='outlined'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  className='mt-4'
                  value={Data.title1}
                  onChange={(v) => {
                    setData({ ...Data, title1: resolvePersianAndArabicNumbers(v.currentTarget.value) });
                  }}
                />)}
                
              <TextField
                label={t("Description")}
                className='mt-3'
                InputLabelProps={{ shrink: true }}
                fullWidth
                inputProps={{ maxLength: 300 }}
                multiline
                rows={6}
                value={Data.content}
                onChange={(v) => {
                  setData({
                    ...Data, content: resolvePersianAndArabicNumbers(
                      v.currentTarget.value?.length >= 300 ? v.currentTarget.value?.substring(0, 300) : v.currentTarget.value
                    )
                  });
                }}
              />
              <h6 className='rightalign mt-2 mb-0'>
                {Data.content
                  ? parseInt(Data.content.length) + "/300"
                  : "0/300"}
              </h6>
              {/* {props.type === "broker" && (
                    <TextField
                      label='رابط غوغل'
                      variant='outlined'
                      fullWidth
                      className='mt-4'
                      value={Data.title1}
                      onChange={(v) => {
                        setData({ ...Data, title1: v.currentTarget.value });
                      }}
                    />)} */}
                 
            </>
          ) : CLASSIC_TEMPLATES.includes(+templateId) ? <T04AboutUS type={props.type} data={Data} /> :
              CLASSIC2_TEMPLATES.includes(+templateId) ? <T05AboutUS type={props.type} data={Data} /> :
                MODERN2_TEMPLATES.includes(+templateId) ? <T06AboutUS type={props.type} data={Data} /> :
                  (
              <div data-aos='fade-up' className={`col-12 justify-content-center flex-column d-flex ${DARK_TEMPLATES.includes(+templateId) ? 'aboutus-card col-lg-9 mx-auto' : ''} ${props.type !== 'broker' ? (MODERN_TEMPLATES.includes(+templateId) ? !props?.half ? 'align-items-center' : "" : 'align-items-center') : "broker-flex"}`}>
                {props.type !== 'broker' && (<>
                  <span className="section-prefix">{t("AboutUS1")}</span>
                  <h2 className='sec-title'>
                    {Data.title1}
                  </h2>
                </>)}
                <p className={`sec-desc ${[...CLASSIC_TEMPLATES,...MODERN2_TEMPLATES].includes(+templateId) ? 'col-lg-9' : 'col-lg-11'}`}>{Data.content}</p>
                <Link style={{ marginTop: '20px', maxWidth: 'fit-content' }} className='py-2 px-4 primary-btn' to={props.type !== 'broker' ? "About" : 'ejar'}>
                  {props.type !== 'broker' ? t("ReadTheFullStory") : t("GoToTheRentalAgreement")}
                </Link>
              </div>
            )}
        </div>
        {props.type === 'broker' && <div className='order-1 order-lg-2 col-lg-6 d-flex align-self-center mb-4'>
          <div
            className='m-auto'
            data-aos={!props.isEdit && "fade-up"}>
            <img
              // className='shadow-img'
              className="broker-img"
              src={ImageTOView} alt={imagealt} />
          </div>
        </div>}
      </div>
      {props.isEdit && <div className='mt-3 col-12'>
        <LoadingButton
          classes="primary-btn m-auto"
          handleClick={() => props.saveDetails({ data: Data })}
          label={t("SaveChanges")}
          loading={props.loading}
        />
      </div>}
    </div>
  );
}

export default Process;
