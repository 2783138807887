import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from "react-router-dom";

const AccessDenied = ({ btnLink, btnText, desc, title }) => {
    const location = useLocation();
    const isAdmin = location.pathname.includes('admin');
    const { t } = useTranslation();

    return (
        <div className='error-page version2'>
            <h2 style={{ fontSize: '40px', textAlign: 'center' }}>{title || t("YouCannotAccessThisPage")}</h2>
            <p>{desc}</p>
            <Link
                to={btnLink}
                className='btn primary-btn'
            >
                {btnText}
            </Link>
            {!isAdmin && (
                <>
                    <img src="/assets/img/cloud.svg" alt="cloud" className="cloud cloud-1" />
                    <img src="/assets/img/cloud.svg" alt="cloud" className="cloud cloud-2" />
                    <img src="/assets/img/cloud.svg" alt="cloud" className="cloud cloud-3" />
                    <img src="/assets/img/cloud.svg" alt="cloud" className="cloud cloud-4" />
                    <img src="/assets/img/404.svg" alt="404" className="image-404" />
                </>
            )}
        </div>
    );
}

export default AccessDenied;