import { Delete, Instagram, WhatsApp } from "@mui/icons-material";
import { Alert, Avatar, Box, FormControl, FormHelperText, InputAdornment, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getWLAboutUsMiddleman, saveWLAboutUsMiddleman, uploadWlFileBase64 } from "../../api/userApi";
import { SnapChat, Tel, Twitter } from "../../constants/icons";
import LoadingData from "../global-components/loading-data";
import NoData from "../global-components/no-data";
import SwiperCore, { Autoplay, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import LoadingButton from "../global-components/loading-btn";
import { preventString, removeZeroFromNumberIfExists, resolvePersianAndArabicNumbers } from "../../helpers";
import DragAndDropFileUploader from "../global-components/drag-drop-file-uploader";
import { useAuth } from "../../helpers/context";
import { CLASSIC2_TEMPLATES, CLASSIC_TEMPLATES, DARK_TEMPLATES, DEMO_VENDORS, MODERN2_TEMPLATES, MODERN_TEMPLATES } from "../../constants";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { useTranslation } from "react-i18next";
import T04OurTeam from "../pro/template-04/our-team";
import T05OurTeam from "../pro/template-05/our-team";
import T06OurTeam from "../pro/template-06/our-team";

SwiperCore.use([Autoplay]);

function TeamSection(props) {
  const [Data, setData] = useState(props.data);
  const { vendor, templateId } = useAuth();
  const [team, setTeam] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [timeStamp, setTimeStamp] = useState(new Date());
  const [errors, setErrors] = useState({});
  const [loadingImageButtons, setLoadingImageButtons] = useState({ number: '0' });
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (vendor) {
      if (DEMO_VENDORS.includes(vendor)) {
        if (localStorage.getItem(`team-${vendor}`)) {
          setTeam(JSON.parse(localStorage.getItem(`team-${vendor}`)));
          setLoadingData(false);
        } else {
          getWLAboutUsMiddleman({
            userName: vendor,
            language: i18n.language === 'ar' ? '0' : '1',
          }).then((_response) => {
            if (_response.resCode === 0) {
              let data = [];
              for (let item of _response.response.array)
                data.push(item);
              setTeam(data);
              localStorage?.setItem(`team-${vendor}`, JSON.stringify(data));
              setLoadingData(false);
            }
          });
        }
      } else {
        getWLAboutUsMiddleman({
          userName: vendor,
          language: i18n.language === 'ar' ? '0' : '1',
        }).then((_response) => {
          if (_response.resCode === 0) {
            let data = [];
            for (let item of _response.response.array)
              data.push(item);
            setTeam(data);
            setLoadingData(false);
          }
        });
      }
    
    }
  }, [vendor, i18n]);
  

  const addNewPerson = () => {
    let person = {
      id: `${team.length + 1}${parseInt(Math.random() + 1 * 598)}`,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      whatsapp: "",
      twitter: "",
      instagram: "",
      snapchat: "",
      icon: ""
    };
    setTeam([person, ...team]);
  };

  const deletePerson = (id) => {
    let temp = team.filter(t => t.id !== id);
    setTeam(temp);
  };

  const setError = (fieldName, newValue, arr) => {
    if (newValue.trim() === "")
      arr[fieldName] = t("ThisFieldIsMandatory");
    else
      arr[fieldName] = "";
    return arr;
  }

  const setField = (userId, fieldName, newValue) => {
    let temp = team;
    let user = team.filter(t => t.id === userId)[0];
    
    temp[temp.map((x, i) => [i, x]).filter(
      x => x[1] === user)[0][0]] = { ...user, [fieldName]: newValue };
    setTeam(temp);
    let errorsTemp = { id: userId };
    if (errors?.id === userId)
      errorsTemp = { ...errors };
    if (fieldName === "firstName")
      errorsTemp = setError('firstName', newValue, errorsTemp);
    else if (fieldName === "lastName")
      errorsTemp = setError('lastName', newValue, errorsTemp);
    else if (fieldName === "phoneNumber")
      errorsTemp = setError('phoneNumber', newValue, errorsTemp);

    setErrors(errorsTemp);
  };

  const getField = (userId, fieldName) => {
    let user = team.filter(t => t.id === userId)[0];
    return user[fieldName] || null;
  };

  const upload = (file, type, userId) => {
    setLoadingImageButtons({ number: userId });
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let fileInfo = {
        pathWithFileName: 'assets/img/team/' + file.name,
        base64Data: reader.result,
        typeOfFile: type,
        userId: props.token,
        language: i18n.language === 'ar' ? '0' : '1',
      };
      uploadWlFile(fileInfo, userId);
    };
  }

  const uploadWlFile = (fileInfo, userId) => {
    if (DEMO_VENDORS.includes(vendor)) {
      setField(userId, 'icon', fileInfo.base64Data);
      setLoadingImageButtons({ number: '0' });
      setTimeStamp(new Date() + timeStamp);
    } else {
      uploadWlFileBase64(fileInfo).then((resp) => {
        if (resp.resCode === 0) {
          setField(userId, 'icon', resp.response.url);
        } else {
          toast.error(resp.resStr);
        }
        setLoadingImageButtons({ number: '0' });
        setTimeStamp(new Date() + timeStamp);
      });
    }
  }

  const saveTeamData = () => {
    let data = [];
    let errors = false;
    for (let item of team) {
      data.push({ ...item, "first_name": item.firstName, "last_name": item.lastName, "phone_number": item.phoneNumber });
      if (item.firstName.trim() === "" || item.lastName.trim() === "" || item.phoneNumber.trim() === "") {
        errors = true;
        toast.error(t("PleaseEnterAllMandatoryFields"));
        break;
      }
    }
    if (!errors) {
      if (DEMO_VENDORS.includes(vendor)) {
        localStorage?.setItem(`team-${vendor}`, JSON.stringify(data));
      } else {
        saveWLAboutUsMiddleman({ userId: props.token, language: i18n.language === 'ar' ? '0' : '1', middlemen: data }).then(res => { });

      }
      props.saveDetails({ data: Data });
    }
  };

  const renderTeamMember = (item, i) => {
    return (
      <div key={i} className={`team-box`}>
        <Avatar src={item.icon} alt={item.firstName} sx={{ width: 100, height: 100, bgcolor: "#fff" }} />
        <h3 className='team-name'><span>{item.firstName}</span>
          {MODERN_TEMPLATES.includes(+templateId) ? <div>
            {item.whatsapp &&
              <a target="_blank" rel="noreferrer" href={`https://wa.me/${removeZeroFromNumberIfExists(item.whatsapp)}`}><WhatsApp /></a>
            }
            <a target="_blank" rel="noreferrer" className="no-color" href={`tel:${removeZeroFromNumberIfExists(item.phoneNumber)}`}><LocalPhoneIcon /></a>
          </div> : null}
        </h3>
        <p className='team-position'>{item.lastName}</p>
        <div className="social-icons">
          {DARK_TEMPLATES.includes(+templateId) && (
            <>
              {item.whatsapp &&
                <a target="_blank" rel="noreferrer" href={`https://wa.me/${removeZeroFromNumberIfExists(item.whatsapp)}`}><WhatsApp /></a>
              }
              <a target="_blank" rel="noreferrer" href={`tel:${removeZeroFromNumberIfExists(item.phoneNumber)}`}><Tel /></a>
            </>
          )}
          {item.snapchat &&
            <a href={`https://www.snapchat.com/${item.snapchat}`} rel="noreferrer" target="_blank"><SnapChat /></a>
          }
          {item.twitter &&
            <a href={`https://www.twitter.com/${item.twitter}`} rel="noreferrer" target="_blank"><Twitter medium /></a>
          }
          {item.instagram &&
            <a href={`https://www.instagram.com/${item.instagram}`} rel="noreferrer" target="_blank"><Instagram /></a>
          }
        </div>
        {[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? null :
          <div className="d-flex justify-content-center align-items-center w-100" style={{ gap: 10, paddingTop: templateId === 3 || templateId === 4 ? '10px' : '30px', borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
            {item.whatsapp &&
              <a target="_blank" rel="noreferrer" className="py-1 outlined-btn px-3" href={`https://wa.me/${removeZeroFromNumberIfExists(item.whatsapp)}`}><WhatsApp /> {t("WhatsApp")}</a>
            }
            <a target="_blank" rel="noreferrer" className={`py-1 primary-btn px-3 ${item.whatsapp ? "" : "w-100 justify-content-center d-flex"}`} href={`tel:${removeZeroFromNumberIfExists(item.phoneNumber)}`}><Tel /> {t("Call")}</a>
          </div>}
      </div>
    );
  }

  return (
    <div className='featured-area'>
      {props.isEdit ? <>
        <div className="row px-3">
          <div className="col-12">
            <TextField
              label={t("Tagline")}
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ maxLength: 500 }}
              value={Data.title}
              size='small'
              multiline
              rows={3}
              onChange={(v) => {
                setData({
                  ...Data, title: resolvePersianAndArabicNumbers(
                    v.currentTarget.value?.length >= 500 ? v.currentTarget.value?.substring(0, 500) : v.currentTarget.value
                  )
                });
              }}
            />
            <h6 className='rightalign mt-1 mb-3 d-flex'>
              {Data.title
                ? parseInt(Data.title.length) + "/500"
                : "0/500"}
            </h6>
          </div>
          <div className="col-12">
            <div className='custom-card with-switcher'>
              <div className="card-header">
                <div>
                  <h6>{t("Team")}</h6>
                </div>
                <div>
                  <button className="outlined-btn px-3" onClick={addNewPerson}>
                    {t("AddIndividual")}
                  </button>
                </div>
              </div>
      
              <div className="card-body">
                
                <div className='row justify-content-start'>
                  {loadingData ? <LoadingData /> :
                    team && team.length > 0 ? team.map((item, i) => (
                      <div
                        key={i}
                        className='col-11 mx-auto mb-3 add-team-container'
                        style={{ cursor: "pointer" }}>
                        <div className="row flex-column">
                          <div className="col-12 flex-column-reverse flex-md-row d-flex align-items-center justify-content-between mb-4">
                            <div className="d-flex flex-column flex-md-row align-items-center justify-content-start" style={{ gap: 8 }}>
                              <Avatar key={'icon' + item.id} src={getField(item.id, 'icon')} alt={item.firstName || 'person img'} sx={{ width: 65, height: 65, bgcolor: "#fff" }} />
                              <DragAndDropFileUploader
                                singleFile
                                ImageOnly
                                handleChange={(e) => upload(e.target.files[0], "jpg", item.id)}
                                loading={loadingImageButtons.number === item.id}
                                title={t("ChangeImage")}
                                id={`contained-file-${i}`}
                                classes="px-3 py-2"
                                buttonMode
                              />
                            </div>
                            <button className="outlined-btn px-3 mb-3 mb-md-0" onClick={() => deletePerson(item.id)}>
                              <Delete /> {t("Remove")}
                            </button>
                          </div>
                          <div className="col-12 row p-0 m-0">
                            <div className='col-12 col-sm-6 col-lg-3 mb-4'>
                              <FormControl
                                error={errors?.id === item.id && errors?.firstName ? true : false}
                                className="col-12"
                              >
                                <TextField
                                  type='text'
                                  required
                                  error={errors?.id === item.id && errors?.firstName ? true : false}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{ width: "100%" }}
                                  size='small'
                                  key={'fname' + item.id}
                                  label={t("Name")}
                                  onChange={(e) => {
                                    let value = resolvePersianAndArabicNumbers(e.target.value);
                                    setField(item.id, 'firstName', value);
                                    return value;
                                  }}
                                  value={getField(item.id, 'firstName')}
                                />
                                {errors?.id === item.id && errors?.firstName &&
                                  <FormHelperText component={Box}>
                                    <Alert severity="error" className="custom-alert">
                                      {errors?.firstName}
                                    </Alert>
                                  </FormHelperText>
                                }
                              </FormControl>
                            </div>
                            <div className='col-12 col-sm-6 col-lg-3 mb-4'>
                              <FormControl
                                error={errors?.id === item.id && errors?.lastName ? true : false}
                                className="col-12"
                              >
                                <TextField
                                  type='text'
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  required
                                  sx={{ width: "100%" }}
                                  size='small'
                                  label={t("Position")}
                                  key={'lastName' + item.id}
                                  error={errors?.id === item.id && errors?.lastName ? true : false}
                                  onChange={(e) => {
                                    setField(item.id, 'lastName', resolvePersianAndArabicNumbers(e.target.value))
                                  }}
                                  value={getField(item.id, 'lastName')}
                                />
                                {errors?.id === item.id && errors?.lastName &&
                                  <FormHelperText component={Box}>
                                    <Alert severity="error" className="custom-alert">
                                      {errors?.lastName}
                                    </Alert>
                                  </FormHelperText>
                                }
                              </FormControl>
                            </div>
                            <div className='col-12 col-sm-6 col-lg-3 mb-4'>
                              <FormControl
                                error={errors?.id === item.id && errors?.phoneNumber ? true : false}
                                className="col-12"
                              >
                                <TextField
                                  type='text'
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{ width: "100%", '& .MuiInputBase-root': { direction: 'ltr' }, '& input': { direction: 'ltr', textAlign: 'left', textAlignLast: 'left' } }}
                                  size='small'
                                  label={t("PhoneNumber")}
                                  required
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position='start' sx={{ '& p': { textAlign: 'right', direction: 'ltr' } }}>
                                        966
                                      </InputAdornment>
                                    ),
                                  }}
                                  key={'phoneNumber' + item.id}
                                  error={errors?.id === item.id && errors?.phoneNumber ? true : false}
                                  onChange={(e) => {
                                    setField(item.id, 'phoneNumber', preventString(e.target.value));
                                  }}
                                  value={getField(item.id, 'phoneNumber')}
                                />
                                {errors?.id === item.id && errors?.phoneNumber &&
                                  <FormHelperText component={Box}>
                                    <Alert severity="error" className="custom-alert">
                                      {errors?.phoneNumber}
                                    </Alert>
                                  </FormHelperText>
                                }
                              </FormControl>
                            </div>
                            <div className='col-12 col-sm-6 col-lg-3 mb-4'>
                              <TextField
                                type='text'
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                sx={{ width: "100%", '& .MuiInputBase-root': { direction: 'ltr' }, '& input': { direction: 'ltr', textAlign: 'left', textAlignLast: 'left' } }}
                                size='small'
                                key={'whatsapp' + item.id}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position='start' sx={{ '& p': { textAlign: 'right', direction: 'ltr' } }}>
                                      966
                                    </InputAdornment>
                                  ),
                                }}
                                label={t("WhatsAppNumber")}
                                onChange={(e) => {
                                  setField(item.id, 'whatsapp', preventString(e.target.value))
                                }}
                                value={getField(item.id, 'whatsapp')}
                              />
                            </div>
                          </div>
                          <div className="col-12 row px-0 mt-2 pt-3 mx-0 justify-content-center" style={{ borderTop: '1px solid #dedede' }}>
                            <div className='col-12 col-sm-6 col-lg-3 mb-4'>
                              <TextField
                                sx={{ width: "100%", '& .MuiInputBase-root': { direction: 'ltr' }, '& input': { direction: 'ltr', textAlign: 'left', textAlignLast: 'left' } }}
                                label={t("XAccount")}
                                key={'twitter' + item.id}
                                size='small' type='text'
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position='start' sx={{ '& p': { textAlign: 'right', direction: 'ltr' } }}>
                                      x.com/
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e) => {
                                  setField(item.id, 'twitter', resolvePersianAndArabicNumbers(e.target.value))
                                }}
                                value={getField(item.id, 'twitter')}
                              />
  
                            </div>
                            <div className='col-12 col-sm-6 col-lg-3 mb-4'>
                              <TextField
                                sx={{ width: "100%", '& .MuiInputBase-root': { direction: 'ltr' }, '& input': { direction: 'ltr', textAlign: 'left', textAlignLast: 'left' } }}
                                label={t("InstagramAccount")}
                                size='small' type='text'
                                key={'instagram' + item.id}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position='start' sx={{ '& p': { textAlign: 'right', direction: 'ltr' } }}>
                                      instagram.com/
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e) => {
                                  setField(item.id, 'instagram', resolvePersianAndArabicNumbers(e.target.value))
                                }}
                                value={getField(item.id, 'instagram')}
                              />
  
                            </div>
                            <div className='col-12 col-sm-6 col-lg-3 mb-4'>
                              <TextField
                                sx={{ width: "100%", '& .MuiInputBase-root': { direction: 'ltr' }, '& input': { direction: 'ltr', textAlign: 'left', textAlignLast: 'left' } }}
                                label={t("SnapChatAccount")}
                                size='small' type='text'
                                key={'snapchat' + item.id}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position='start' sx={{ '& p': { textAlign: 'right', direction: 'ltr' } }}>
                                      snapchat.com/
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e) => {
                                  setField(item.id, 'snapchat', resolvePersianAndArabicNumbers(e.target.value))
                                }}
                                value={getField(item.id, 'snapchat')}
                              />
  
                            </div>
     
                          </div>
                        </div>
                      </div>
                    )) :
                      <NoData />
                  }
                </div>
                
              </div>
            </div>
          </div>
          <div className='col-12'>
            <LoadingButton
              classes=" primary-btn m-auto"
              label={t("SaveChanges")}
              handleClick={saveTeamData}
              loading={props.loading}
            />
          </div>
        </div>
      </>
        : team.length > 0 ? CLASSIC_TEMPLATES.includes(+templateId) ? <T04OurTeam team={team} data={Data} /> :
        CLASSIC2_TEMPLATES.includes(+templateId) ? <T05OurTeam team={team} data={Data} /> :
        MODERN2_TEMPLATES.includes(+templateId) ? <T06OurTeam team={team} data={Data} /> :
        (
            <div style={{ marginBottom: '70px', marginTop: '40px' }}>
              <div className='col-12 justify-content-center align-items-center flex-column d-flex' style={{ marginBottom: '20px', marginTop: '40px' }}>
                <span className="section-prefix">{t("Team")}</span>
                <h2 className='sec-title'>{t("WePresentToYouOurDistinguishedTeam")}</h2>
                <p className="sec-desc mx-auto" style={{ width: 'calc(100% - (var(--px-meduim)/2))' }}>{Data.title}</p>
              </div>
              {team.length > 3 ? (
                <Swiper
                  className={`team-slider mt-4  p-0 ${templateId === 3 || templateId === 4 ? 'template-3__team-boxes' : ''}`}
                  spaceBetween={40}
                  key={i18n.language + 'ii'}
                  slidesPerView={team.length >= 3 ? 3 : team.length}
                  autoplay={{
                    delay: 2000,
                    pauseOnMouseEnter: false,
                    disableOnInteraction: false,
                  }}
                  modules={[Keyboard]}
                  keyboard={{ enabled: true }}
                  loop={true}
                  speed={2400}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    575: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    600: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    }, 1100: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                  }}
                >
                  {team.map((item, i) => (
                    <SwiperSlide key={i} >
                      {renderTeamMember(item, i)}
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <div className='col-12 team-boxes'>
                  {team.map((item, i) => renderTeamMember(item, i))}
                </div>
              )}
              <div>
              </div>
            </div>
          ) : null
      }
    </div>
  );
}

export default TeamSection;
