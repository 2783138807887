import React, { useState } from "react";
import AgentsPage from "./components/agents";
import * as userApi from "../../../api/userApi";
import { Box, Button, CircularProgress } from "@mui/material";
import AppLayout from "../../../components/layouts/app-layout";
import Forbidden from '../../shared/403';
import { useAuth } from "../../../helpers/context";
import { useNavigate } from "react-router-dom";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useEffect } from "react";
import SubscriptionPopup from "../../../components/user-components/subscription-popup";
import { toast } from "react-toastify";
import VerifyPopup from "../../../components/user-components/verify-popup";
import { useTranslation } from "react-i18next";

const Agents = () => {
  const { token } = useAuth();
  const [totalCount, setTotalCount] = useState(0);

  const [msg, setMsg] = useState("wait");
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const history = useNavigate();
  const [openVerifyModal, setOpenVerifyModal] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(0);
  const { t, i18n } = useTranslation();
  const [user] = useState({
    userId: token,
    language: i18n.language === 'ar' ? '0' : '1',
  });
  
  useEffect(() => {
    if (token)
      userApi.getUserProile(user).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          setIsUserVerified(_userInfo.response.userProfile.isUserVerified);
          if (_userInfo.response.userProfile.accountType === "2") setMsg("Done");
          else setMsg(t("AgentsAreForBusinessAccountOnly"));
        } else {
          const _errors = {};
          _errors.phone = _userInfo.resStr;
        }
      });
  }, // eslint-disable-next-line
    [token]);
 
  
  const handleAddAgent = () => {
    userApi.canAddAgent(user).then((result) => {
      if (result.resCode === 0) {
        if (result.response.result === '1') {
          history('/add-agent');
        } else {
          setOpenSubscriptionModal(true);
        }
      } else {
        toast.error(result.resStr);
      }
    });
  };
  
  return (
    <AppLayout needAuth pageTitle={t("Agents")}>
      {token && (msg === "Done" ? (
        <>
          <div className='page-width pd-top-90 mg-bottom-100'>
            <Box
              sx={{
                marginBottom: "40px",
                textAlign: "center !important",
                fontSize: "28px",
                fontWeight: 600,
                color: "#000",
              }}>
              {t("Agents")}
              {totalCount > 0 && (
                <Box
                  sx={{
                    textAlign: "center !important",
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#000",
                  }}>
                  {t("ThereAreAgents", { number: totalCount })}
                </Box>
              )}

              {isUserVerified !== '3' ? (
                <VerifyPopup
                  openVerifyModal={openVerifyModal}
                  setOpenVerifyModal={setOpenVerifyModal}
                  desc={t("YouMustVerifyYourAccountInOrderToContinue")}
                  trigger={
                    <Button
                    className='primary-btn py-2 px-4 mx-auto mt-3 d-flex'
                    style={{ width: 'fit-content' }}
                    onClick={() => setOpenVerifyModal(true)}
                  >
                    <PersonAddAltIcon sx={{ fontSize: "28px" }} />
                    {t("NewAccount")}
                  </Button>
                  }
                />
              ) : (<SubscriptionPopup
                setOpenSubscriptionModal={setOpenSubscriptionModal}
                openSubscriptionModal={openSubscriptionModal}
                desc={t("YouCannotAddANewAgent")}
                trigger={
                  <Button
                    className='primary-btn py-2 px-4 mx-auto mt-3 d-flex'
                    style={{ width: 'fit-content' }}
                    onClick={handleAddAgent}
                  >
                    <PersonAddAltIcon sx={{ fontSize: "28px" }} />
                    {t("NewAccount")}
                  </Button>
                }
              />)}
              
            </Box>
            <AgentsPage userId={user.userId} setTotalCount={setTotalCount} />
          </div>
         
        </>
      ) : (
        msg === 'wait' ? <div className='add-new-property-area pd-top-90 mg-bottom-100 text-center'> <CircularProgress />  </div>
          : <Forbidden btnLink={'/'} btnText={t("BackToHomePage")} desc={msg} />
      ))}
    </AppLayout>
  );
};

export default Agents;
