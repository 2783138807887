/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { SuccessRegisteration } from "../../../constants/backgrounds";
import { openDeleteModal } from "../../../helpers";
import AppLayout from "../../layouts/app-layout";
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";

const Success = (props) => {
  const { userType } = useParams();
  const location = useLocation();
  const history = useNavigate();
  const { t } = useTranslation();

  switch (userType) {
    case "4":
      return (
        <AppLayout pageTitle={t("DoneSuccessfully")}>
          <div className='page-wrapper page-width' style={{ background: `url(${publicUrl}assets/img/pattern.png) repeat` }}>
            <div className='row page-wrapper-without-top'>
              <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <h4 className='page-heading' style={{ color: 'rgba(0,0,0,0.8)' }}>{t("AccountCreatedSuccessfully")}</h4>
                  <div className="my-4">
                    <SuccessRegisteration />
                  </div>
                  <div
                    className='d-flex align-items-center justify-content-start mt-3'
                    style={{ gap: "12px" }}>
                    <Link
                      to={"/verify"}
                      className='primary-btn py-2 px-4'
                      style={{ width: "fit-content" }}>
                      {t("VerifyYourAccount")}
                    </Link>
                    <button
                      onClick={() => {
                        openDeleteModal(
                          t("ConfirmSkipAccountVerification"),
                          t("SkippingAccountVerificationWillInvalidate"),
                          () => history('/'),
                          t("Skip"));
                      }}
                      className='outlined-btn py-2 px-4 outlined-danger'
                      style={{ width: "fit-content" }}>
                      {t("Skip")}
                    </button>
                  </div>
                  <div className='mt-4'>
                    <p className='mt-3'>
                      {t("ByClickingOnVerifyYourAccount")}
                      <Link
                        to='/terms-condition'
                        style={{ color: "var(--main-color-one)" }}>
                        {t("TermsOfUse")}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </AppLayout>
      );
    default:
      return (
        <AppLayout pageTitle={t("DoneSuccessfully")}>
          <div className='page-wrapper page-width' style={{ background: `url(${publicUrl}assets/img/pattern.png) repeat` }}>
            <div className='row page-wrapper-without-top'>
              <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <h4 className='page-heading' style={{ color: 'rgba(0,0,0,0.8)' }}>
                    {location?.state?.from === "forgot-password" ? t("PasswordChangedSuccessfully") : t("AccountCreatedSuccessfully")}
                  </h4>
                  <p className='my-3' style={{ fontSize: 16, fontWeight: 600 }}>
                    {location?.state?.from === "forgot-password" ? t("PasswordChangedSuccessfully") : t("AccountCreatedSuccessfully")}
                  </p>
                  <div className="my-4">
                    <SuccessRegisteration />
                  </div>
                  <a
                    href='/'
                    className='primary-btn py-2 px-4 mt-3'
                    style={{ width: "fit-content" }}>
                    {t("GoToHomePage")}
                  </a>
                </div>
              </div>
             
            </div>
          </div>
        </AppLayout>
      );
  }
}

export default Success;
