import React, { useState, useEffect } from "react";
import { saveWLAboutUs, getWLAboutUs } from "../../../api/userApi";
import { toast } from "react-toastify";
import SubHeader from "../../../components/global-components/sub-header";
import UploadImage from "../../../components/global-components/upload-image";
import { useAuth } from "../../../helpers/context";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { DEMO_VENDORS } from "../../../constants";
import { useTranslation } from "react-i18next";
import { CircularProgress, Hidden } from "@mui/material";
import LoadingButton from "../../../components/global-components/loading-btn";

export default function AboutUs() {
  const [loading, setLoading] = useState(false);
  const { token, vendor } = useAuth();
  const [publicUrl, setPublicUrl] = useState("https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/" + vendor + "/");
  const [data, setData] = useState('');
  const [ImageTOView, setImageTOView] = useState(publicUrl + "assets/img/others/7.png?" + Math.random());
  const { t, i18n } = useTranslation();
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    setLoadingData(true);
    if (vendor) {
      setPublicUrl("https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/" + vendor + "/");
    }
    const aboutStorage = localStorage.getItem(`about-${vendor}`) ? JSON.parse(localStorage.getItem(`about-${vendor}`)) : undefined;
    if (aboutStorage?.content) {
      setData(aboutStorage?.content);
      setImageTOView(aboutStorage?.image || publicUrl + "assets/img/others/7.png?" + Math.random());
      setLoadingData(false);
    } else {
      getWLAboutUs({ userName: vendor, language: i18n.language === 'ar' ? '0' : '1' }).then(
        (_DefLocation) => {
          if (_DefLocation.resCode === 0) {
            setData(_DefLocation.response.aboutUs);
          }
        }
      ).finally(() => setLoadingData(false));
    }

    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  },// eslint-disable-next-line
    [vendor, i18n.language]);
 
  function changeImage(e) {
    setImageTOView(e ? e : (publicUrl + "assets/img/others/7.png?" + Math.random()));
  }

  function onSave() {
    if (DEMO_VENDORS.includes(vendor)) {
      setLoading(true);
      const aboutStorage = localStorage.getItem(`about-${vendor}`) ? JSON.parse(localStorage.getItem(`about-${vendor}`)) : undefined;
      localStorage?.setItem(`about-${vendor}`, JSON.stringify({
        content: data,
        image: aboutStorage?.image || ''
      }));
      toast.success(t("SavedSuccessfully"));
      setLoading(false);

    } else {
      setLoading(true);
      saveWLAboutUs({
        userId: token,
        language: i18n.language === 'ar' ? '0' : '1',
        content: data,
      })
        .then((response) => {
          if (response.resCode === 0) {
            toast.success(t("SavedSuccessfully"));
          } else {
            toast.error(response.resStr);
          }
        })
        .finally(() => setLoading(false));
    }
  }

  return (
    <>
      <SubHeader onOK={onSave} loading={loading} />
      {loadingData ? <div className='add-new-property-area pd-top-90 mg-bottom-100 text-center'>
        <CircularProgress />
      </div> :
        <>
        <div className="cards-wrapper about-page-wrapper">
          <div className='custom-card'>
            <div className="card-header">
              <h4>{t("AboutUs")}</h4>
              <h6>{t("WriteAnOverviewOfYourCompany")}</h6>
            </div>
            <div className="card-body">
              <div className={`toolbar-container`}></div>
              <CKEditor
                editor={DecoupledEditor}
                config={{
                  language: {
                    ui: i18n.language === "ar" ? 'ar' : "en",
                    content: i18n.language === "ar" ? 'ar' : "en",
                  },
                  toolbar: {
                    items: [
                      'undo', 'redo',
                      '|', 'heading',
                      '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                      '|', 'bold', 'italic', 'strikethrough',
                      '|', 'alignment',
                      '|', 'link', 'blockQuote',
                      '|', 'bulletedList', 'numberedList', 'outdent', 'indent'
                    ],
                    shouldNotGroupWhenFull: false
                  },
                  mediaEmbed: false
                }}
                                
                data={data}
                onReady={(editor) => {
                  const toolbarContainer = document.querySelector(`.toolbar-container`);
                  toolbarContainer?.appendChild(editor.ui.view.toolbar.element);
                }}
                onChange={(event, editor) => {
                  const content = editor.getData();
                  setData(content);
                }}
              />
            
            </div>
          </div>
          <div className='custom-card'>
            <div className="card-header">
              <h4>{t("MainImage")}</h4>
              <h6>{t("ChooseAnImageThatExpressesYourVision")}</h6>
            </div>
            <div className="card-body">
              <div className="m-auto d-flex flex-column justify-content-center align-items-center position-relative">
                <img className='about-img mb-4' src={ImageTOView} alt='about-img' />
                <UploadImage
                  token={token}
                  imgUrl='/assets/img/others/7.png'
                  imgSource='assets/img/others/others/'
                  section="about"
                  goNext={(e) => changeImage(e.base64Data)}
                />
              </div>
              
            </div>
          </div>
        </div>
        <Hidden mdDown>
      <div className="py-3">
        <LoadingButton
          classes='primary-btn px-5 mx-auto'
          style={{ justifyContent: 'center' }}
          handleClick={onSave}
          label={t("SaveChanges")}
          loading={loading}
        />
        </div>
        </Hidden>
        </>
      }
    </>
  );
}
