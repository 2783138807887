import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAuth } from "../../../helpers/context";


const T04AboutUS = ({ type, data }) => {
    const { t } = useTranslation();
    const { vendor } = useAuth();

    return (
        <div className="row mx-0 mt-5 align-items-center">
            <div className="col-12 col-lg-6" >
                <div data-aos='fade-up' className={`col-12  flex-column d-flex `}>
                    {type !== 'broker' && (<>
                        <span className="section-prefix tag" data-aos="fade-left" data-aos-duration="1500">{t("AboutUS1")}</span>
                        <h2 className='sec-title' data-aos="fade-up" data-aos-duration="1500">
                            {data.title1}
                        </h2>
                    </>)}
                    <p className={`sec-desc col-lg-9`}>{data.content}</p>
                    <Link style={{ marginTop: '20px', maxWidth: 'fit-content' }} className='py-2 px-4 primary-btn no-border-radius' to={type !== 'broker' ? "About" : 'ejar'}>
                        {type !== 'broker' ? t("ReadTheFullStory") : t("GoToTheRentalAgreement")}
                    </Link>
                </div>
            </div>
            <div className="col-12 col-lg-6 text-end">
                <img src={"https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/" + vendor + "/assets/img/others/7.png?" + Math.random()}
                    alt={t("AboutUS1")}
                    className="about-image" />
            </div>
        </div>
    );
}

export default T04AboutUS;
