import React, { useEffect, useState } from "react";
import SubHeader from "../../../components/global-components/sub-header";
import { useAuth } from "../../../helpers/context";
import { useNavigate } from "react-router-dom";
import { Plus } from "../../../constants/icons";
import { changeNewsStatus, getNews } from "../../../api/blogApi";
import LoadingData from "../../../components/global-components/loading-data";
import { getDemoSnakbar, openDeleteModal } from "../../../helpers";
import { toast } from "react-toastify";
import NoData from "../../../components/global-components/no-data";
import { DEMO_VENDORS } from "../../../constants";
import { ClickAwayListener, Fab, Grid, Grow, List, ListItem, ListItemButton, ListItemText, Paper, Popper } from "@mui/material";
import PostCardForAdmin from "./components/post-card-for-admin";
import { useTranslation } from "react-i18next";

const Blog = () => {
  const [data, setData] = useState([]);
  // const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const { vendor, token } = useAuth();
  const [openDemoSnackBar, setOpenDemoSnackBar] = useState(false);
  const history = useNavigate();
  const [openPopper, setOpenPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPost, setSelectedPost] = useState();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (vendor) {
      setLoadingData(true);
      getNews({
        userName: vendor,
        language: i18n.language === 'ar' ? '0' : '1',
        userId: token
      }).then((_response) => {
        if (_response.resCode === 0) {
          setData(_response.response.array);
          // setTotalCount(_response.response.totalCount);
        }
      }).finally(() => setLoadingData(false));
    }
  }, [vendor, token, i18n.language]);

  const handlePublishStatus = (id, status) => {
    changeNewsStatus({
      id,
      userId: token,
      language: i18n.language === 'ar' ? '0' : '1',
      status
    }).then((res) => {
      if (res.resCode === 0) {
        toast.success(status === '0' ? t("TheNewsHasBeenSuccessfullyUnpublished") : status === '1' ? t("TheNewsHasBeenPublishedSuccessfully") : t("TheNewsHasBeenDeletedSuccessfully"));
        setLoadingData(true);
        getNews({
          userName: vendor,
          language: i18n.language === 'ar' ? '0' : '1',
          userId: token
        }).then((_response) => {
          if (_response.resCode === 0) {
            setData(_response.response.array);
            // setTotalCount(_response.response.totalCount);
          }
        }).finally(() => setLoadingData(false));
      }
    });
  };

  return (
    <>
      <SubHeader
        OKElement={
          <Fab
            size="large"
            color="primary"
            className="ok-fab"
            onClick={() => history('/admin/news')}
          >
            <Plus sx={{ fontSize: 30 }} />
            <span>{t("NewNews")}</span>
          </Fab>
        }
      />

      <div className='projects-page m-0 blog'>
        <div className='row justify-content-center m-0'>
          <div className='row align-items-center  col-12 pd-bottom-60 m-0'>
            {loadingData ? <LoadingData /> : data.length > 0 ?
              <>
                <Grid container spacing={2}>
                  {
                    data.map((item, i) => (
                      <Grid item xs={12} md={6} lg={4} xl={3} key={i}>
                        <PostCardForAdmin
                          item={item}
                          setOpenPopper={setOpenPopper}
                          setAnchorEl={setAnchorEl}
                          openPopper={openPopper}
                          anchorEl={anchorEl}
                          setSelectedPost={setSelectedPost}
                        />
                      </Grid>
                    ))
                  }
                </Grid>
                <Popper
                  sx={{
                    zIndex: 100,
                    offset: '55px 0 0 5px',
                  }}
                  open={openPopper}
                  role={undefined}
                  transition
                  anchorEl={anchorEl}
                  disablePortal={false}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom' ? 'center top' : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={(event) => {
                          if (event.target.nodeName === 'BODY' && event.type === 'click') {
                            return;
                          } else {
                            setAnchorEl(null); setOpenPopper(false);
                          }
                        }}>
                          <List>
                            <ListItem disablePadding>
                              <ListItemButton
                                sx={{ padding: '2px 15px' }}
                                onClick={() => {
                                  history(`/admin/news-details/${selectedPost.id}`)
                                }}>
                                <ListItemText primary={t("ViewDetails")}/>
                              </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                              <ListItemButton onClick={() => {
                                history(`/admin/edit-news/${selectedPost.id}`)
                              }}
                                sx={{ padding: '2px 15px' }}
                              >
                                <ListItemText primary={t("Edit")} />
                              </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                              <ListItemButton
                                sx={{ padding: '2px 15px' }}
                                onClick={(e) => {
                                  if (DEMO_VENDORS.includes(vendor)) {
                                    setOpenDemoSnackBar(true);
                                  } else {
                                    openDeleteModal(t("ConfirmDeletion"), t("AreYouSureToDeleteThisNews"), () => handlePublishStatus(selectedPost.id, '-1'));
                                  }
                                }}>
                                <ListItemText primary={t("Delete")} />
                              </ListItemButton>
                            </ListItem>
  
                            <ListItem disablePadding>
                              <ListItemButton
                                sx={{ padding: '2px 15px' }}
                                onClick={(e) => {
                                  if (DEMO_VENDORS.includes(vendor)) {
                                    setOpenDemoSnackBar(true);
                                  } else {
                                    if (selectedPost.isPublished === '1')
                                      openDeleteModal(t("ConfirmUnpublishing"), t("AreYouSureYouWantToUnpublishThisNews"), () => handlePublishStatus(selectedPost.id, '0'), t("Unpublish"))
                                    else
                                      openDeleteModal(t("ConfirmPublication"), t("AreYouSureToPublishThisNews"), () => handlePublishStatus(selectedPost.id, '1'), t("Publish"))
                                  }
                                }}>
                                <ListItemText primary={selectedPost.isPublished === '1' ? t("Unpublish") : t("Publish")} />
                              </ListItemButton>
                            </ListItem>
                          </List>
      
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </> : <NoData msg={t("ThereAreNoNews")} />}
          </div>
        </div>
      </div>
      {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}
    </>
  );
}

export default Blog;
