import React from "react";
import { useTranslation } from "react-i18next";
import SwiperCore, { Autoplay, Keyboard,Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Autoplay]);

  
const T06Services = ({ data, desc }) => {
    const { i18n,t } = useTranslation();


    return (
        <>
            <section className="services-section">
                <div className="row mx-0 w-100 ">
                    <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                        <span className="section-prefix tag" data-aos="fade-up" data-aos-duration="1500">{t("OurServices")}</span>
                        <h2 className='sec-title text-center border-lg-start' data-aos="fade-up" data-aos-duration="1500">{t("ServicesWeProvide")}</h2>
                    </div>
                    <div className={`col-12`} data-aos="fade-up" data-aos-duration="1500">
                        <p className="sec-desc text-center">{desc}</p>
                    </div>
                </div>
                <div className="col-12">
              
                  <div className="mb-0 mb-lg-auto" style={{marginBottom: '80px'}}>
                   
                        <Swiper
                          style={{ minHeight:'350px', width:'100%' }}
                          spaceBetween={40}
                          centeredSlides
                          slidesPerView={1}
                          key={i18n.language +'ii'}
                          autoplay={{
                            delay: 2000,
                            pauseOnMouseEnter: true,
                            disableOnInteraction: true,
                          }}
                         
                        modules={[Keyboard,Pagination]}
                        keyboard={{ enabled: true }}
                        pagination={{ clickable: true, el: '.swiper-pagination1' }}
                        loop={true}
                        speed={3000}
                      >
                        {data.map((item, i) => (
                          <SwiperSlide key={i} >
                              <div className={`row template-3__about-wrapper mb-5 position-relative mx-0`}>
                              <div className='col-12 col-lg-6  template-3__about-content p-0 order-1 order-lg-0'>
                                  <div className={`process-area h-100`}>
                                    <div className='row m-0 align-items-center h-100 px-lg-5 px-0'>
                                      <div className='col-12 p-0'>
                                        <div className={`col-12 justify-content-center flex-column d-flex`}>
                                          <h2 className='sec-title text-start'>
                                            {item.title}
                                          </h2>
                                          <p className="sec-desc text-start">{item.description_small}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`col-12 col-lg-6 p-0 order-0 order-lg-1`}>
                                  <div className="video-bg">
                                    <img src={item.icon} alt={item.title} />
                                  </div>
                                </div>
                              </div>
                          </SwiperSlide>
                        ))}
                        </Swiper>
                        <div className='swiper-pagination1'></div>
                   
                  </div>
                
                </div>
            </section>
        </>
    );
}

export default T06Services;
