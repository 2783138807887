import React from "react";
import { useNavigate } from "react-router-dom";
import SubHeader from "../../../components/global-components/sub-header";
import { Plus } from "../../../constants/icons";
import AgentsPage from "../../amakkn/agents/components/agents";
import { Fab } from "@mui/material";
import { useTranslation } from "react-i18next";

const WLAgents = () => {
  const history = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <SubHeader
        OKElement={
          <Fab
            size="large"
            color="primary"
            className="ok-fab"
            onClick={() => history('/admin/add-agent')}
          >
            <Plus sx={{ fontSize: 30 }} />
            <span>{t("NewAccount")}</span>
          </Fab>
        }
      />
      <AgentsPage />
    </>
  );
}

export default WLAgents;
