import React, {  useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../helpers/context";
import { changeUserStatus, getUserProile } from "../../api/userApi";
import AppLayout from "../../components/layouts/app-layout";
import { toast } from "react-toastify";
import { ListArrow } from "../../constants/icons";
import { openDeleteModal } from "../../helpers";
import LoadingButton from "../../components/global-components/loading-btn";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const DeleteMyAccount = () => {
  const { token, setToken, setUserType, setUserId, setAccountType, UserId } = useAuth();
  let { userId } = useParams();
  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const history = useNavigate();
  const { t, i18n } = useTranslation();

  const getUserData = (id) => {
    getUserProile({
      userId: id ? id : token,
      language: i18n.language === 'ar' ? '0' : '1',
    }).then((_userInfo) => {
      if (_userInfo.resCode === 0) {
        if (id) {
          setToken(id);
          setAccountType(_userInfo.response.userProfile?.accountType);
          setUserId(_userInfo.response.userProfile?.userId);
          setUserType(_userInfo.response.userProfile?.userType);
        }
      } else {
        toast.error(_userInfo.resStr);
      }
    });
  };

  useEffect(
    () => {
      if (userId) {
        getUserData(userId);
      } else {
        getUserData();
      }
    }, // eslint-disable-next-line
    [userId]
  );

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <AppLayout needAuth={!userId} pageTitle={t("DeleteAmakknAccount")} withoutNav={userId}>
      <div className="page-width favorite-area pd-top-90 mg-bottom-100 text-center">
        <Box
          sx={{
            marginBottom: "40px",
            textAlign: "center !important",
            fontSize: "28px",
            fontWeight: 600,
            color: "#000",
          }}>
          {t("DeleteAmakknAccount")}
        </Box>
        <div
          className={`row align-items-center justify-content-end`}
          style={{ marginTop: '-50px !important' }}
        >
          <div className="col-12 p-0 mb-2">
            <div className='custom-card' style={{ minHeight: 'auto' }}>
              <div className="card-header">
                <h4 style={{ color: 'var(--main-color-one)' }}>{t("GeneralControls")}</h4>
              </div>
              <div className="card-body">
                <ul className="custom-list">
                  <li><ListArrow style={i18n.language ==="en"? {transform:'rotate(180deg)'}:{}} />
                    {t("AllPersonalInformationEnteredByYouWillBeDeleted")}
                  </li>
                  <li><ListArrow style={i18n.language ==="en"? {transform:'rotate(180deg)'}:{}} />
                    {t("AllRealEstateADsPublishedByYouWillBeDeleted")}
                  </li>
                  <li><ListArrow style={i18n.language ==="en"? {transform:'rotate(180deg)'}:{}} />
                    {t("YourSubscriptionWillBeDeleted")}
                  </li>
              
                </ul>
                <LoadingButton
                  loading={isSubmittingData}
                  classes="primary-btn delete-btn d-flex  justify-content-center mt-4 px-5 mr-2"
                  handleClick={() => {
                    openDeleteModal(t("DeleteMyAccount"), t("AreYouSureYouWantToDeleteYourAccountYouCannotUndoThisProcess"), () => {
                      setIsSubmittingData(true);
                      changeUserStatus({
                        userId: UserId,
                        status: '-1',
                        language: i18n.language === 'ar' ? '0' : '1',
                        adminId: token
                      }).then((res) => {
                        if (res.resCode === 0) {
                          history("/");
                          setToken(undefined);
                          setUserId(undefined);
                          setUserType(undefined);
                          setAccountType(undefined);
                          document.cookie.split(";").forEach(function (c) {
                            if (!c.includes('AmmaknConsent'))
                              document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
                          });
                          sessionStorage?.clear?.();
                          localStorage?.clear?.();
                        } else {
                          toast.error(res.resStr);
                        }
                       
                      }).finally(() => setIsSubmittingData(false))
                    });
                  
                  }}
                  label={t("DeleteMyAccount")}
                />
                  
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default DeleteMyAccount;
