import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Error = () => {
  const { t } = useTranslation();

  return (
    <div className='error-page'>
      <h2>404</h2>
      <p>{t("OopsSomethingWrong")}</p>
      <Link to='/'>{t("GoToHomePage")}</Link>
      <img src="/assets/img/cloud.svg" alt="cloud" className="cloud cloud-1" />
      <img src="/assets/img/cloud.svg" alt="cloud" className="cloud cloud-2" />
      <img src="/assets/img/cloud.svg" alt="cloud" className="cloud cloud-3" />
      <img src="/assets/img/cloud.svg" alt="cloud" className="cloud cloud-4" />
      <img src="/assets/img/404.svg" alt="404" className="image-404" />
    </div>
  );
}

export default Error;
