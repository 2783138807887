import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Pagination } from "swiper";
import { Marker } from "../../../constants/icons";
import { useMediaQuery } from "@mui/material";

const publicUrl = process.env.REACT_APP_URL + "/";

const T04FeaturedProjects = ({ data, selectedProjects }) => {
    const { t, i18n } = useTranslation();
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));

    return (
        <div>
            <div className="row mx-0 w-100" style={{ marginBottom: '70px', marginTop: '40px' }}>
                <div className="col-12 col-lg-5 d-flex flex-column" >
                    <span className="section-prefix tag" data-aos="fade-left" data-aos-duration="1500">{t("OurProjects")}</span>
                    <h2 className='sec-title text-start' data-aos="fade-up" data-aos-duration="1500">{t("HereIsACollectionOfOurDistinctiveProjects")}</h2>
                </div>
                <div className={`col-12 col-lg-7 ${data.title ? 'border-lg-start' : ''}`} data-aos="fade-right" data-aos-duration="1500">
                    <p className="sec-desc text-start">{data.title}</p>
                </div>
            </div>
            <div className='col-12 news-details-wrap mt-4 row mx-0 p-0 projects-page justify-content-center'>
                {selectedProjects.length > 0 &&
                    selectedProjects.map((item, i) => (
                        <div key={item.id} className='col-12 h-100 w-100 my-5 project-big' data-aos="fade-up" data-aos-duration="1500">
                            <div className={`row w-100 mx-0 h-100 align-items-center ${i === 1 ? 'flex-row-reverse' : ''}`}>
                                <div className={`col-12 col-lg-6 h-100 d-flex flex-column justify-content-center ${i === 1 ? 'pr-4' : ''}`}>
                                    <h4 className='title property-name' style={{ color: 'var(--main-color-one)', fontSize: '27px' }}>{item.name}</h4>
                                    <h4 className='title project-desc'>{item.description}</h4>
                                    {isLargeScreen && <h5 className='project-address mt-3'> <Marker /> &nbsp; {item.address}</h5>}

                                    <Link to={"/project/" + item.id} className="mt-4 mb-5 btn primary-btn no-border-radius" style={{ width: 'fit-content', height: '40px' }}>
                                        {t('ReadMore')}
                                    </Link>

                                    <div className='project-stats-wrapper'>
                                        <h5 className='project-stats'>
                                            <span>{Intl.NumberFormat("en").format(item.area)}</span>
                                            <span>{t('Area')}</span>
                                        </h5>
                                        
                                        <h5 className='project-stats'>
                                            <span>{Intl.NumberFormat("en").format(item.totalUnits)}</span>
                                            <span>{t('ResidentialUnit1')}</span>
                                        </h5>

                                        <h5 className='project-stats'>
                                            <span>{item.status}</span>
                                            <span>{t('Status')}</span>
                                        </h5>
                                    </div>
                                </div>
                                    
                                <div className='col-12 col-lg-6 h-100 shine-animate-item' style={{ overflow: 'hidden' }}>
                                    {item.images?.length > 0 ?
                                        <Swiper
                                            spaceBetween={15}
                                            slidesPerView={1}
                                            key={i18n.language + 'ii'}
                                            style={{ height: isLargeScreen ? '500px': '300px', width: '100%' }}
                                            modules={[Keyboard, Pagination]}
                                            keyboard={{ enabled: true }}
                                            pagination={{
                                                clickable: true, el: `.swiper-pagination.pagination-${item.id}`,
                                                dynamicBullets: true,
                                                dynamicMainBullets: 4
                                            }}
                                            speed={2600}>
                                            {item.images?.map((image, index) => (
                                                <SwiperSlide key={index} className="shine-animate">
                                                    <img src={image} alt={`${item.name} ${index}`} className="w-100 h-100" style={{ objectFit: 'cover' }} />
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                        :
                                        <img
                                            src={item.images.length > 0 ? item.images[0] : publicUrl + "assets/img/defimgs/1.svg"}
                                            alt={item.name}
                                            className={item.images.length > 0 ? "shine-animate" : "contain-img shine-animate"}
                                        />}
                                    {item.images?.length > 0 ? <div className={`swiper-pagination pagination-${item.id}`}></div> : null}
                                            
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
}

export default T04FeaturedProjects;
