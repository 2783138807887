import { AppleStoreBtn, GooglePlayBtn, HuaweiAppGalleryBtn } from "../../constants/assets";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

const DownloadBanner = () => {

  const { t } = useTranslation();

  function handleGoogleClick() {
    const analytics = firebase.analytics();
    analytics.logEvent("play_store_website", { platform: "website" });
    window.open('https://play.google.com/store/apps/details?id=com.amakknapp.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1', '_blank');
  }

  function handleAppleClick() {
    const analytics = firebase.analytics();
    analytics.logEvent("app_store_website", { platform: "website" });
    window.open('https://itunes.apple.com/us/app/amakkn/id1220303072', '_blank');
  }
    
  function handleHuaweiClick() {
    window.open(
      "https://url.cloud.huawei.com/kcZJ8WHuHm",
      "_blank"
    );
  }
  
  return (
    <div className="download-banner" data-aos="fade-up" data-aos-duration="1500">
      <div className="right-section">
        <h3>{t("DownloadAmakknApp")}</h3>
        <div className="btns-wrapper">
          <GooglePlayBtn onClick={handleGoogleClick} />
          <AppleStoreBtn onClick={handleAppleClick} />
          <HuaweiAppGalleryBtn onClick={handleHuaweiClick} />

        </div>
      </div>
      <LazyLoadImage src="/assets/img/arrow.svg" alt="arrow" className="arrow" />
      <div className="left-section">
        <LazyLoadImage src="/assets/img/banner/download-banner-01.png" alt="banner-01" />
        <LazyLoadImage src="/assets/img/banner/download-banner-02.png" alt="banner-02" />
      </div>
    </div>
  );
}

export default DownloadBanner;