import React, { useEffect, useState } from "react";
import { getWLAboutUs } from "../../api/userApi";
import PageHeader from "../../components/global-components/page-header";
import { useAuth } from "../../helpers/context";
import purify from 'dompurify';
import { MODERN_TEMPLATES } from "../../constants";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { vendor, templateId } = useAuth();
  const [Content, setContent] = useState("");
  const [image, setImage] = useState('');
  const { t, i18n } = useTranslation();
 
  useEffect(() => {
    if (vendor) {
   
      const aboutStorage = localStorage.getItem(`about-${vendor}`) ? JSON.parse(localStorage.getItem(`about-${vendor}`)) : undefined;
      if (aboutStorage?.content) {
        setContent(purify.sanitize(aboutStorage?.content));
        setImage(aboutStorage?.image || ("https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/" +
          vendor + "/assets/img/others/7.png?" + Math.random()));
      } else {
        getWLAboutUs({ userName: vendor, language: i18n.language === 'ar' ? '0' : '1' }).then(
          (_DefLocation) => {
            if (_DefLocation.resCode === 0) {
              setContent(purify.sanitize(_DefLocation.response.aboutUs));
            }
          }
        );
        setImage("https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/" +
          vendor + "/assets/img/others/7.png?" + Math.random());
      }
    }
     
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, [vendor, i18n]);


  return (
    <>
      <PageHeader
        name={t("AboutUS")}
        imageUrl={MODERN_TEMPLATES.includes(+templateId) ? image : null}
        imgCover={MODERN_TEMPLATES.includes(+templateId)}
        moreHeight={MODERN_TEMPLATES.includes(+templateId)}
      />
      <div className='about-area pd-top-100 pd-bottom-90'>
        <div className='container pd-top-50'>
          <div className='row justify-content-between m-0'>
            {MODERN_TEMPLATES.includes(+templateId) ? null : <div className='col-lg-5 mb-4 mb-lg-0'>
              <div className='shape-image-list-wrap'>
                <div className='shape-image-list left-top'>
                  {image && <img
                    className='shadow-img'
                    src={image}
                    alt={"imagealt"}
                  />}
                </div>
              </div>
            </div>}
            
            <div className={MODERN_TEMPLATES.includes(+templateId) ? 'col-12' : 'col-lg-6'}>
              <div
                className={`${vendor === "amakkn" ? 'text-center' : ""}`}
                style={{ wordWrap: 'break-word' }}
                >
                {vendor === "amakkn" && (
                  <>
                    <h1 className='sub-title'>{t("AboutUS")}</h1>
                    <h2 className='title'>{t("BestRealEstateWebsiteInSaudiArabia")}</h2>
                  </>
                )}
                <div dangerouslySetInnerHTML={{__html: Content}}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AboutUs;
