import { Close } from '@mui/icons-material';
import { Collapse, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Pagination, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import LoadingData from '../../../../components/global-components/loading-data';
import NoData from '../../../../components/global-components/no-data';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { toast } from 'react-toastify';
import LoadingButton from '../../../../components/global-components/loading-btn';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useAuth } from '../../../../helpers/context';
import { CustomerDetailsContent } from '../../../admin/customers/customer-details';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getCustomers, getWLProfile } from '../../../../api/userApi';
import SendWhatsAppMsg from '../../../../components/send-whatsapp-msg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ActionsPopper from '../../../../components/global-components/actions-popper';
import { useTranslation } from 'react-i18next';
import { renderCustomerType, renderFeaturesForWhatsAppMsg, trimPhone } from '../../../../helpers';

const Row = ({ expendedRow, setExpendedRow, row, property, setCustomer, setAnchorEl, setOpenPopper, openPopper, anchorEl }) => {
    const { t } = useTranslation();
    
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' }, background: row.id === expendedRow ? '#fafafa' : 'none' }}>
                <TableCell align="right" className="small-col">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        className="custom-btn"
                        sx={{ background: row.id === expendedRow ? 'var(--main-color-one)' : '#fff', borderRadius: '8px' }}
                        onClick={() => setExpendedRow((old) => old === row.id ? 0 : row.id)}
                    >
                        {row.id === expendedRow ? <KeyboardArrowUpIcon sx={{ color: '#fff' }} /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
  
                <TableCell align="right" component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="right"><a href={`tel:${trimPhone(row.phone)}`} style={{ color: 'var(--main-color-one)', textDecoration: 'underline',direction:'ltr',display:'inline-block' }}>{trimPhone(row.phone)}</a></TableCell>
                <TableCell align="right">{row.email || t("Undefined")}</TableCell>
                <TableCell align="right">{renderCustomerType(row.type) || t("Undefined")}</TableCell>
                <TableCell align="right">
                    <p
                        title={row.notes ? row.notes.length > 70 ? row.notes : '' : ''}
                        className='text-more'
                        style={{ whiteSpace: "pre-line" }}>
                        {row.notes
                            ? row.notes.length > 70
                                ? row.notes.substr(0, 70) + "... "
                                : row.notes : t("Undefined")}
                    </p>
                </TableCell>
                <TableCell align="right">
                    <IconButton
                        onClick={(e) => {
                            setOpenPopper(!openPopper);
                            setAnchorEl(anchorEl ? null : e.target);
                            setCustomer(row);
                        }}
                        sx={{
                            background: '#fff',
                            zIndex: 99,
                            color: 'var(--main-color-one)',
                            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                            '&:hover,&:focus': {
                                color: '#fff',
                                background: 'var(--main-color-one)'
                            }
                        }}>
                        <MoreVertIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow sx={{ background: row.id === expendedRow ? '#fafafa' : 'none' }}>
                <TableCell style={{ paddingBottom: row.id === expendedRow ? '40px' : 0, paddingTop: row.id === expendedRow ? '15px' : 0 }} colSpan={7}>
                    <Collapse in={row.id === expendedRow} timeout="auto" unmountOnExit>
                        <CustomerDetailsContent client={row} companyName={""} propertyId={property?.propertyId} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const PropertyInterestsModal = ({ data, property, open, onClose, loadingData, propertyName, paginationOptions, setPaginationOptions, totalCount }) => {
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const { token, vendor } = useAuth();
    const [whatsAppDialog, setWhatsAppDialog] = useState({
        visible: false,
        message: '',
        numbers: [],
        multiple: false,
        customers: []
    });
    const [expendedRow, setExpendedRow] = useState(0);
    const [customer, setCustomer] = useState();
    const [openPopper, setOpenPopper] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t, i18n } = useTranslation();
    const [siteName, setSiteName] = useState('');

    const exportToExcel = (propertyName, data) => {
        return new Promise(function (resolve, reject) {
            var req = new XMLHttpRequest();
            var url = '/assets/excel-templates/PROPERTY_INTERESTS.xlsx';
            req.open("GET", url, true);
            req.responseType = "arraybuffer";
            req.onreadystatechange = function () {
                if (req.readyState === 4) {
                    if (req.status === 200) {
                        resolve(XlsxPopulate.fromDataAsync(req.response));
                    } else {
                        reject("Received a " + req.status + " HTTP code.");
                    }
                }
            };
            req.send();
        })
            .then(function (workbook) {
                let i = 7;
                // const arr = [...Array(30000).map((x, index) => ({ 'name': `user ${index}`, 'phone': `user ${index}`, 'unit': '' }))];
                if (data && data.length > 0) {
                    workbook.sheet(0).cell('A1').value(`${t("InterestedIn")} ${propertyName}`);

                    data.map(line => {
                        workbook.sheet(0).cell(`A${i}`).value(line?.name);
                        workbook.sheet(0).cell(`B${i}`).value(line?.phone);
                        workbook.sheet(0).cell(`C${i}`).value(line?.email || t("Undefined"));
                        workbook.sheet(0).cell(`D${i}`).value(line?.type || t("Undefined"));
                        workbook.sheet(0).cell(`E${i}`).value(line?.notes || t("Undefined"));
                        i++;
                        return null;
                    });
                }
                return workbook.outputAsync();
            })
            .then(function (blob) {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.href = url;
                a.download = `${t("InterestedIn")} ${propertyName}.xlsx`;
                setLoading(false);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
                toast.success(t("TheFileWasExportedSuccessfully"));
            })
            .catch(function (err) {
                console.log(err);
                toast.error(t("TheFileWasNotExported"));
                setLoading(false);
            });
    };

    useEffect(() => {
        getWLProfile({ userName: vendor, language: i18n.language === 'ar' ? '0' : '1' }).then((_userInfo) => {
            if (_userInfo.resCode === 0) {
                setSiteName(window.location.href.includes('amakkncompany.com') ? 'مكتب أحمد للعقارات (مثال)' : _userInfo.response.wlUser.briefDescription);
            }
        });
    }, [vendor, i18n]);

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={"xl"}
                open={open}
                className="custom-dialog model "
                onClose={onClose}>
                <DialogTitle>
                    <div className="dialog-head">
                        <div>
                            <h2>{t("ListOfCustomersInterestedInThisRealEstateAD")}</h2>
                        </div>
                        <div className="dialog-actions">
                            <span onClick={onClose} style={{ cursor: 'pointer' }}>
                                <Close />
                            </span>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className='row justify-content-start p-0 mt-3'>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '500px' }}>
                            {!loadingData && totalCount > 0 && (<Stack direction={"row"} alignItems="center" gap={4}>
                                <LoadingButton
                                    loading={loading}
                                    classes="primary-btn btn export-btn px-3 mb-3 py-3"
                                    style={{ width: 'fit-content',height:'40px'  }}
                                    handleClick={() => {
                                        setLoading(true);
                                        getCustomers({
                                            propertyId: property?.propertyId,
                                            page: 1,
                                            pageSize: 1000000,
                                            userId: token
                                        }).then(res => {
                                            if (res.resCode === 0) {
                                                exportToExcel(propertyName, res.response.array)
                                            } else {
                                                toast.error(res.resStr);
                                            }
                                        }).finally(() => setLoading(false));
                                    }}
                                    label={t("ExportToExcel")}
                                    icon={<InsertDriveFileIcon />}
                                />
                                <LoadingButton
                                    loading={loading2}
                                    OKdisabled={loading2}
                                    classes="primary-btn btn whatsapp-button px-3 mb-3 py-3"
                                    style={{ width: 'fit-content',height:'40px'  }}
                                    handleClick={() => {
                                        setLoading2(true);
                                        getCustomers({
                                            propertyId: property?.propertyId,
                                            page: 1,
                                            pageSize: 1000000,
                                            userId: token
                                        }).then(res => {
                                            if (res.resCode === 0) {
                                                setWhatsAppDialog({
                                                    message: `السلام عليكم،\n\nمتوفّر لدينا ${property.propertyTypeName} ${property.listedFor === '1' ? t("ForRent") : t("ForSale")} في${property?.fullAddress?.split(",")?.[1]}، حي${property?.fullAddress?.split(",")?.[2]}\nالسعر: ${Intl.NumberFormat("en").format(property.defaultPrice)} ريال${property.listedFor === '1' ? `/${property.defaultPriceType.name}`:''}\n\n${renderFeaturesForWhatsAppMsg(property)}\nرقم ترخيص الإعلان: ${property.additionalInfo?.filter(i=> i.key ==="10")?.[0]?.valueText || t("NotAvailable")}\nرابط الإعلان: ${window.location.origin}/property-details/${property.idToShare}\n\n${siteName}\nرخصة فال: ${property.additionalInfo?.filter(i=> i.key ==="21")?.[0]?.valueText || t("NotAvailable")}`,
                                                    numbers: res.response.array?.map(item => item.phone),
                                                    visible: true,
                                                    multiple: true,
                                                    customers: res.response.array
                                                });
                                            } else {
                                                toast.error(res.resStr);
                                            }
                                        }).finally(() => setLoading2(false));
                                       
                                    }}
                                    label={t("ContactViaWhatsApp")}
                                    icon={<WhatsAppIcon />}
                                />
                            </Stack>)}
                            <TableContainer className="table-container" component={Paper}>
                                <Table sx={{ minWidth: '100%' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="small-col" />
                                            <TableCell align="right">{t("Name")}</TableCell>
                                            <TableCell align="right">{t("PhoneNumber")}</TableCell>
                                            <TableCell align="right">{t("Email")}</TableCell>
                                            <TableCell align="right">{t("CustomerType")}</TableCell>
                                            <TableCell align="right" style={{ maxWidth: '100px' }}>{t("Notes")}</TableCell>
                                            <TableCell align="right" >{t("Actions")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loadingData ? <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        ><TableCell colSpan={7}><LoadingData /></TableCell></TableRow> :
                                            data?.length > 0 ? data.map((row) => (
                                                <Row
                                                    setWhatsAppDialog={setWhatsAppDialog}
                                                    key={row.id}
                                                    property={property}
                                                    expendedRow={expendedRow}
                                                    setExpendedRow={setExpendedRow}
                                                    openPopper={openPopper}
                                                    anchorEl={anchorEl}
                                                    setAnchorEl={setAnchorEl}
                                                    setOpenPopper={setOpenPopper}
                                                    setCustomer={setCustomer}
                                                    row={row} />
                                            )) :
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                ><TableCell colSpan={7}><NoData msg={t("NoInterestedCustomersWereFound")} /></TableCell></TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className='row justify-content-between align-items-center mt-4 px-2'>
                                <div className='col-12 col-sm-6'>
                                    {data?.length > 0 && (
                                        <FormControl sx={{ width: 130 }}>
                                            <InputLabel id='pageSize'>{t("PageSize")}</InputLabel>
                                            <Select
                                                labelId='pageSize'
                                                id='pageSize'
                                                name='pageSize'
                                                value={paginationOptions.pageSize}
                                                size='small'
                                                label={t("PageSize")}
                                                onChange={(e) => {
                                                    setPaginationOptions({ ...paginationOptions, pageSize: e.target.value });
                                                }}>
                                                <MenuItem value={10} key={10}>10</MenuItem>
                                                <MenuItem value={25} key={25}>25</MenuItem>
                                                <MenuItem value={50} key={50}>50</MenuItem>
                                                <MenuItem value={100} key={100}>100</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                </div>
                                <div className='col-12 col-sm-6'>
                                    {totalCount > paginationOptions.pageSize && (
                                        <Pagination
                                            sx={{ '& .MuiPagination-ul': { flexDirection: 'row', marginTop: '25px' } }}
                                            count={parseInt(Math.ceil(totalCount / paginationOptions.pageSize))}
                                            page={paginationOptions.page}
                                            onChange={(event, value) => {
                                                setPaginationOptions({ ...paginationOptions, page: +value });
                                            }}
                                            color="primary" />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
           
            <ActionsPopper
                openPopper={openPopper}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setOpenPopper={setOpenPopper}
                items={
                    [
                        {
                            title: t("ContactViaWhatsApp"),
                            action: () => setWhatsAppDialog({
                                numbers: [customer.phone],
                                message: `السيد ${customer.name}،\nالسلام عليكم،\n\nمتوفّر لدينا ${property.propertyTypeName} ${property.listedFor === '1' ? t("ForRent") : t("ForSale")} في${property?.fullAddress?.split(",")?.[1]}، حي${property?.fullAddress?.split(",")?.[2]}\nالسعر: ${Intl.NumberFormat("en").format(property.defaultPrice)} ريال${property.listedFor === '1' ? `/${property.defaultPriceType.name}`:''}\n\n${renderFeaturesForWhatsAppMsg(property)}\nرقم ترخيص الإعلان: ${property.additionalInfo?.filter(i=> i.key ==="10")?.[0]?.valueText || t("NotAvailable")}\nرابط الإعلان: ${window.location.origin}/property-details/${property.idToShare}\n\n${siteName}\nرخصة فال: ${property.additionalInfo?.filter(i=> i.key ==="21")?.[0]?.valueText || t("NotAvailable")}`,
                                visible: true,
                                multiple: false,
                                customers: []
                            })
                        },
                        // {
                        //     title: "اتصال مباشر",
                        //     action: () => window.open(`tel:${trimPhone(customer.phone)}`, '_blank')
                        // },
                        customer && customer?.email && {
                            title: t("ContactViaEmail"),
                            action: () => window.open(`mailto:${customer?.email}`, '_blank')
                        }
                    ]
                }
            />
            <SendWhatsAppMsg
                dialog={whatsAppDialog}
                setDialog={setWhatsAppDialog}
            />
        </>
    );
}

export default PropertyInterestsModal;
