import React from "react";
import { Link } from "react-router-dom";
import { SuccessRegisteration } from "../../../constants/backgrounds";
import AppLayout from "../../layouts/app-layout";
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";

const SuccessResetPassword = () => {

  const { t } = useTranslation();

  return (
    <AppLayout pageTitle={t("SetPassword")} >
      <div className='page-wrapper page-width wl-login' style={{ background: `url(${publicUrl}assets/img/pattern.png) repeat` }}>
        <div className='row page-wrapper-without-top'>
          <div className="col-12 d-flex flex-column justify-content-center align-items-center">
            <div className='d-flex flex-column justify-content-center align-items-center'>
              <h4 className='page-heading' style={{ color: 'rgba(0,0,0,0.8)' }}>{t("PasswordChangedSuccessfully")}</h4>
              <div className="my-4"><SuccessRegisteration /></div>
              <Link
                className='primary-btn py-2 px-4 mt-3'
                style={{ width: "fit-content" }}
                to={'/'}
              >
                {t("GoToHomePage")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  ); 
}

export default SuccessResetPassword;
