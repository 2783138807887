import React from "react";
import ContactInfo from "../../../components/contact-info";
import { useRef } from "react";
import SubHeader from "../../../components/global-components/sub-header";
import { useState } from "react";
import LoadingButton from "../../../components/global-components/loading-btn";
import { useTranslation } from "react-i18next";
import { Hidden } from "@mui/material";

const ContactPage = () => {
  const [loading, setLoading] = useState(false);
  const childRef = useRef(null);
  const { t } = useTranslation();

  return (
    <>
      <SubHeader
        onOK={() => childRef.current.onSave()}
        loading={loading} />
      <div className='custom-card'>
        <div className="card-header">
          <h4>{t("ContactInformation")}</h4>
          <h6>{t("ContactInformationDesc")}</h6>
        </div>
        <div className="card-body">
          <ContactInfo setLoading={setLoading} ref={childRef} />
        </div>
      </div>
      <Hidden mdDown>
        <div className="py-3">
          <LoadingButton
            classes='primary-btn px-5 mx-auto'
            style={{ justifyContent: 'center' }}
            handleClick={() => childRef.current.onSave()}
            label={t("SaveChanges")}
            loading={loading}
          />
        </div>
      </Hidden>
    </>
  );
}

export default ContactPage;
