import { Avatar, Skeleton } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function PropertyLoader({ isMap }) {
    const { i18n } = useTranslation();
    return (
        <div
            className={`property-details-area ${isMap ? "property-details-area2 p-0" : ""
                }`}>
            <div className='row m-0'>
                <div className='col-12'>
                    <div className='property-details-slider-info'>
                        <div className='row fixed-scroll'>
                            <div className={`col-12 col-lg-8 ${i18n.language === "ar" ? 'pl-lg-5' : 'pr-lg-5'}`}>
                                <div className='row '>
                                    <div className='col-12 property-details-slider p-0'>
                                        <Skeleton
                                            className='d-none d-lg-block'
                                            variant='rectangular'
                                            width='100%'
                                            height={550}
                                            sx={{ marginBottom: "15px", borderRadius: "12px" }}
                                        />
                                        <Skeleton
                                            className='d-block d-lg-none'
                                            variant='rectangular'
                                            width='100%'
                                            height={275}
                                            sx={{ marginBottom: "15px", borderRadius: "12px" }}
                                        />

                                        <div className='row justify-content-center mb-5'>
                                            <Skeleton
                                                variant='rectangular'
                                                width={180}
                                                height={100}
                                                sx={{ margin: "0 15px", borderRadius: "12px" }}
                                            />

                                            <Skeleton
                                                className='d-none d-lg-block'
                                                variant='rectangular'
                                                width={180}
                                                height={100}
                                                sx={{ margin: "0 15px", borderRadius: "12px" }}
                                            />
                                            <Skeleton
                                                className='d-none d-lg-block'
                                                variant='rectangular'
                                                width={180}
                                                height={100}
                                                sx={{ margin: "0 15px", borderRadius: "12px" }}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 '>
                                        <Skeleton variant='rectangular' width='100%' height={70} />
                                    </div>
                                    <div className='col-12 property-sub-header'>
                                        <h3>
                                            {" "}
                                            <Skeleton variant='rectangular' width={100} height={25} />
                                        </h3>
                                        <p>
                                            {" "}
                                            <Skeleton variant='rectangular' width={180} height={15} />
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className={`col-12 col-lg-4 widget widget-owner-info mb-5 ${isMap ? "no-sticky" : ""
                                    }`}>
                                <div className='owner-info'>
                                    <div className='thumb'>
                                        <Skeleton variant='circular'>
                                            <Avatar sx={{ width: 75, height: 75 }} />
                                        </Skeleton>
                                    </div>
                                    <div className='details'>
                                        <h5>
                                            <Skeleton
                                                variant='rectangular'
                                                width={150}
                                                height={20}
                                                sx={{ marginBottom: "8px" }}
                                            />
                                        </h5>
                                        <span className='designation'>
                                            <Skeleton variant='rectangular' width='45%' height={12} />
                                        </span>
                                    </div>
                                </div>

                                <div>
                                    <div className='contact'>
                                        <Skeleton
                                            variant='rectangular'
                                            width='100%'
                                            height={45}
                                            sx={{ borderRadius: "48px" }}
                                        />
                                    </div>
                                    <div className='contact-info'>
                                        <h6 className='mb-2'>
                                            <Skeleton variant='rectangular' width='40%' height={18} />
                                        </h6>
                                        <div className='media'>
                                            <div className='media-left'>
                                                <Skeleton variant='rectangular'>
                                                    <Avatar sx={{ width: 24, height: 24 }} />
                                                </Skeleton>
                                            </div>
                                            <div className='media-body'>
                                                <p>
                                                    <Skeleton
                                                        variant='rectangular'
                                                        width='35%'
                                                        height={12}
                                                        sx={{ marginBottom: "8px" }}
                                                    />
                                                </p>
                                                <span>
                                                    <Skeleton
                                                        variant='rectangular'
                                                        width='55%'
                                                        height={12}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div className='media'>
                                            <div className='media-left'>
                                                <Skeleton variant='rectangular'>
                                                    <Avatar sx={{ width: 24, height: 24 }} />
                                                </Skeleton>
                                            </div>
                                            <div className='media-body'>
                                                <p>
                                                    <Skeleton
                                                        variant='rectangular'
                                                        width='35%'
                                                        height={12}
                                                        sx={{ marginBottom: "8px" }}
                                                    />
                                                </p>
                                                <span>
                                                    <Skeleton
                                                        variant='rectangular'
                                                        width='55%'
                                                        height={12}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div className='media'>
                                            <div className='media-left'>
                                                <Skeleton variant='rectangular'>
                                                    <Avatar sx={{ width: 24, height: 24 }} />
                                                </Skeleton>
                                            </div>
                                            <div className='media-body'>
                                                <p>
                                                    <Skeleton
                                                        variant='rectangular'
                                                        width='35%'
                                                        height={12}
                                                        sx={{ marginBottom: "8px" }}
                                                    />
                                                </p>
                                                <span>
                                                    <Skeleton
                                                        variant='rectangular'
                                                        width='55%'
                                                        height={12}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
