import React, { useState } from "react";
// import Geocode from "react-geocode";
import { Map } from "google-maps-react";
// let publicUrl = process.env.REACT_APP_URL + "/";

const mapStyles = {
  width: "99%",
  height: "90%",
  position: "relative",
  scrolling: "no",
};

const MapContainer = (props) => {
  let googleMap = React.createRef();
  const [state, setState] = useState({
    markers: [
      {
        name: "Current position",
        position: {
          lat: props.lat,
          lng: props.lng,
        },
        zoom: 12,
      },
    ],
  });

  props.onZoomChange?.(state.markers[0].zoom);

  function BoundsChanged(map) {
    if (googleMap.map) {
      if (googleMap.map.getCenter()) {
        const latt = googleMap.map.getCenter().lat();
        const lngo = googleMap.map.getCenter().lng();
        geoAddress(latt, lngo);
        setState({
          markers: [
            {
              name: "Current position",
              position: {
                lat: latt,
                lng: lngo,
              },
              zoom: googleMap.map.zoom,
            },
          ],
        });

        // if (googleMap.map.getBounds()) props.onZoomChange(googleMap.map.zoom);
      }
    }
  }

  // function onMarkerDragEnd(coord, index, t) {
  //   const { latLng } = coord;
  //   const lat = latLng.lat();
  //   const lng = latLng.lng();
  //   geoAddress(lat, lng);

  //   setState((prevState) => {
  //     const markers = [...state.markers];
  //     markers[index] = { ...markers[index], position: { lat, lng } };
  //     return { markers };
  //   });
  // }

  function geoAddress(lat, lng) {
    let NewUser = props.user;
    NewUser.latitude = lat.toString();
    NewUser.longitude = lng.toString();
    NewUser.isLocationChanged = "1";
    var latLng = new window.google.maps.LatLng(lat, lng);
    var gecod = new window.google.maps.Geocoder();
    gecod.geocode(
      {
        location: latLng,
        region: "SA",
      },
      (results, status) => {
        if (status === "OK") {
          let neighborhood = "",city = "";
          results[0].address_components.map((ad, i) => {
            if (ad.types.includes("locality")) city = ad.long_name;
            return ad.long_name;
          });
          results[0].address_components.map((ad, i) => {
            if (ad.types.includes("sublocality")) neighborhood = ad.long_name;
            return ad.long_name;
          });
          // results[0].address_components.map((ad, i) => {
          //   if (ad.types.includes("administrative_area_level_1")) city = ad.long_name;
          //   return ad.long_name;
          // });
          // Geocode.setApiKey("AIzaSyBHEdJYUhC4TUpnv2J1dnJ_2S7WBV2uIY0");
          // Geocode.setLanguage("ar");
          // Geocode.setRegion("SA");
          // Geocode.enableDebug();
          // Geocode.fromLatLng(lat, lng)
          // .then(
          //   (response) => {
          const address = results[0].formatted_address;
          NewUser.address = address;
          NewUser.neighborhood = neighborhood;
          NewUser.city = city;
          props.onChange(NewUser);
        }
      }
    );
    // },
    // (error) => {
    //   console.error(error);
    // }
    // );
  }

  return (
    <Map
      ref={(map) => {
        googleMap = map;
      }}
      google={window.google}
      zoom={props.zoomLevel || 12}
      scrollwheel={true}
      scaleControl={true}
      streetViewControl={false}
      onIdle={BoundsChanged}
      style={mapStyles}
      gestureHandling={"greedy"}
      disableDoubleClickZoom={true}
      fullscreenControl={false}
      minZoom={props.zoomLevel || 12}
      maxZoom={22}
      mapTypeControl={true}
      mapType={window.google.maps.MapTypeId.HYBRID}
      zoomControl={true}
      clickableIcons={false}
      initialCenter={{
        lat: props.lat,
        lng: props.lng,
      }}>
      {/* {state.markers.map((marker, index) => (
        <Marker
          key={index}
          position={marker.position}
          draggable={true}
          icon={{
            url: publicUrl + "assets/img/icons/GreenPin.svg",
          }}
          onDragend={(t, map, coord) => onMarkerDragEnd(coord, index, t)}
          name={marker.name}
        />
      ))} */}
    </Map>
  );
};

export default MapContainer;
