import { useState } from "react";
import Cookies from "universal-cookie";

export default function useLocation() {
  const cookies = new Cookies();
  const getLocation = () => {
    const locationString = cookies.get("location");
    // const userlocation = JSON.parse(locationString);
    return locationString;
  };

  const [location, setLocation] = useState(getLocation());

  const saveLocation = (userLocation) => {
    if (userLocation) {
      cookies.set("location", JSON.stringify(userLocation));
      setLocation(userLocation);
    } else {
      cookies.remove("location");
      setLocation(userLocation);
    }
  };

  return {
    setLocation: saveLocation,
    location,
  };
}
