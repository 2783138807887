import React, { useMemo, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Chip,
  Avatar,
  DialogActions,
  FormControl,
  TextField,
  IconButton
} from "@mui/material";
import { useAuth } from "../../../helpers/context";
import { CITIES, DISTRICTS, REGIONS } from "../../../constants";
import { Close, Send } from "@mui/icons-material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import PropertyCardForAdmin from "../../shared/properties/components/property-card-for-admin";
import { getBargain, saveBargainComment } from "../../../api/userApi";
import LoadingButton from "../../../components/global-components/loading-btn";
import { toast } from "react-toastify";
import CustomerDetails from "../customers/customer-details";
import { resolvePhonePrefix, trimPhone } from "../../../helpers";
import { PhoneIcon, WhatsAppIcon } from "../../../constants/icons";

const publicUrl = process.env.REACT_APP_URL + "/";

const DealDetails = ({ getData, openDealDialog, setOpenDealDialog, setDeal, deal, companyName }) => {
    const { t, i18n } = useTranslation();
    const { token } = useAuth();
    const [content, setContent] = useState('');
    const [isSubmittingData, setIsSubmittingData] = useState(false);
    const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
    const [client, setClient] = useState({});

    const regionsOptions = useMemo(
        () => {
            return REGIONS.map((region) => ({
                id: +region.REGION_ID,
                label: i18n.language === "ar" ? region.REGIONNAME_AR : region.REGIONNAME_EN,
            }));
        }, // eslint-disable-next-line
        [i18n.language]);
      
    const citiesOptions = useMemo(
        () => {
            return CITIES.map((city) => ({
                id: +city.CITY_ID,
                label: i18n.language === "ar" ? city.CITYNAME_AR: city.CITYNAME_EN,
                regionId: +city.REGION_ID,
            }))
        },// eslint-disable-next-line
        [i18n.language]);
    
    const districtsOptions = useMemo(
        () => {
            return DISTRICTS.map((district) => ({
                id: +district.DISTRICT_ID,
                label: i18n.language === "ar" ? district.DISTRICTNAME_AR:district.DISTRICTNAME_EN,
                regionId: +district.REGION_ID,
                cityId: +district.CITY_ID,
            }));
        }, // eslint-disable-next-line
        [i18n.language]);
    
    const resolveStatusName = (status) => {
        const badge = (statusName) => {
            return <Chip label={statusName} size="medium" style={{
                color: status === '0' ? 'gold' : status === '1' ? "blue" : status === '2' ? 'green' : status === '3' ? 'black' : '#d50000',
                borderColor: status === '0' ? 'gold' : status === '1' ? "blue" : status === '2' ? 'green' : status === '3' ? 'black' : '#d50000',
                background: '#fff',
                borderWidth: '1px',
                borderStyle: 'solid'
            }} />;
        };
        
        switch (status) {
            case '0': return badge(t("NewDeal"));
            case '1': return badge(t("Negotiations"));
            case '2': return badge(t("AdvancedNegotiations"));
            case '3': return badge(t("DealClosing"));
            case '4': return badge(t("DealLosing"));
            default: return '';
        }
    }
    
    return deal?.id && (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={deal?.propertyObject ? "xl" : "lg"}
                open={openDealDialog}
                className="custom-dialog model"
                onClose={() => { setOpenDealDialog(false); setDeal({}); }}>
                <DialogTitle>
                    <div className="dialog-head">
                        <div>
                            <h2>{t("Buyer")}: <span
                                style={{ textDecoration: 'underline', cursor: 'pointer', color: 'var(--main-color-one)' }}
                                onClick={() => {
                                    setClient(deal.buyerObject);
                                    setOpenCustomerDialog(true);
                                }}
                            >{deal.buyerObject?.name} </span>  &nbsp;
                                
                                <div style={{ display: "inline-flex", gap: '20px', alignItems: 'center', position: 'relative', top: '3px' }}>
                                    <IconButton
                                        edge='start'
                                        color='inherit'
                                        className="phone-btn"
                                        onClick={() => { window.open(`tel:${trimPhone(deal.buyerObject.phone)}`, '_blank') }}
                                        sx={{
                                            color: 'var(--main-color-one)',
                                            background: 'none',
                                            '&:hover': { color: '#fff', background: 'var(--main-color-one)' },
                                            borderRadius: '100%',
                                            border: '1px solid var(--main-color-one)',
                                            width: '27px',
                                            height: '27px',
                                            padding: '5px'
                                        }}>
                                        <PhoneIcon />
                                    </IconButton>
                                    <IconButton
                                        edge='start'
                                        color='inherit'
                                        className="whatsapp-btn"
                                        onClick={() => { window.open(`https://wa.me/${resolvePhonePrefix(deal.buyerObject.phone)}`, '_blank') }}
                                        sx={{
                                            color: '#25D366',
                                            background: 'none',
                                            '&:hover': { color: '#fff', background: '#25D366' },
                                            borderRadius: '100%',
                                            border: '1px solid #25D366',
                                            width: '30px',
                                            padding: '5px',
                                            height: '30px'
                                        }}>
                                        <WhatsAppIcon />
                                    </IconButton>
                                
                                    {resolveStatusName(deal.status)}
                                </div>
                                <br />
                                <span style={{ fontSize: '13px' }}>
                                    {deal.createdAt ? moment(new Date(deal.createdAt)).add(3, 'hours').fromNow() : <></>}
                                </span>
                            </h2>
                        </div>
                        <div className="dialog-actions">
                            <span onClick={() => { setOpenDealDialog(false); setDeal({}); }} style={{ cursor: 'pointer' }}>
                                <Close />
                            </span>
                        </div>
                    </div>
  
                </DialogTitle>
                <DialogContent>
                    <div className='card-body px-0'>
                        <div className="row">
                            <div className={`col-12 ${deal?.propertyObject ? "col-lg-8" : ""}`}>
                                {/* <Divider textAlign="left" className="col-11 mt-2 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
                                {t("BasicInformation")}
                            </Divider> */}
                                <div className='row mx-3 mb-2'>
                                    {[
                                        {
                                            label: t("Seller"),
                                            value: deal.sellerObject?.name || t("NotAvailable")
                                        },
                                        {
                                            label: t("DealValue"),
                                            value: Intl.NumberFormat("en").format(deal.value.endsWith('.000') ? parseInt(deal.value) : deal.value) + ` ${t('SAR')}`
                                        },
                                        {
                                            label: t("Commission"),
                                            value: Intl.NumberFormat("en").format(deal.commission.endsWith('.000') ? parseInt(deal.commission) : deal.commission) + ` ${t('SAR')}`
                                        },
                                        {
                                            label: t("Address"),
                                            value: (regionsOptions.filter((i) => +i.id === +deal?.region)?.[0]?.label || null) + ' - ' + (citiesOptions.filter((i) => +i.id === +deal?.city)?.[0]?.label || null) + ' - ' + (districtsOptions.filter((i) => deal?.neighbourhoods?.includes(+i.id))?.map((i, index) => i.label + (index === deal?.neighbourhoods?.length - 1 ? '' : ' ')) || null)
                                        }
                                    ]?.map((item) => item.label === t("Seller") && !deal.sellerObject?.name ? null : (
                                        <div className='col-12 col-md-6 col-lg-4 mb-4 mb-lg-3' key={item?.label}>
                                            <strong>{item?.label}</strong>
                                            <span className="d-block mt-2 mb-3"
                                                onClick={() => {
                                                    if (item.label === t("Seller")) {
                                                        setClient(deal.sellerObject);
                                                        setOpenCustomerDialog(true);
                                                    }
                                                }}>{item.label === t("Seller") ? <div style={{ display: "inline-flex", gap: '20px', alignItems: 'center', position: 'relative', top: '3px' }}>
                                                    <span style={{ textDecoration: 'underline', cursor: 'pointer', color: 'var(--main-color-one)' }}>
                                                        {item?.value}
                                                    </span>
                                                    <IconButton
                                                        edge='start'
                                                        color='inherit'
                                                        className="phone-btn"
                                                        onClick={() => { window.open(`tel:${trimPhone(deal.sellerObject.phone)}`, '_blank') }}
                                                        sx={{
                                                            color: 'var(--main-color-one)',
                                                            background: 'none',
                                                            '&:hover': { color: '#fff', background: 'var(--main-color-one)' },
                                                            borderRadius: '100%',
                                                            border: '1px solid var(--main-color-one)',
                                                            width: '27px',
                                                            height: '27px',
                                                            padding: '5px'
                                                        }}>
                                                        <PhoneIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        edge='start'
                                                        color='inherit'
                                                        className="whatsapp-btn"
                                                        onClick={() => { window.open(`https://wa.me/${resolvePhonePrefix(deal.sellerObject.phone)}`, '_blank') }}
                                                        sx={{
                                                            color: '#25D366',
                                                            background: 'none',
                                                            '&:hover': { color: '#fff', background: '#25D366' },
                                                            borderRadius: '100%',
                                                            border: '1px solid #25D366',
                                                            width: '30px',
                                                            padding: '5px',
                                                            height: '30px'
                                                        }}>
                                                        <WhatsAppIcon />
                                                    </IconButton>
                                                    
                                                </div> :
                                                    item?.value}</span>
                                        </div>
                                    ))}
                                </div>

                                <Divider textAlign="left" className="col-11 mt-2 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
                                    <div className="d-flex align-items-center" style={{ gap: '5px' }}>
                                        {t("Comments")}
                                    </div>
                                </Divider>
                           
                                <div className='row mx-0 mb-2 flex-column'>
                                    {deal?.comments?.length > 0 && <ul className="p-0">
                                        {deal.comments?.map(item => (
                                            <li key={item.id} style={{ marginBottom: '15px', listStyle: 'none', display: 'flex', gap: '10px' }}>
                                                <Avatar
                                                    alt={item.avatar}
                                                    sx={{ bgcolor: '#fff', width: '50px', height: '50px', objectFit: 'cover', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}
                                                    src={item.avatar || publicUrl + "assets/img/defimgs/1_account.svg"}
                                                />
                                                <div className="comment-wrapper">
                                                    <div className="d-flex justify-content-between w-100" style={{ gap: '7px' }}>
                                                        <h6 style={{ marginBottom: '2px' }}>{item?.createdBy === companyName ? t("MainAccount") : item?.createdBy}</h6>
                                                        <p style={{ fontSize: '12px', marginTop: 0 }}> {moment(new Date(item.createdAt)).add(3, 'hours').fromNow()}</p>
                                                    </div>
                                                    <p style={{ fontSize: '12px', margin: 0 }}> {item?.content}</p>
                                                </div>
                                            </li>
                                        ))}</ul>}
                                    <FormControl className="my-2 comment-box">
                                        <TextField
                                            placeholder={t("WriteYourComment")}
                                            sx={{ width: "100%" }}
                                            InputLabelProps={{ shrink: true }}
                                            value={content}
                                            size='small'
                                            multiline
                                            rows={2}
                                            InputProps={{
                                                endAdornment: (
                                                    <LoadingButton
                                                        style={{ justifyContent: "center", alignItems: 'center', borderRadius: '100% !important', width: '40px', height: '40px' }}
                                                        OKdisabled={content?.length === 0}
                                                        loading={isSubmittingData}
                                                        classes='primary-btn px-2 py-1'
                                                        handleClick={() => {
                                                            setIsSubmittingData(true);
                                                            saveBargainComment({
                                                                userId: token,
                                                                bargainId: deal.id,
                                                                content: content,
                                                                language: i18n.language === 'ar' ? '0' : '1'
                                                            }).then(res => {
                                                                if (res.resCode === 0) {
                                                                    setContent("")
                                                                    toast.success(t("CommentAddedSuccessfully"));
                                                                    getBargain({
                                                                        userId: token,
                                                                        language: i18n.language === 'ar' ? '0' : '1',
                                                                        bargainId: deal?.id
                                                                    }).then((res) => {
                                                                        if (res.resCode === 0) {
                                                                            setDeal(res.response);
                                                                        }
                                                                    });
                                                                    getData();
                                                                } else {
                                                                    toast.error(res.resStr);
                                                                }
                                                            }).finally(() => setIsSubmittingData(false));
                                                        }}
                                                        label={<Send sx={i18n.language === "ar" ? { transform: 'rotate(-180deg)', fontSize: '22px' } : { fontSize: '22px' }} />}
                                                    />
                                                ),
                                            }}
                                            onChange={(e) => setContent(e.currentTarget.value)}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        
                            {deal?.propertyObject && (<div className="col-12 col-lg-4">
                                <Divider textAlign="left" className="col-11 mt-2 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
                                    {t("DealRelatedRealEstateAD")}
                                </Divider>
                                <div
                                    className='col-12 col-md-6 col-lg-8 m-auto m-lg-0'
                                    style={{ cursor: "pointer" }}>
                                    <PropertyCardForAdmin item={deal?.propertyObject} hideActions />
                                </div>
                            </div>)}
                        </div>
                    </div>
               
                </DialogContent>
                <DialogActions sx={{ paddingInline: '25px', flexDirection: { xs: 'column', lg: 'row' }, alignItems: 'flex-start', justifyContent: 'flex-start', gap: '10px' }}>
                    <span style={{ fontSize: '13px' }}><b>{t("AddedBy")}:</b> {deal.added_by === companyName ? t("MainAccount") : deal.added_by}</span>
                    <span style={{ fontSize: '13px' }}><b>{t("UpdatedAt")}:</b> {deal.updatedAt ? moment(new Date(deal.updatedAt)).add(3, 'hours').fromNow() : t("NotAvailable")}</span>
                </DialogActions>
            </Dialog>
            <CustomerDetails
                openCustomerDialog={openCustomerDialog}
                setOpenCustomerDialog={setOpenCustomerDialog}
                setClient={setClient}
                companyName={companyName}
                client={client} />
        </>
      
    );
}

export default DealDetails;
