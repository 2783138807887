import React, { useEffect, useState } from "react";
import { Avatar, IconButton, Pagination, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { getHomeFeaturedForUser, publishMyListing } from "../../../../api/propertyApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getDemoSnakbar, openDeleteModal } from "../../../../helpers";
import NoData from '../../../../components/global-components/no-data';
import { useAuth } from "../../../../helpers/context";
import LoadingData from "../../../../components/global-components/loading-data";
import { DEMO_VENDORS } from "../../../../constants";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ActionsPopper from "../../../../components/global-components/actions-popper";
import { useTranslation } from "react-i18next";

const pageSize = 50;
const publicUrl = process.env.REACT_APP_URL + "/";

const Properties = () => {
    const { token, UserType, vendor } = useAuth();
    const [loadingData, setLoadingData] = useState(true);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
    const [timeStamp, setTimeStamp] = useState(new Date());
    const [property, setProperty] = useState();
    const [openPopper, setOpenPopper] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (token)
            getHomeFeaturedForUser({
                userId: token,
                page: page,
                status: "1",
                pageSize: pageSize,
                isAssigned: UserType === '4' ? "0" : "na",
                language: i18n.language === 'ar' ? '0' : '1',
            })
                .then((property) => {
                    if (property.resCode === 0) {
                        setData(property.response.propertyArray);
                        setTotalCount(property.response.totalCount);
                    } else {
                        toast.error(property.resStr);
                    }
                })
                .finally(() => setLoadingData(false));
        
    }, [token, timeStamp, UserType, page, i18n]);


    const handleDelete = (id) => {
        publishMyListing({
            propertyIds: id,
            userId: token,
            isPublish: "4",
        })
            .then(() => {
                toast.success(t("TheADHasBeenSuccessfullyDeleted"));
                setTimeStamp(new Date());
            });
    }

    
    const handleUnPublish = (id) => {
        publishMyListing({
            propertyIds: id,
            userId: token,
            isPublish: "0",
        })
            .then(() => {
                toast.success(t("TheADHasBeenSuccessfullyUnpublished"));
                setTimeStamp(new Date());
            });
    }
    
    return (
        <>
            {loadingData ? (
                <Skeleton
                    variant='rectangular'
                    height={450}
                    style={{ marginTop: '15px', borderRadius: '16px' }}
                    width={'100%'}
                />
            ) : (
                <div className='custom-card'>
                    <div className="card-header">
                        <h4>{t("ListOfRealEstateADs", { count: data?.length > 0 ? data?.length : null })}</h4>
                        <h6>{t("YouCanManageAllRealEstateADsPublishedByYou")}</h6>
                    </div>
                    <div className="card-body">
                        <div style={{ display: 'flex', height: data.length > 0 ? '420px' : '370px', width: '100%', flexDirection: 'column' }}>

                            <TableContainer className="table-container responsive-table"
                                component={Paper}>
                                <Table sx={{ minWidth: '100%' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="right">{t("CoverImage")}</TableCell>
                                            <TableCell align="right">{t("PropertyType")}</TableCell>
                                            <TableCell align="right">{t("ADPurpose")}</TableCell>
                                            <TableCell align="right">{t("Price")}</TableCell>
                                            <TableCell align="right">{t("Actions")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loadingData ? <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        ><TableCell colSpan={5}><LoadingData /></TableCell></TableRow> :
                                            data?.length > 0 ? data.map((row) => (
                                                <TableRow
                                                    key={row.propertyId}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& td, & th': { pt: 0.57, pb: 0.57 } }}
                                                >
                                                    <TableCell align="right" component="th" scope="row">
                                                        <Avatar
                                                            sx={{ width: 48, height: 48 }}
                                                            variant="square"
                                                            src={row.photos.split(',')[0] ? row.photos.split(',')[0] : `${publicUrl}assets/img/defimgs/${row.propertyType}.svg`} />
                                                    </TableCell>
                                                    <TableCell align="right">{row.propertyTypeName}</TableCell>
                                                    <TableCell align="right">{row.listedFor === '2' ? t("ForSale") : t("ForRent")}</TableCell>
                                                    <TableCell align="right">{Intl.NumberFormat("en").format(row.defaultPrice)}</TableCell>
                                                    <TableCell align="right">
                                                        <IconButton
                                                            onClick={(e) => {
                                                                setOpenPopper(!openPopper);
                                                                setAnchorEl(anchorEl ? null : e.target);
                                                                setProperty(row);
                                                            }}
                                                            sx={{
                                                                background: '#fff',
                                                                zIndex: 99,
                                                                color: 'var(--main-color-one)',
                                                                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                                                                '&:hover,&:focus': {
                                                                    color: '#fff',
                                                                    background: 'var(--main-color-one)'
                                                                }
                                                            }}>
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )) :
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                ><TableCell colSpan={5}><NoData msg={t("ThereAreNoADsPublishedByYou")} /></TableCell></TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <ActionsPopper
                                openPopper={openPopper}
                                anchorEl={anchorEl}
                                setAnchorEl={setAnchorEl}
                                setOpenPopper={setOpenPopper}
                                items={
                                    [
                                        {
                                            title: t("ADDetails"),
                                            action: () => history(`/admin/property-details/${property.idToShare}`)
                                        },
                                        {
                                            title: t("EditAD"),
                                            action: () => history(`/admin/property/edit-property/${property.propertyId}`)
                                        },
                                        {
                                            title: t("UnpublishAD"),
                                            action: () => {
                                                if (DEMO_VENDORS.includes(vendor)) {
                                                    setOpenDemoSnackBar(true);
                                                } else {
                                                    openDeleteModal(t("ConfirmUnpublishing"), t("AreYouSureYouWantToUnpublishThisAD"),
                                                        () => handleUnPublish(property.propertyId), t("Unpublish"))
                                                }
                                            }
                                        },
                                        {
                                            title: t("DeleteAD"),
                                            action: () => {
                                                if (DEMO_VENDORS.includes(vendor)) {
                                                    setOpenDemoSnackBar(true);
                                                } else {
                                                    openDeleteModal(t("ConfirmDeletion"), t("AreYouSureToDeleteThisAD"),
                                                        () => handleDelete(property.propertyId));
                                                }
                                            }
                                        }
                                    ]
                                }
                            />
                            {totalCount > pageSize && (
                                <Pagination
                                    sx={{ '& .MuiPagination-ul': { flexDirection: 'row', marginTop: '25px' } }}
                                    count={parseInt(Math.ceil(totalCount / pageSize))}
                                    page={page}
                                    onChange={(event, value) => {
                                        setPage(+value);
                                    }}
                                    color="primary" />
                            )}
                            {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}

                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Properties;
