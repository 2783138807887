import React, { useEffect, useRef, useState } from "react";
import * as userApi from "../api/userApi";
import { useNavigate } from "react-router-dom";
import UserForm from "./section-components/registration/UserForm";
import { toast } from "react-toastify";
import AppLayout from "./layouts/app-layout";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Business, User } from "../constants/icons";
import { useAuth } from "../helpers/context";
import { preventString, resolvePersianAndArabicNumbers } from "../helpers";
import { useTranslation } from "react-i18next";
// import ReCAPTCHA from "react-google-recaptcha";

const Registration = (props) => {
  const { token, setAccountType, setUserType } = useAuth();
  const [errors, setErrors] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [Captcha, setCaptcha] = useState({
    captcha: false,
    "g-recaptcha-response": 0,
  });
  const recaptchaRef = useRef(null);
  const { t, i18n } = useTranslation();
  const history = useNavigate();
  const [user, setUser] = useState({
    accountType: "",
    name: "",
    userType: "",
    countryCode: "+966",
    phone: "",
    language: i18n.language === 'ar' ? '0' : '1',
  });

  function handleChange({ target }) {
    let value = target.value;
    if (target.name === "phone") value = preventString(value);
    else {
      value = resolvePersianAndArabicNumbers(value);
    }
    setUser({
      ...user,
      [target.name]: value,
    });
  }

  // function handleCaptcha(key) {
  //   console.log(key)
  // setCaptcha({
  //   captcha: true,
  //   "g-recaptcha-response": key,
  // });
  // }

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      formIsValid();
    }
  }, // eslint-disable-next-line
    [i18n.language]);

  function formIsValid() {
    const _errors = {};
    if (!user.name) _errors.name = t("EnterName");
    if (!user.countryCode) _errors.countryCode = t("ChooseCountryCode");
    if (!user.phone) _errors.phone = t("EnterPhoneNumber");

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (!formIsValid()) return;
    const captchaToken = await recaptchaRef.current.executeAsync();
    setCaptcha({ captcha: true, "g-recaptcha-response": captchaToken, });
    setLoadingButton(true);

    userApi
      .saveUser({
        ...user,
        phone: user.phone,
        "gRecaptchaResponse": captchaToken || Captcha["g-recaptcha-response"],
      })
      .then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          setAccountType(_userInfo?.response?.user?.accountType);
          setUserType(_userInfo?.response?.user?.userType);
          history("/otp/" + user.countryCode + "/" + user.phone);
          if (_userInfo.resStr !== "success") toast.success(_userInfo.resStr);
        } else {
          const _errors = {};
          _errors.phone = _userInfo.resStr;
          setErrors(_errors);
          setLoadingButton(false);
          recaptchaRef.current?.reset();

        }
      })
      .catch((e) => {
        setLoadingButton(false);
        recaptchaRef.current?.reset();
      });
  }

  return (
    <AppLayout pageTitle={t("Register")}>
      {token ? (
        <div className='page-width pd-top-90 mg-bottom-100 text-center'>
          <h3>{t("YouAreAlreadyRegisteredWithUs")}</h3>
          <h4>{t("LogOutIfYouWantToCreateNewAccount")}</h4>
        </div>
      ) : (
        <div className='page-width pd-top-100 mg-bottom-100 text-center registeration'>
          <Box
              sx={{
                marginBottom: "40px",
                textAlign: "center !important",
                fontSize: "28px",
                fontWeight: 600,
                color: "#000",
              }}>
            {t("RegisterNewAccount")}
            <Box
              sx={{
                textAlign: "center !important",
                fontSize: "16px",
                fontWeight: 400,
                color: "#000",
              }}>
              {t("SelectAccountType")}
            </Box>
          </Box>
          <div className='pd-bottom-90'>
            <div className='container'>
              <FormControl className='col-12 col-md-11 p-0 mb-3'>
                <RadioGroup
                  name='contract-type'
                  row
                  style={{ justifyContent: "space-evenly" }}
                  value={user.accountType}>
                  <div
                    className={`custom-radio col-12 col-md-5 mb-3 ${user.accountType === "1" ? "active" : ""}`}
                    onClick={(e) =>
                      setUser({
                        ...user,
                        accountType: "1",
                        userType: "3",
                      })
                    }>
                    <div
                      className='d-flex align-items-center'
                      style={{ gap: "10px" }}>
                      <User />
                      <span>{t("IndividualsAccount")}</span>
                    </div>
                    <div>
                      <FormControlLabel value='1' control={<Radio />} />
                    </div>
                  </div>
                  <div
                    className={`custom-radio col-12 col-md-5 mb-3 ${user.accountType === "2" ? "active" : ""}`}
                    onClick={(e) =>
                      setUser({
                        ...user,
                        accountType: "2",
                        userType: "4",
                      })
                    }>
                    <div
                      className='d-flex align-items-center'
                      style={{ gap: "10px" }}>
                      <Business />
                      <span>{t("BusinessAccount")}</span>
                    </div>
                    <div>
                      <FormControlLabel value='2' control={<Radio />} />
                    </div>
                  </div>
                </RadioGroup>
              </FormControl>
              <Divider variant='fullWidth' className='col-12 my-4 p-0' />
              <p style={{ fontSize: "16px", margin: user.accountType === "2" ? "45px 0 20px":"20px 0" }} className="text-center">
                {user.accountType === "2" ? t("RequiresVerificationViaNationalAccess") : <>&nbsp;</>}
              </p>

              {user.userType && (
                <>
                  <UserForm
                    errors={errors}
                    user={user}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                    recaptchaRef={recaptchaRef}
                    // handleCaptcha={handleCaptcha}
                    name={user.accountType === "2" ? t("CompanyName") : t("Name")}
                    loading={loadingButton}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </AppLayout>
  );
}

export default Registration;
