import React, { useEffect }  from 'react'
import { countVisit } from '../../api/userApi';
import { getCookie, saveCookie } from '../../helpers';
import { useAuth } from '../../helpers/context';
import { useTranslation } from 'react-i18next';
  
const NewVisitingDetection = () => {

    const { vendor } = useAuth();
    const { i18n } = useTranslation();

    useEffect(() => {
        if (vendor)
            checkNewVisiting();
    },
        // eslint-disable-next-line
        [vendor]);
    
    const checkNewVisiting = () => {
        let currentSession = getCookie("visiting-session");
        if (!currentSession) {
            saveCookie("visiting-session", true, 60 * 60);
            countVisit({ vendorName: vendor, language: i18n.language === 'ar' ? '0' : '1' }).then(res => { }).catch(() => { });
        }
    };

    return <></>;
}

export default NewVisitingDetection;

