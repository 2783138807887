import React, { useState } from "react";
import { useAuth } from "../../../../helpers/context";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { UploadFile } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import UploadLogo from "../../../../components/uploadLogo";
import { useTranslation } from "react-i18next";

const LogoSection = () => {
 
  const { logo, setLogo, token } = useAuth();
  const [openLogoModal, setOpenLogoModal] = useState(false);
  const { t } = useTranslation();

  return (
    <div className='custom-card logo'>
      <div className="card-header">
        <h4>{t("Logo")}</h4>
        <h6>{t("YouCanControlTheLogoForYourWebsite")}</h6>
      </div>
      <div className="card-body flex-column flex-sm-row">
        <img
          src={logo}
          style={{ maxWidth: "130px" }}
          alt='logo'
        />
        <button className="primary-btn justify-content-between" onClick={() => setOpenLogoModal(true)}>
          <UploadFile />
          <span>{t("ChangeLogo")}</span>
        </button>
        <Dialog
          onClose={() => setOpenLogoModal(false)}
          maxWidth="lg"
          className="custom-dialog scrollable-img"
          open={openLogoModal}>
          <DialogTitle>
            <div className="dialog-head">
              <div>
                <h2>{t("ChangeLogo")}</h2>
              </div>
              <div className="dialog-actions">
                <IconButton
                  aria-label='close'
                  onClick={() => setOpenLogoModal(false)}
                  sx={{ color: (theme) => theme.palette.grey[500] }}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            <UploadLogo token={token} LogoTOView={logo} setLogoTOView={setLogo} />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

export default LogoSection;
