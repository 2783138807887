import React, { useEffect, useRef, useState } from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Link } from "react-router-dom";
import photo1 from "../../../assets/media/shared-photos/01.jpg";
import photo2 from "../../../assets/media/shared-photos/02.jpg";
import photo3 from "../../../assets/media/shared-photos/03.jpg";
import { ReactComponent as InfoSvg } from "../../../assets/media/icons/infosvg.svg";
import { ReactComponent as BlockSvg } from "../../../assets/media/icons/block.svg";
import { ReactComponent as GallarySvg } from "../../../assets/media/icons/gallary.svg";
import { ReactComponent as LocationSvg } from "../../../assets/media/icons/location.svg";
import { ReactComponent as AddContactSvg } from "../../../assets/media/icons/addcontact.svg";
import { ReactComponent as HeartSvg } from "../../../assets/media/icons/heart.svg";
import { ReactComponent as CloseSvg } from "../../../assets/media/icons/close.svg";
import { ReactComponent as DocumentsSvg } from "../../../assets/media/icons/documents.svg";
import { ReactComponent as ProfileDocumentsSvg } from "../../../assets/media/icons/profiledocuments.svg";

import {
  getNewMessages,
  getState,
  getUserProile,
  saveLastMessage,
} from "../../../api/userApi";
import {
  getPropertiesOfOwnerNew,
  getPropertyDescription,
} from "../../../api/propertyApi";
import Picker, { SKIN_TONE_MEDIUM_LIGHT } from "emoji-picker-react";
import { ThemeColor } from "../../../Config/Config";
import md5 from "md5";
import { ArrowBack } from "@mui/icons-material";
import { Avatar, Box, ClickAwayListener, IconButton, Popper, useMediaQuery } from "@mui/material";
import { useAuth } from "../../../helpers/context";
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { DEMO_VENDORS } from "../../../constants";
import { getDemoSnakbar } from "../../../helpers";
import { useTranslation } from "react-i18next";

const UserChat = (props) => {
  const isAdmin = window.location.href.includes('admin');
  const ENDPOINT = process.env.REACT_APP_CHAT_URL;
  const { token, UserId, vendor } = useAuth();
  const [UserName, setUserName] = useState('');
  const [Chats, setChats] = useState([]);
  const [Status, setStatus] = useState("");
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const [Receiver, setReceiver] = useState({
    name: "",
    avatar: "",
    companyIsVerified: "0",
  });
  const [Options, setOptions] = useState({
    showProfileDetail: false,
    chatSearchBox: false,
    showUserInformation: true,
    showLastMedia: true,
    showDocuments: true,
    emojiWidth: window.innerWidth / 16 + "rem",
    themeColor: Boolean(localStorage.getItem("theme"))
      ? parseInt(localStorage.getItem("theme"))
      : ThemeColor.Light,
    message: "",
  });
  const [Property, setProperty] = useState(undefined);
  const socketRef = useRef();
  let timer = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const openEmojiSelector = Boolean(anchorEl);
  const emojiSelectorID = openEmojiSelector ? 'emoji-popper' : undefined;
  const { t, i18n } = useTranslation();

  const handleClickEmojiSelector = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  let chatRef = [];
  const channelID = props.id;

  const channelIDArr = channelID.split("-");
  const ReceiverId =
    channelIDArr[0] === UserId ? channelIDArr[2] : channelIDArr[0];

  const PropertyId = channelIDArr[1];

  useEffect(() => {
    if (socketRef.current)
      if (socketRef.current.connected) socketRef.current.disconnect();

    getNewMessages({ channelId: channelID, userId: UserId, language: i18n.language==="ar"?'0':'1' })
      .then((chat) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        chatRef = chat.response.messages;
        setChats(chat.response.messages);

        getState({ userId: ReceiverId }).then((_userInfo) => {
          setStatus(_userInfo.response.lastSeen ? t("LastSeen") + " " + getDateName2(_userInfo.response.lastSeen):'');
        });

        socketRef.current = window.io(ENDPOINT);
        socketRef.current.on("chat message", (msg) => {
          if (chatRef.length === 0) chatRef = Chats;
          switch (msg.text) {
            case "":
              if (msg.receiverUid === UserId) {
                let chatArr = [];
                chatRef.forEach((element) => {
                  element.seen = "2";
                  chatArr.push(element);
                });
                setChats((Chats) => chatArr);
                chatRef = chatArr;
                setStatus(t("Online"));
              }
              break;
            case "D@]44vaJ@FM2xYG(Fu7v8fNt8raG]wm.":
              if (msg.receiverUid === UserId) setStatus(t("Typing"));
              break;
            case "4sVpfb393M>]tWR@LiqEZ,8[3UTZcht)":
              if (msg.receiverUid === UserId) setStatus(t("Online"));
              break;
            default:
              const index = chatRef.filter(function (item) {
                return item.mId === msg.mId;
              });
              if (index.length > 0) {
                if (msg.chatID === UserId) {
                  let chatArr = [];
                  chatRef.forEach((element) => {
                    element.seen = "2";
                    chatArr.push(element);
                  });
                  setChats((Chats) => chatArr);
                  chatRef = chatArr;
                }
                setStatus(t("Online"));
              } else {
                setChats((Chats) => [...Chats, msg]);
                chatRef.push(msg);
              }
              break;
          }
        });
        socketRef.current.emit("connectUser", {
          isOnline: "1",
          userId: UserId,
        });
        emitMsg("");
        return () => {
          socketRef.current.disconnect();
        };
      })
      .catch((error) => {
        throw error;
      });
  }, [UserId, channelID, UserId, i18n.language]);

  window.addEventListener("beforeunload", (ev) => {
    // ev.preventDefault();
    socketRef.current.emit("connectUser", { isOnline: "0", userId: UserId });
  });

  useEffect(() => {
    getPropertiesOfOwnerNew({
      ownerId: ReceiverId,
      page: "1",
      sortBy: "updatedAt",
      sortOrder: "desc",
      pageSize: "10",
      userId: token,
      language: i18n.language === 'ar' ? '0' : '1',
    }).then((_userInfo) => {
      if (_userInfo.resCode === 0) setReceiver(_userInfo.response.hostInfo);
    });
  }, [ReceiverId, token, i18n.language]);

  useEffect(() => {
    getPropertyDescription({
      propertyId: PropertyId,
      language: i18n.language === 'ar' ? '0' : '1',
    }).then((property) => {
      if (property.resCode === 0) setProperty(property.response);
      else setProperty(undefined);
    });
  }, [PropertyId, i18n.language]);

  useEffect(() => {
    getUserProile({
      userId: token,
      language: i18n.language === 'ar' ? '0' : '1',
    }).then((_userInfo) => {
      if (_userInfo.resCode === 0) {
        setUserName(_userInfo.response.userProfile.name);
      }
    });
  }, [token, i18n]);

  function emitMsg(msg) {
    let unixTime = (+new Date()).toString();
    const mid = unixTime + channelID;
    let Mid = md5(mid);
    socketRef.current.emit("chat message", {
      receiverUid: ReceiverId,
      text: msg,
      userName: UserName,
      receiver: Receiver.name,
      chatID: UserId,
      channelId: channelID,
      updatedDate: unixTime,
      mId: Mid,
      seen: "0",
      inbox: "1",
    });
  }

  let messagesEndRef = React.createRef();

  window.onresize = () => {
    let width = window.innerWidth / 16;
    if (width >= 50) width = 50;
    setOptions({ emojiWidth: width + "rem" });
  };

  useEffect(() => {
    messagesEndRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }, [messagesEndRef, Chats]);

  function SendMessage() {
    // socket.emit("chat message", { abcd: "asd" });

    saveLastMessage({
      userId1: token,
      userId2: ReceiverId,
      propertyId: channelIDArr[1],
      lastMessage: Options.message,
    }).then((_response) => { });
    emitMsg(Options.message);
    setOptions({ message: "" });
  }

  function timeConverter(unix_timestamp) {
    if (unix_timestamp)
      return new Intl.DateTimeFormat(i18n.language === "ar" ? "ar-SA" : 'en', {
        hour: "2-digit",
        minute: "2-digit",
      }).format(new Date(parseFloat(unix_timestamp)));
  }

  function getDateName(unix_timestamp) {
    if (unix_timestamp) {
      let date = new Date(parseFloat(unix_timestamp));
      let todaysDate = new Date();
      let Yesterday = new Date(new Date().setDate(todaysDate.getDate() - 1));
      if (date.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0))
        return t("Today");
      else if (date.setHours(0, 0, 0, 0) === Yesterday.setHours(0, 0, 0, 0))
        return t("Yesterday");
      else
        return new Intl.DateTimeFormat(i18n.language === "ar" ? "ar-SY" : 'en', {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(parseFloat(unix_timestamp)));
    }
  }

  function getDateName2(unix_timestamp) {
    if (unix_timestamp) {
      let date = new Date(parseFloat(unix_timestamp));
      let todaysDate = new Date();
      let Yesterday = new Date(new Date().setDate(todaysDate.getDate() - 1));
      if (date.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0))
        return new Intl.DateTimeFormat(i18n.language === "ar" ? "ar-SY" : 'en', {
          hour: "numeric",
          minute: "numeric",
        }).format(new Date(parseFloat(unix_timestamp)));
      else if (date.setHours(0, 0, 0, 0) === Yesterday.setHours(0, 0, 0, 0))
        return t("Yesterday");
      else
        return new Intl.DateTimeFormat(i18n.language === "ar" ? "ar-SY" : 'en', {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(parseFloat(unix_timestamp)));
    }
  }

  function isSameDate(unix_timestamp1, unix_timestamp2) {
    let date1 = new Date(parseFloat(unix_timestamp1));
    let date2 = new Date(parseFloat(unix_timestamp2));
    if (date1.setHours(0, 0, 0, 0) === date2.setHours(0, 0, 0, 0)) return true;
    else return false;
  }

  const toggleShowProfileDetail = () => {
    setOptions({ showProfileDetail: !Options.showProfileDetail });
    document.body.click();
  };

  // const toggleChatSearchBox = () => {
  //   setOptions({ chatSearchBox: !Options.chatSearchBox });
  //   document.body.click();
  // };

  const toggleShowUserInformation = () => {
    setOptions({ showUserInformation: !Options.showUserInformation });
  };

  const toggleShowLastMedia = () => {
    setOptions({ showLastMedia: !Options.showLastMedia });
  };

  const toggleShowDocuments = () => {
    setOptions({ showDocuments: !Options.showDocuments });
  };

  const handleMessageChange = (event) => {
    setOptions({ message: event.target.value });

    emitMsg("D@]44vaJ@FM2xYG(Fu7v8fNt8raG]wm.");
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(function () {
      emitMsg("4sVpfb393M>]tWR@LiqEZ,8[3UTZcht)");
    }, 2000);
  };

  const handleEmojiSelect = (event, emojiObject) => {
    setOptions({
      message: Options.message
        ? Options.message + emojiObject.emoji
        : emojiObject.emoji,
    });
  };
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <main className={`main main-visible ${isAdmin ? "admin radius-16 border shadow" : ""}`}>
      <div className='chats' style={{position:'relative',top:'70px'}}>
        <div className='chat-body'>
          <div className='chat-header'>
            {!isLargeScreen && (
              <Link className='contacts-link' to={isAdmin ? '/admin/chat' : '/Chats'}>
                <button
                  className='btn btn-secondary btn-icon btn-minimal btn-sm text-muted d-xl-none ml-3'
                  type='button'>
                  <ArrowBack style={i18n.language ==="ar" ?{ transform: 'rotate(180deg)' }:{}} />
                </button>
              </Link>
            )}
           
            {Receiver.userType !== '6' ? (
              <Link
                to={{
                  pathname:
                    Receiver.accountType === "1"
                      ? "/user-profile/"
                      : "/company-profile/",
                }}
                state={{ userId: Receiver.id }}
              >
                <div className='media chat-name d-flex align-items-center justify-content-between' style={{ gap: '25px' }}>
                  <div
                    className='d-sm-inline-block'
                  >
                    <Avatar
                      src={Receiver.avatar}
                      sx={{
                        width: 56,
                        height: 56,
                        bgcolor: Receiver?.avatar ? '#fff' : 'var(--main-color-one)'
                      }}
                      alt='user img'>
                      {Receiver.name && Receiver.name.length > 4 ? Receiver.name.substring(0, 4) : Receiver.name}
                    </Avatar>
                  </div>
                  <div className='media-body align-self-center'>
                    <h6 className='mb-0' style={{ fontWeight: 500 }}>{Receiver.name}</h6>
                    <small className='text-muted'>{Status}</small>
                  </div>
                </div>
              </Link>
            ) : (
              <div className='media chat-name d-flex align-items-center justify-content-between' style={{ gap: '25px' }}>
                <div
                  className='d-sm-inline-block'
                >
                    <Avatar src={Receiver.avatar}
                      sx={{
                        width: 56,
                        height: 56,
                        bgcolor: Receiver?.avatar ? '#fff' : 'var(--main-color-one)'
                      }}
                      alt='user img'>{Receiver.name && Receiver.name.length > 4 ? Receiver.name.substring(0, 4) : Receiver.name}</Avatar>
                </div>
                <div className='media-body align-self-center'>
                  <h6 className='mb-0' style={{ fontWeight: 500 }}>{Receiver.name}</h6>
                  <small className='text-muted'>{Status}</small>
                </div>
              </div>
            )}
           

            {/* <ul className='nav flex-nowrap'>
              {/* <li className="nav-item list-inline-item d-none d-sm-block mr-1">
                  <Link
                    className="nav-link text-muted px-1"
                    onClick={toggleChatSearchBox}
                    to="#"
                  >
                    <SearchSvg />
                  </Link>
                </li>
                <li className="nav-item list-inline-item d-none d-sm-block mr-1">
                  <Link
                    className="nav-link text-muted px-1"
                    to="#"
                    title="Call Now"
                  >
                    <CallNowSvg />
                  </Link>
                </li> */}
            {/*    <li className="nav-item list-inline-item d-none d-sm-block mr-0">
                <Dropdown>
                  <Dropdown.Toggle
                    className="text-muted hw-20 mt-2"
                    as={VerticalOptionDots}
                  ></Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                    <Link
                        className="dropdown-item align-items-center d-flex"
                        onClick={toggleChatSearchBox}
                        to="#"
                      >
                        <SearchSvg className="hw-20 mr-2" />
                        <span>Search</span>
                      </Link> 








                    <Link
                      className="dropdown-item align-items-center d-flex"
                      to="#"
                      onClick={toggleShowProfileDetail}
                    >
                      <InfoSvg className="hw-20 mr-2" />
                      <span>View Info</span>
                    </Link>



              {/* <Link
                        className="dropdown-item align-items-center d-flex"
                        to="#"
                      >
                        <MuteNotificationsSvg className="hw-20 mr-2" />
                        <span>Mute Notifications</span>
                      </Link>
                      <Link
                        className="dropdown-item align-items-center d-flex"
                        to="#"
                      >
                        <WallpaperSvg className="hw-20 mr-2" />
                        <span>Wallpaper</span>
                      </Link>
                      <Link
                        className="dropdown-item align-items-center d-flex"
                        to="#"
                      >
                        <ArchiveSvg className="hw-20 mr-2" />
                        <span>Archive</span>
                      </Link>
                      <Link
                        className="dropdown-item align-items-center d-flex"
                        to="#"
                      >
                        <DeleteSvg className="hw-20 mr-2" />
                        <span>Delete</span>
                      </Link>
                      <Link
                        className="dropdown-item align-items-center d-flex text-danger"
                        to="#"
                      >
                        <BlockSvg className="hw-20 mr-2" />
                        <span>Block</span>
                      </Link> 
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              {/*     <li className="nav-item list-inline-item d-sm-none mr-0">
                <Dropdown>
                  <Dropdown.Toggle
                    className="text-muted hw-20"
                    as={VerticalOptionDots}
                  ></Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                   <Link
                        className="dropdown-item align-items-center d-flex"
                        to="#"
                      >
                        <CallNowSvg className="hw-20 mr-2" />
                        <span>Call</span>
                      </Link>
                      <Link
                        className="dropdown-item align-items-center d-flex"
                        onClick={toggleChatSearchBox}
                        to="#"
                      >
                        <SearchSvg className="hw-20 mr-2" />
                        <span>Search</span>
                      </Link> 




                      
                    <Link
                      className="dropdown-item align-items-center d-flex"
                      to="#"
                      onClick={toggleShowProfileDetail}
                    >
                      <InfoSvg className="hw-20 mr-2" />
                      <span>View Info</span>
                    </Link>



                    {/* <Link
                        className="dropdown-item align-items-center d-flex"
                        to="#"
                      >
                        <MuteNotificationsSvg className="hw-20 mr-2" />
                        <span>Mute Notifications</span>
                      </Link>
                      <Link
                        className="dropdown-item align-items-center d-flex"
                        to="#"
                      >
                        <WallpaperSvg className="hw-20 mr-2" />
                        <span>Wallpaper</span>
                      </Link>
                      <Link
                        className="dropdown-item align-items-center d-flex"
                        to="#"
                      >
                        <ArchiveSvg className="hw-20 mr-2" />
                        <span>Archive</span>
                      </Link>
                      <Link
                        className="dropdown-item align-items-center d-flex"
                        to="#"
                      >
                        <DeleteSvg className="hw-20 mr-2" />
                        <span>Delete</span>
                      </Link>
                      <Link
                        className="dropdown-item align-items-center d-flex text-danger"
                        to="#"
                      >
                        <BlockSvg className="hw-20 mr-2" />
                        <span>Block</span>
                      </Link>
                  </Dropdown.Menu>
                </Dropdown>
              </li> 
            </ul> */}
          </div>
          {/* <div
              className={
                "border-bottom px-3 collapse " +
                (Options.chatSearchBox ? "show" : "")
              }
            >
              <div className="container-xl py-2 px-0 px-md-3">
                <div className="input-group bg-light ">
                  <input
                    type="text"
                    className="form-control form-control-md border-right-0 transparent-bg pr-0"
                    placeholder="Search..."
                  ></input>
                  <div className="input-group-append">
                    <span className="input-group-text transparent-bg border-left-0">
                      <SearchSvg className="hw-20 text-muted" />
                    </span>
                  </div>
                </div>
              </div>
            </div> */}
          {Property !== undefined && (

            <div className='media chat-name text-truncate Rectangle1427'>
              {vendor !== 'amakkn' ? <a target="_blank" rel="noreferrer" href={`https://amakkn${window.location.origin.includes('amakkncompany.com') || process.env.REACT_APP_API_URL?.includes('devsa2') ? '.org' : '.com'}/property-details/${Property.propertyId}`}>
              <div className='media chat-name text-truncate'>
                  <div className='d-sm-inline-block mr-3 ml-3'>
                    <Avatar
                      src={Property.photosNew ? Property.photosNew.split(",")[0] :
                        process.env.REACT_APP_URL +
                        "/assets/img/defimgs/" +
                        Property.propertyType +
                        ".svg"
                      }
                      variant="rounded"
                      sx={{ width: 96, height: 96 }}
                      alt='property img' />
                  </div>
                  <div className='media-body align-self-center '>
                    <h6 className='chat-name' style={{ fontWeight: 600, fontSize: '20px' }}>
                      {Property.propertyTypeName +
                        " " +
                        (Property.listedFor === "2" ? t("ForSale") : t("ForRent"))}
                    </h6>
                    <h6 className='chat-time' style={{ fontWeight: 400, fontSize: '18px' }}>
                      {Property.listedFor === "2"
                        ? Intl.NumberFormat("en").format(
                          Property.defaultPrice
                        ) + ` ${t("SAR")} `
                        : Intl.NumberFormat("en").format(
                          Property.defaultPrice
                        ) +
                        ` ${t("SAR")} / ` +
                        Property.defaultPriceType.name}
                    </h6>
                    <h6 className='chat-time mt-3' style={{ fontWeight: 400, fontSize: '14px', color: '#565768' }}>
                      {Property.address?.length > 46 ? Property.address.substring(0, 45) : Property.address}
                    </h6>
                  </div>
                </div>
              </a>:
              <Link
                className=''
                to={"/property-details/" + Property.propertyId}>
                <div className='media chat-name text-truncate'>
                  <div className='d-sm-inline-block mr-3 ml-3'>
                    <Avatar
                      src={Property.photosNew ? Property.photosNew.split(",")[0] :
                        process.env.REACT_APP_URL +
                        "/assets/img/defimgs/" +
                        Property.propertyType +
                        ".svg"
                      }
                      variant="rounded"
                      sx={{ width: 96, height: 96 }}
                      alt='property img' />
                  </div>
                  <div className='media-body align-self-center '>
                    <h6 className='chat-name' style={{ fontWeight: 600, fontSize: '20px' }}>
                      {Property.propertyTypeName +
                        " " +
                        (Property.listedFor === "2" ? t("ForSale") : t("ForRent"))}
                    </h6>
                    <h6 className='chat-time' style={{ fontWeight: 400, fontSize: '18px' }}>
                      {Property.listedFor === "2"
                        ? Intl.NumberFormat("en").format(
                          Property.defaultPrice
                        ) + ` ${t("SAR")} `
                        : Intl.NumberFormat("en").format(
                          Property.defaultPrice
                        ) +
                        ` ${t("SAR")} / ` +
                        Property.defaultPriceType.name}
                    </h6>
                    <h6 className='chat-time mt-3' style={{ fontWeight: 400, fontSize: '14px', color: '#565768' }}>
                      {Property.address?.length > 46 ? Property.address.substring(0, 45) : Property.address}
                    </h6>
                  </div>
                </div>
              </Link>}
            </div>
          )}

          <div className='chat-content p-2'>
            <div className='mx-2 mt-2'>
              {Chats.length > 0 ? (
                Chats.map((element, idx) => (
                  <div key={idx}>
                    {idx === 0 ? (
                      <div
                        className='message-divider pb-2'
                        data-label={getDateName(element.updatedDate)}></div>
                    ) : isSameDate(
                      Chats[idx].updatedDate,
                      Chats[idx - 1].updatedDate
                    ) ? (
                      <></>
                    ) : (
                      <div
                        className='message-divider pb-2'
                        data-label={getDateName(element.updatedDate)}></div>
                    )}

                    <div
                      className={`message ${element.chatID === UserId ? "self" : ""}`}>
                      <div className='message-wrapper'>
                        <div className='message-content'>
                          <span>{element.text}</span>
                        </div>
                      </div>
                      <div className='message-options'>
                        {element.chatID === UserId ? (
                          <span className='message-status'>
                            {element.seen === "2" ? "\u2713\u2713" : "\u2713"}
                          </span>
                        ) : (
                          <></>
                        )}
                        {idx === Chats.length - 1 ? (
                          <span className='message-date' ref={messagesEndRef}>
                            {timeConverter(element.updatedDate)}
                          </span>
                        ) : (
                          <span className='message-date'>
                            {timeConverter(element.updatedDate)}
                          </span>
                        )}
                        {/* <MessageDropdown /> */}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  className='message-divider pb-2'
                  data-label=''
                  ref={messagesEndRef}></div>
              )}
            </div>

            <div className='chat-finished' id='chat-finished'></div>
          </div>

          <div className={`chat-footer ${isAdmin ? 'admin' : ''}`}>
            <div className='form-row align-items-center'>
              <div className='col'>
                <div className='input-group'>
                  {/* <div
                    className='input-group-prepend mr-sm-2 mr-1'
                    style={{ display: "none" }}>
                    <Dropdown>
                      <Dropdown.Toggle
                        className='text-muted hw-20'
                        as={ChatPlusSvg}></Dropdown.Toggle>
                      <Dropdown.Menu className='dropdown-menu'>
                        <Link className='dropdown-item' to='#'>
                          <GallarySvg className='hw-20 mr-2' />
                          <span>Gallery</span>
                        </Link>
                        <Link className='dropdown-item' to='#'>
                          <AudioSvg />
                          <span>Audio</span>
                        </Link>
                        <Link className='dropdown-item' to='#'>
                          <ChatDocFileSvg className='hw-20 mr-2' />
                          <span>Document</span>
                        </Link>
                        <Link className='dropdown-item' to='#'>
                          <ContactSvg />
                          <span>Contact</span>
                        </Link>
                        <Link className='dropdown-item' to='#'>
                          <LocationSvg />
                          <span>Location</span>
                        </Link>
                        <Link className='dropdown-item' to='#'>
                          <PollSvg />
                          <span>Poll</span>
                        </Link>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> */}
                  <input
                    type='text'
                    className='form-control transparent-bg border-0 no-resize hide-scrollbar'
                    placeholder={t("WriteYourMessage")}
                    rows='1'
                    onKeyDown={(e) => {
                      if (e.code === "Enter" || e.code === "NumpadEnter") {
                        handleMessageChange(e);
                        setTimeout(() => {
                          if (DEMO_VENDORS.includes(vendor)) {
                            setOpenDemoSnackBar(true);
                          } else {
                            SendMessage()
                          }
                        }, 400);
                      }
                    }}
                    value={Options.message}
                    onChange={handleMessageChange}></input>

                  <div className='input-group-prepend mr-sm-2 mr-1'>
                    <IconButton onClick={handleClickEmojiSelector}>
                      <InsertEmoticonIcon sx={{ fontSize: '32px' }} />
                    </IconButton>
                    <Popper id={emojiSelectorID} open={openEmojiSelector} anchorEl={anchorEl}>
                      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                        <Box>
                          <Picker
                            onEmojiClick={handleEmojiSelect}
                            skinTone={SKIN_TONE_MEDIUM_LIGHT}
                          />
                        </Box>
                      </ClickAwayListener>
                    </Popper>
                  </div>
                </div>
              </div>
              <div className='col-auto'>
                <div
                  className='btn btn-primary btn-icon rounded-circle text-light'
                  role='button'
                  onClick={() => {
                    if (DEMO_VENDORS.includes(vendor)) {
                      setOpenDemoSnackBar(true);
                    } else {
                      SendMessage()
                    }
                  }}>
                  <ChevronLeftIcon sx={{ fontSize: "28px", fontWeight: 700, transform:i18n.language ==="en"? 'rotate(180deg)':'rotate(0)' }} />
                </div>
              </div>
            </div>
            {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}
          </div>
        </div>

        <div
          className={`chat-info ${Options.showProfileDetail ? "chat-info-visible" : ""}`}>
          <div className='d-flex h-100 flex-column'>
            <div className='chat-info-header px-2'>
              <div className='container-fluid'>
                <ul className='nav justify-content-between align-items-center'>
                  <li className='text-center'>
                    <h5 className='text-truncate mb-0'>Profile Details</h5>
                  </li>
                  <li className='nav-item list-inline-item'>
                    <Link
                      className='nav-link text-muted px-0'
                      to='#'
                      onClick={toggleShowProfileDetail}>
                      <CloseSvg />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='hide-scrollbar flex-fill'>
              <div className='text-center p-3'>
                <div className='avatar avatar-xl mx-5 mb-3'>
                  <img
                    className='avatar-img'
                    src={Receiver.avatar}
                    alt=''></img>
                </div>
                <h5 className='mb-1'>{Receiver.name}</h5>
                {Receiver.companyAddress ? (
                  <div className='text-muted d-flex align-items-center justify-content-center'>
                    <LocationSvg />
                    <span>{Receiver.companyAddress}</span>
                  </div>
                ) : (
                  ""
                )}

                <div className='d-flex align-items-center justify-content-center'>
                  <div className='btn btn-outline-default btn-icon rounded-circle mx-1'>
                    <AddContactSvg />
                  </div>
                  <div className='btn btn-primary btn-icon rounded-circle text-light mx-1'>
                    <HeartSvg />
                  </div>
                  <div className='btn btn-danger btn-icon rounded-circle text-light mx-1'>
                    <BlockSvg />
                  </div>
                </div>
              </div>
              <div className='chat-info-group'>
                <Link
                  className='chat-info-group-header'
                  data-toggle='collapse'
                  to='#'
                  onClick={toggleShowUserInformation}>
                  <h6 className='mb-0'>User Information</h6>
                  <InfoSvg className='hw-20 text-muted' />
                </Link>

                <div
                  className={`chat-info-group-body collapse ${Options.showUserInformation ? "show" : ""}`}>
                  <div className='chat-info-group-content list-item-has-padding'>
                    <ul className='list-group list-group-flush '>
                      <li className='list-group-item border-0'>
                        <p className='small text-muted mb-0'>Phone</p>
                        <p className='mb-0'>+01-222-364522</p>
                      </li>
                      <li className='list-group-item border-0'>
                        <p className='small text-muted mb-0'>Email</p>
                        <p className='mb-0'>catherine.richardson@gmail.com</p>
                      </li>
                      <li className='list-group-item border-0'>
                        <p className='small text-muted mb-0'>Address</p>
                        <p className='mb-0'>
                          1134 Ridder Park Road, San Fransisco, CA 94851
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className='chat-info-group'>
                <Link
                  className='chat-info-group-header'
                  data-toggle='collapse'
                  to='#'
                  onClick={toggleShowLastMedia}>
                  <h6 className='mb-0'>Last Media</h6>
                  <GallarySvg className='hw-20 text-muted' />
                </Link>

                <div
                  className={`chat-info-group-body collapse ${Options.showLastMedia ? "show" : ""}`}
                  id='shared-media'>
                  <div className='chat-info-group-content'>
                    <div className='form-row'>
                      <div className='col-4 col-md-2 col-xl-4'>
                        <Link to='#'>
                          <img
                            src={photo1}
                            className='img-fluid rounded border'
                            alt=''></img>
                        </Link>
                      </div>
                      <div className='col-4 col-md-2 col-xl-4'>
                        <Link to='#'>
                          <img
                            src={photo2}
                            className='img-fluid rounded border'
                            alt=''></img>
                        </Link>
                      </div>
                      <div className='col-4 col-md-2 col-xl-4'>
                        <Link to='#'>
                          <img
                            src={photo3}
                            className='img-fluid rounded border'
                            alt=''></img>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='chat-info-group'>
                <Link
                  className='chat-info-group-header'
                  data-toggle='collapse'
                  to='#'
                  onClick={toggleShowDocuments}>
                  <h6 className='mb-0'>Documents</h6>
                  <DocumentsSvg className='hw-20 text-muted' />
                </Link>

                <div
                  className={`chat-info-group-body collapse ${Options.showDocuments ? "show" : ""}`}
                  id='shared-files'>
                  <div className='chat-info-group-content list-item-has-padding'>
                    <ul className='list-group list-group-flush'>
                      <li className='list-group-item'>
                        <div className='document'>
                          <div className='btn btn-primary btn-icon rounded-circle text-light mr-2'>
                            <ProfileDocumentsSvg />
                          </div>

                          <div className='document-body'>
                            <h6 className='text-truncate'>
                              <Link
                                to='#'
                                className='text-reset'
                                title='effects-of-global-warming.docs'>
                                Effects-of-global-warming.docs
                              </Link>
                            </h6>

                            <ul className='list-inline small mb-0'>
                              <li className='list-inline-item'>
                                <span className='text-muted'>79.2 KB</span>
                              </li>
                              <li className='list-inline-item'>
                                <span className='text-muted text-uppercase'>
                                  docs
                                </span>
                              </li>
                            </ul>
                          </div>

                          <div className='document-options ml-1'>
                            {/* <DocumentOptionDropdown /> */}
                          </div>
                        </div>
                      </li>
                      <li className='list-group-item'>
                        <div className='document'>
                          <div className='btn btn-primary btn-icon rounded-circle text-light mr-2'>
                            <ProfileDocumentsSvg />
                          </div>

                          <div className='document-body'>
                            <h6 className='text-truncate'>
                              <Link
                                to='#'
                                className='text-reset'
                                title='global-warming-data-2020.xlxs'>
                                Global-warming-data-2020.xlxs
                              </Link>
                            </h6>

                            <ul className='list-inline small mb-0'>
                              <li className='list-inline-item'>
                                <span className='text-muted'>79.2 KB</span>
                              </li>
                              <li className='list-inline-item'>
                                <span className='text-muted text-uppercase'>
                                  xlxs
                                </span>
                              </li>
                            </ul>
                          </div>

                          <div className='document-options ml-1'>
                            {/* <DocumentOptionDropdown /> */}
                          </div>
                        </div>
                      </li>
                      <li className='list-group-item'>
                        <div className='document'>
                          <div className='btn btn-primary btn-icon rounded-circle text-light mr-2'>
                            <ProfileDocumentsSvg />
                          </div>

                          <div className='document-body'>
                            <h6 className='text-truncate'>
                              <Link
                                to='#'
                                className='text-reset'
                                title='great-presentation-on global-warming-2020.ppt'>
                                Great-presentation-on global-warming-2020.ppt
                              </Link>
                            </h6>
                            <ul className='list-inline small mb-0'>
                              <li className='list-inline-item'>
                                <span className='text-muted'>79.2 KB</span>
                              </li>
                              <li className='list-inline-item'>
                                <span className='text-muted text-uppercase'>
                                  ppt
                                </span>
                              </li>
                            </ul>
                          </div>

                          <div className='document-options ml-1'>
                            {/* <DocumentOptionDropdown /> */}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default UserChat;
