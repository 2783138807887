import React, { useState, useEffect } from "react";
import { getPropertyTypesForCategory } from "../../../../api/propertyApi";
import { Link, useNavigate } from "react-router-dom";
import { Plus } from "../../../../constants/icons";
import { Dialog, DialogContent, DialogTitle, Fab, Tab, Tabs } from "@mui/material";
import { Close } from "@mui/icons-material";
import { renderPropertyIcon } from "../../../../helpers";
import { canAddProperty, getUserProile } from "../../../../api/userApi";
import { useAuth } from "../../../../helpers/context";
import { toast } from "react-toastify";
import VerifyPopup from "../../../../components/user-components/verify-popup";
import SubscriptionPopup from "../../../../components/user-components/subscription-popup";
import { useTranslation } from "react-i18next";

export default function AddPropertyBtn(props) {
  const [PropertyType, setPropertyType] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [ADType, setADType] = React.useState("2");
  const [isUserVerified, setIsUserVerified] = useState(true);
  const { token, UserType } = useAuth();
  const [openVerifyModal, setOpenVerifyModal] = useState(false);
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const history = useNavigate();
  const [errorMsg, setErrorMsg] = useState('');
  const { vendor } = useAuth();
  const { t, i18n } = useTranslation();
  
  useEffect(() => {
    getUserProile({
      userId: token,
      language: i18n.language === 'ar' ? '0' : '1',
    }).then((_userInfo) => {
      if (_userInfo.resCode === 0) {
        if (
          _userInfo.response.userProfile.userType !== "1" &&
          _userInfo.response.userProfile.isUserVerified !== "3"
        )
          setIsUserVerified(false);
      } else {
        toast.error(_userInfo.resStr);
      }

      getPropertyTypesForCategory({
        category: "1,2",
        language: i18n.language === 'ar' ? '0' : '1',
      }).then((_PropertyType) => setPropertyType(_PropertyType.response));
    });
  }, [token, i18n]);

  const handleClickOpen = () => {
    // setOpen(true);
    canAddProperty({ userId: token, language: i18n.language === 'ar' ? '0' : '1' }).then((result) => {
      if (result.resCode === 0) {
        if (result.response.result === '1') {
          history(props.isAdmin ? "/admin/property/add/" : "/add-property/");
        } else {
          setOpenSubscriptionModal(true);
          setErrorMsg(result.resStr);
        }
      } else {
        toast.error(result.resStr);
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const path = props.isAdmin ? "/admin/property/add/" : "/add-property/";
  const tabStyle = {
    fontSize: "16px",
    "&.Mui-selected": {
      color: "var(--main-color-one)",
    },
  };

  return (
    <>
      {isUserVerified ? (
        <SubscriptionPopup
          setOpenSubscriptionModal={setOpenSubscriptionModal}
          openSubscriptionModal={openSubscriptionModal}
          desc={UserType === '5' ?
            <>
              {errorMsg}
              <br />  تواصل مع مدير الحساب لترقية الاشتراك
            </> :
            errorMsg
          }
          trigger={
            vendor === "amakkn" ? <button className='px-3 primary-btn' onClick={handleClickOpen}>
              <Plus />
              <span>{t("NewAD")}</span>
            </button> :
              <Fab
                size="large"
                color="primary"
                className="ok-fab"
                onClick={handleClickOpen}
              >
                <Plus sx={{ fontSize: 30 }} />
                <span>{t("NewAD")}</span>
              </Fab>
          }
        />
      ) : (
        <VerifyPopup
          openVerifyModal={openVerifyModal}
          setOpenVerifyModal={setOpenVerifyModal}
          desc={"يجب عليك توثيق حسابك لكي تتمكن من اضافة إعلانك العقاري"}
          trigger={
            vendor === "amakkn" ? <button
              className='px-3 primary-btn'
              onClick={() => setOpenVerifyModal(true)}>
              <Plus />
              <span>{t("NewAD")}</span>
            </button> :
              <Fab
                size="large"
                color="primary"
                className="ok-fab"
                onClick={() => setOpenVerifyModal(true)}
              >
                <Plus sx={{ fontSize: 30 }} />
                <span>{t("NewAD")}</span>
              </Fab>
          }
        />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        className='custom-dialog add-property'
        onClose={handleClose}>
        <DialogTitle>
          <div
            className='dialog-head justify-content-center'
            style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
            <Tabs
              onChange={(event, newValue) => {
                setADType(newValue);
              }}
              centered
              value={ADType}
              sx={{
                width: "auto",
              }}
              TabIndicatorProps={{
                style: { background: "var(--main-color-one)" },
              }}>
              <Tab value={"2"} label={t("ForSale")} sx={tabStyle} />
              <Tab value={"1"} label={t("ForRent")} sx={tabStyle} />
            </Tabs>
            <span className='close-icon' onClick={handleClose}>
              <Close />
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className='property-types-boxes'>
            {PropertyType.map((_PropertyType, index) => (
              <Link
                className='property-type-box'
                to={`${path}${ADType}/${_PropertyType.key}`}
                key={index}>
                {renderPropertyIcon(_PropertyType.key)}
                <span>{_PropertyType.name}</span>
              </Link>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}