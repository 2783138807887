import { Box } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import AppLayout from "../../components/layouts/app-layout";
import AboutUs from "../../components/section-components/about-us";
import { ListArrow } from "../../constants/icons";
import { useAuth } from "../../helpers/context";
import { useTranslation } from "react-i18next";

// getAboutUsAndTerms("1").then((_DefLocation) => {
//   if (_DefLocation.resCode === 0) {
//     setContent(_DefLocation.response.extra.content);
//   }
// });

const AboutPage = () => {

  const { vendor } = useAuth();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  
  return (
    <AppLayout pageTitle={t("AboutUS")} withFooter>
      {vendor ? vendor === "amakkn" ? (
        <div className="page-width favorite-area pd-top-90 mg-bottom-100 text-center">
          <Box
            sx={{
              marginBottom: "40px",
              textAlign: "center !important",
              fontSize: "28px",
              fontWeight: 600,
              color: "#000",
            }}>
            {t("AboutAmakkn")}
          </Box>
          <div className='row align-items-center justify-content-end pd-top-30 m-0'>
            <div className="col-12 p-0 mb-2">
              <div className='custom-card' style={{ minHeight: 'auto' }}>
                <div className="card-header">
                  <h4 style={{ color: 'var(--main-color-one)' }}>{t("AboutAmakkn")}</h4>
                </div>
                <div className="card-body">
                  <p>{t("AboutAmakknDesc")}</p>
                </div>
              </div>
            </div>
            <div className="col-12 p-0 mb-2">
              <div className='custom-card' style={{ minHeight: 'auto' }}>
                <div className="card-header">
                  <h4 style={{ color: 'var(--main-color-one)' }}>{t("Vision")}</h4>
                </div>
                <div className="card-body">
                  <p>{t("VisionDesc")}</p>
                </div>
              </div>
            </div>
            <div className="col-12 p-0 mb-2">
              <div className='custom-card' style={{ minHeight: 'auto' }}>
                <div className="card-header">
                  <h4 style={{ color: 'var(--main-color-one)' }}>{t("Strategy")}</h4>
                </div>
                <div className="card-body">
                  <ul className="custom-list">
                    <li><ListArrow style={i18n.language ==="en"? {transform:'rotate(180deg)'}:{}} /> {t("Strategy1")}</li>
                    <li><ListArrow style={i18n.language ==="en"? {transform:'rotate(180deg)'}:{}} /> {t("Strategy2")}</li>
                    <li><ListArrow style={i18n.language ==="en"? {transform:'rotate(180deg)'}:{}} /> {t("Strategy3")}</li>
                  </ul>
                </div>
              </div>
            </div>
       
          </div>
        </div>
      ) : (<AboutUs />) : <></>}
    </AppLayout>
  );
}

export default AboutPage;
