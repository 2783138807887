import firebase from "firebase/compat/app";
import "firebase/compat/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Dev
// const firebaseConfig = {
//   apiKey: "AIzaSyCYbX7lYFkr1d-DjBwKywnPSxMLKno3Mg0",
//   authDomain: "amakkn-dev.firebaseapp.com",
//   databaseURL: "https://amakkn-dev.firebaseio.com",
//   projectId: "amakkn-dev",
//   storageBucket: "amakkn-dev.appspot.com",
//   messagingSenderId: "210365158326",
//   appId: "1:210365158326:web:f094ac042516bf37e5a717",
//   measurementId: "G-X24KTYJYEC",
// };
// const VapidKey =  "BOg_Ej5eN1gm5mtOQ3A_KZIcy9w0RblqbVBBeDSYbc7bNoAfx3UJuGb-7ojaXbMfDLb64D6AVkgapvpxMhuMOrI";

// Prod

const firebaseConfig = {
  apiKey: "AIzaSyBHEdJYUhC4TUpnv2J1dnJ_2S7WBV2uIY0",
  authDomain: "amakkn-59b4c.firebaseapp.com",
  databaseURL: "https://amakkn-59b4c.firebaseio.com",
  projectId: "amakkn-59b4c",
  storageBucket: "amakkn-59b4c.appspot.com",
  messagingSenderId: "438426910927",
  appId: "1:438426910927:web:9875bb6de4cc7b80",
  measurementId: "G-0PH7EQ92TM",
};
const VapidKey =
  "BJK6ZlY3UGnB_hbo2YD_1cW6ynlPLzYeZcTZNNe7VXQrKLcJZ4oyy6HsF-uUTy57hvIZCFkwCB5z5gvMhoeZGBY";

firebase.initializeApp(firebaseConfig);
let messaging = null;
if (firebase.messaging.isSupported()) messaging = firebase.messaging();

export const getToken = (setTokenFound) => {
  if (messaging)
    return messaging
      .getToken({
        vapidKey: VapidKey,
      })
      .then((currentToken) => {
        if (currentToken) {
          // console.log("current token for client: ", currentToken);
          setTokenFound(currentToken);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          setTokenFound(currentToken);
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // catch error while creating client token
      });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
