import i18next from "i18next";
import { handleResponse, handleError } from "./apiUtils";
const baseUrl = window.location.origin.includes('amakkncompany.com') ? 'https://devsa2.amakkn.com' : process.env.REACT_APP_API_URL;

export function getLocation() {
  return fetch(
    "https://api.ipstack.com/check?access_key=d45431c2eba9c071c9cfe736f2dde3e3"
  )
    .then(handleResponse);
    // .catch(handleError);
}

export function getAboutUsAndTerms(type) {
  const data = { platform: "1", language: i18next.language === 'ar' ? '0' : '1', type: type };
  return fetch(baseUrl + "/Extras/getExtraInfoForPlatformAndTypeAndLanguage/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(data),
  })
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getListOfAllSubjects(props) {
  return fetch(baseUrl + "/Feedback/getListOfAllSubjects/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function sendFeedback(props) {
  return fetch(baseUrl + "/Feedback/sendFeedback/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}
export function saveOrder(props) {
  return fetch(baseUrl + "/Orders/saveOrder/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}
export function checkPayment(props) {
  return fetch(baseUrl + "/Orders/checkPayment/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}
export function getUserPayments(props) {
  return fetch(baseUrl + "/Orders/getUserPayments/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}
export function changeOrderStatus(props) {
  return fetch(baseUrl + "/Orders/changeOrderStatus/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}
