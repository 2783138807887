import React from 'react'
import CookieConsent from "react-cookie-consent";
import { useTranslation } from 'react-i18next';

export default function CookieConsentBar() {
    const { t } = useTranslation();

    return (
        <CookieConsent
            buttonText={t("Confirm")}
            overlay={false}
            buttonClasses="primary-btn py-2 px-4"
            cookieName="AmmaknConsent"
        >
            <div className='cookie-wrapper'>
                {t("WelcomeToAmakknWeUseCookiesToImproveYourExperienceOnOurSite")}
                &nbsp;<span style={{ fontSize: "10px" }}>({t("PleaseClickTheConfirmButton")})</span>
            </div>
        </CookieConsent>
    );
}
