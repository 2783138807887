import React from "react";
import { useTranslation } from "react-i18next";

const ADConstraints = ({ onOK, project }) => {
  const { t } = useTranslation();

  return (
    <div className='custom-card ad-constraints'>
      <div className='card-header'>
        <h4>{t("RealEstateAdvertisingControls")}</h4>
        <h6>{t("OnceYouClickNextYouAgreeToAllOfTheseTermsAndConditions")}</h6>
      </div>
      <div className='card-body'>
        <div>
          <ul>
            <li>{t("TheADInformationIsCorrect")}</li>
            <li>{t("TheRealEstateADMustBeRemovedAfterPurposeHasBeenFulfilled")}</li>

            {!project && (
              <>
                <li>{t("TheADWillBeAutomaticallyDeletedAccordingToTheExpirationDateOfTheAdvertisementLicense")}</li>
                <li>{t("TheADWillBeBlockedIfTheLicenseOfFALBrokerageAndMarketingExpires")}</li>
              </>
            )}

            <li>
              {t("AdherenceToAllProvisions4")}
              <a
                style={{ color: "var(--main-color-one)", textDecoration: "underline" }}
                target="_blank"
                rel="noreferrer"
                href='https://amakknbackendupload.s3.me-south-1.amazonaws.com/brokerage.pdf'>
                {t("AdherenceToAllProvisions5")}
              </a>
              {t("AND")}
              <a
                style={{ color: "var(--main-color-one)", textDecoration: "underline" }}
                target="_blank"
                rel="noreferrer"
                href='https://amakknbackendupload.s3.me-south-1.amazonaws.com/regulations.pdf'>
                {t("AdherenceToAllProvisions6")}
              </a>
            </li>
            <li> {t("AdherenceToAllProvisions1")}
              <a
                style={{ color: "var(--main-color-one)", textDecoration: "underline" }}
                target="_blank"
                rel="noreferrer"
                href='https://amakknbackendupload.s3.me-south-1.amazonaws.com/rega.pdf'>
                {t("AdherenceToAllProvisions2")}
              </a>
              {t("AdherenceToAllProvisions3")}
            </li>
            <li>
              {t("IAgreeToTheTermsAndConditionsOfTheAmakknPlatformUsageAgreement1")}
              <a
                style={{ color: "var(--main-color-one)", textDecoration: "underline" }}
                target="_blank"
                rel="noreferrer"
                href='https://www.amakkn.com/terms'>
                {t("IAgreeToTheTermsAndConditionsOfTheAmakknPlatformUsageAgreement2")}
              </a>.
            </li>
          </ul>
        </div>
      </div>
      <div className='card-footer'>
        <button className='primary-btn px-5' onClick={() => onOK()}>
          {t("Next")}
        </button>
      </div>
    </div>
  );
}

export default ADConstraints;
