import { createTheme } from "@mui/material/styles";
import {
  blue,
  grey,
  pink,
  red,
  deepOrange,
  orange,
  teal,
  green,
  amber,
  lime,
  purple,
} from "@mui/material/colors";
import React from "react";
import { useContext } from "react";
import i18next from "i18next";

export const availableThemes = [
  {
    title: "Default",
    primary: blue,
    secondary: pink,
  },
  {
    title: "Sunset",
    primary: deepOrange,
    secondary: orange,
  },
  {
    title: "Greeny",
    primary: teal,
    secondary: green,
  },
  {
    title: "Beach",
    primary: grey,
    secondary: amber,
  },
  {
    title: "Tech",
    primary: blue,
    secondary: lime,
  },
  {
    title: "Deep Ocean",
    primary: purple,
    secondary: pink,
  },
];

const defaultTheme = {
  direction: i18next.language ==="ar"? "rtl":"ltr",
  palette: {
    primary: blue,
    secondary: pink,
    lightBG: "#F2F5F5",
    darkBG: "rgba(0, 0, 0, 0.003)",
  },
  error: red,
  appBar: {
    height: 57,
    color: blue[600],
  },
  drawer: {
    width: 240,
    color: grey[900],
    backgroundColor: grey[900],
    miniWidth: 56,
  },
  raisedButton: {
    primaryColor: blue[600],
  },
  typography: {
    useNextVariants: true,
    fontFamily: ["Amazon Ember"].join(","),
  },
};
const theme = createTheme(defaultTheme);

export const customTheme = (primary,font) => {
  return createTheme({
    direction: i18next.language ==="ar"? "rtl":"ltr",
    palette: {
      primary: { main: primary ? primary : "#0000ff" },
      secondary: pink,
      lightBG: "#F2F5F5",
      darkBG: "rgba(0, 0, 0, 0.003)",
    },
    error: blue,
    appBar: {
      height: 57,
      color: blue[600],
    },
    drawer: {
      width: 240,
      color: grey[900],
      backgroundColor: grey[900],
      miniWidth: 56,
    },
    raisedButton: {
      primaryColor: blue[600],
    },
    typography: {
      useNextVariants: true,
      fontFamily: [font ? font:"Amazon Ember"].join(","),
    },
  });
};

export const AppContext = React.createContext({
  theme: customTheme(),
  setTheme: () => { },
  token: undefined,
  setToken: (val) => { },
  UserId: undefined,
  setUserId: (val) => { },
  logo: undefined,
  setLogo: (val) => { },
  paymentData: undefined,
  setPaymentData: (val) => { },
  vendor: undefined,
  setVendor: (val) => { },
  currentDomain: undefined,
  setCurrentDomain: (val) => { },
  unreadMessagesCount: undefined,
  setUnreadMessagesCount: (val) => { },
  planEndDate: undefined,
  setPlanEndDate: (val) => { },
  currentPlan: undefined,
  setCurrentPlan: (val) => { },
  UserType: undefined,
  setUserType: (val) => { },
  AccountType: undefined,
  setAccountType: (val) => { },
  MenuData: undefined,
  setMenuData: (val) => { },
  ShowMenuPhone: undefined,
  setShowMenuPhone: (val) => { },
  iconPackage: undefined,
  setIconPackage: (val) => { },
  templateId: undefined,
  setTemplateId: (val) => { },
  loadingTemplate: undefined,
  setLoadingTemplate: (val) => { },
});



export const useAuth = () => {
  return useContext(AppContext);
}

export default theme;
