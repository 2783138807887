import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  // getListOfChannelsForCustomerSupport,
  initiateSupportChatChannel,
  // getListOfSubjects,
} from "../../../api/userApi";
// import { ProfileType, UserStatus } from "../../../Config/Config";
// import ChatMessage from "./Message/ChatMessage";
// import { Link } from "react-router-dom";
// import moment from "moment";
// import Button from "@mui/material/Button";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import { CircularProgress } from "@mui/material";
import { useAuth } from "../../../helpers/context";
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";

const ChatList = (props) => {
  const { i18n } = useTranslation();
  const history = useNavigate();
  const ENDPOINT = process.env.REACT_APP_CHAT_URL;
  const { token, UserId } = useAuth();
  const [user] = useState({
    userId: token,
    language: i18n.language === 'ar' ? '0' : '1',
    page: "1",
    pageSize: "50",
  });
  // const [Mesg, setMesg] = useState("wait");
  const socketRef = useRef();

  // const [Chats, setChats] = useState([]);
  // const [Subjects, setSubjects] = useState([]);
  // const [selected, setSelected] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // function getDateName(unix_timestamp) {
  //   var date = moment(unix_timestamp, "YYYY-MM-DD HH:mm").toDate();
  //   var todaysDate = new Date();
  //   var Yesterday = new Date(new Date().setDate(todaysDate.getDate() - 1));
  //   if (date.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0))
  //     return new Intl.DateTimeFormat("ar-SY", {
  //       hour: "numeric",
  //       minute: "numeric",
  //     }).format(moment(unix_timestamp, "YYYY-MM-DD HH:mm").toDate());
  //   else if (date.setHours(0, 0, 0, 0) === Yesterday.setHours(0, 0, 0, 0))
  //     return "الأمس";
  //   else
  //     return new Intl.DateTimeFormat("ar-SY", {
  //       year: "numeric",
  //       month: "2-digit",
  //       day: "2-digit",
  //     }).format(date);
  // }

  useEffect(() => {
    initiateSCChannel();

  },
    // eslint-disable-next-line
    []);
  
  useEffect(() => {
    if (token) {
      socketRef.current = window.io(ENDPOINT);
      socketRef.current.emit("inboxRefresh", {
        myUserId: UserId,
      });
      // socketRef.current.on("inboxRefresh", (msg) => {
      //   setTimeout(function () {
      //     getListOfChannelsForCustomerSupport(user).then((_userInfo) => {
      //       if (_userInfo.resCode === 0) {
      //         let chat = [];
      //         chat = _userInfo.response.chatArray;
      //         if (chat.length > 0) {
      //           let chatArray = [];
      //           chat.forEach((element) => {
      //             let reciverName = element.userName1;
      //             let unread = element.readByUser1;
      //             let propertyImage =
      //               publicUrl +
      //               "assets/img/defimgs/" +
      //               (parseInt(element.userType1) > 3 ? "2" : "1") +
      //               "_account.svg";
      //             if (element.userAvatar1) propertyImage = element.userAvatar1;

      //             if (element.userId1 === UserId) {
      //               reciverName = element.userName2;
      //               unread = element.readByUser2;

      //               propertyImage =
      //                 publicUrl +
      //                 "assets/img/defimgs/" +
      //                 (parseInt(element.userType2) > 3 ? "2" : "1") +
      //                 "_account.svg";
      //               if (element.userAvatar2)
      //                 propertyImage = element.userAvatar2;
      //             }
      //             chatArray.push({
      //               ChatUserId: element.channelId,
      //               to: "/CSChats/" + element.channelId,
      //               name: reciverName,
      //               profile: propertyImage,
      //               profileType: ProfileType.Image,
      //               time: getDateName(element.updatedAt),
      //               message: element.lastMessage,
      //               status: UserStatus.Online,
      //               subject: element.subject,
      //               badge: parseInt(unread),
      //             });
      //           });
      //           // setChats(chatArray);
      //           history(chatArray[0].to);
      //         }
      //         // else {
      //         //   setMesg("لم يتم العثور على رسائل");
      //         // }
      //       } else {
      //         const _errors = {};
      //         _errors.phone = _userInfo.resStr;
      //       }
      //     });
      //   }, 350);
      // });

      // getListOfSubjects({ language: "0" }).then((_userInfo) => {
      //   if (_userInfo.resCode === 0) {
      //     setSubjects(_userInfo.response);
      //   }
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserId, publicUrl, token, user]);
  // Get userid from parameter to load perticular user chat history

  function initiateSCChannel(subjectId) {
    initiateSupportChatChannel({
      userId1: token,
      userId2: "1",
      subjectId: '4',
    }).then((_userInfo) => {
      if (_userInfo.resCode === 0 || _userInfo.resCode === 30) {
        history("/CSChats/" + _userInfo.response.chat.channelId);
        // handleClose();
      }
    });
  }
  // function handleChatClick(id) {
  //   var newChats = Chats.map((el) => {
  //     if (el.ChatUserId === id) return Object.assign({}, el, { badge: 0 });
  //     return el;
  //   });
  //   setChats(newChats);
  //   setSelected({ id: id, selected: true });
  //   props.handleChatClick();
  // }
  return <></>;
  // return token && (
  //   <aside className='sidebar'>
  //     <div className='tab-content'>
  //       <div className='tab-pane active' id='chats-content'>
  //         <div className='d-flex flex-column h-100'>
  //           <div
  //             className='h-100'
  //             id='chatContactsList'
  //             style={{ backgroundColor: "#fff" }}>
  //             <div
  //               className='sidebar-header sticky-top'
  //               style={{
  //                 height: "81px",
  //                 padding:'24px'
  //               }}>
  //               <div className='d-flex justify-content-between align-items-center'>
  //                 <Link className='contacts-link' to='/'>
  //                   <button
  //                     className='btn btn-secondary btn-icon btn-minimal btn-sm text-muted'
  //                     style={{
  //                       paddingTop: "8px",
  //                       marginLeft: "8px",
  //                     }}
  //                     type='button'>
  //                     <ArrowBack style={{ transform: 'rotate(180deg)' }} />
  //                   </button>
  //                 </Link>
  //                 <h5
  //                   className='font-weight-semibold mb-0  w-100'
  //                   style={{
  //                     paddingTop: "8px",
  //                     textAlignLast: "right",
  //                     marginRight: "20px",
  //                   }}>
  //                   صندوق الرسائل
  //                 </h5>
  //               </div>
  //             </div>

  //             {/* <div className='text-align-last-center mt-3'>
  //               <Button
  //                 aria-controls='simple-menu'
  //                 aria-haspopup='true'
  //                className="btn primary-btn px-3 mb-3"
  //                 onClick={handleClick}>
  //                 إضافة مشكلة جديدة
  //               </Button>
  //               <Menu
  //                 id='simple-menu'
  //                 anchorEl={anchorEl}
  //                 keepMounted
  //                 open={Boolean(anchorEl)}
  //                 onClose={handleClose}>
  //                 {Subjects.map((item, i) => (
  //                   <MenuItem
  //                     key={i}
  //                     onClick={(e) => {
  //                       addNew(item.subjectId);
  //                     }}>
  //                     {item.subject}
  //                   </MenuItem>
  //                 ))}
  //               </Menu>
  //             </div> */}

  //             <ul
  //               className='contacts-list'
  //               id='chatContactTab'
  //               data-chat-list=''>
  //               {Chats.length > 0 ? (
  //                 Chats.map((chat, index) => {
  //                   return (
  //                     <ChatMessage
  //                       key={"chat" + index}
  //                       {...chat}
  //                       handleChatClick={() => {
  //                         handleChatClick(chat.ChatUserId);
  //                       }}
  //                       selected={selected}
  //                     />
  //                   );
  //                 })
  //               ) : (
  //                 <div>
  //                   <h6 className='text-align-last-center mt-2'>
  //                   {Mesg==='wait' ? <CircularProgress/> : Mesg}
  //                   </h6>
  //                 </div>
  //               )}
  //             </ul>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </aside>
  // );
};
export default ChatList;
