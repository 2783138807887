import React, { useEffect, useState } from "react";
import PropertyDetailsVersion1 from "./components/property-details";
import { useParams } from "react-router-dom";
import AppLayout from "../../../components/layouts/app-layout";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../helpers/context";
import PropertyDetailsVersion2 from "./components/property-details-v2";
import { CLASSIC2_TEMPLATES, CLASSIC_TEMPLATES, MODERN2_TEMPLATES } from "../../../constants";
import PropertyDetailsVersion3 from "./components/property-details-v3";

const isAdmin = window.location.href.includes("admin");

const PropertyDetails = () => {
  let { id } = useParams();
  const [propertyId, setPropertyId] = useState(id);
  const { t } = useTranslation();
  const { templateId } = useAuth();

  useEffect(() => {
    setPropertyId(id);
  }, [id]);
  return (
    <AppLayout pageTitle={t("ADDetails")} withoutNav={isAdmin} unsetOverflow>
      {[...CLASSIC2_TEMPLATES].includes(+templateId) && !isAdmin ?
        <PropertyDetailsVersion3 id={propertyId} /> :
        [...CLASSIC_TEMPLATES, ...MODERN2_TEMPLATES].includes(+templateId) && !isAdmin ?
          <PropertyDetailsVersion2 id={propertyId} /> :
          <PropertyDetailsVersion1 id={propertyId} />
      }
    </AppLayout>
  );
}

export default PropertyDetails;
