import React from "react";
import MyFavorites from "./components/my-favorite";
import AppLayout from "../../../components/layouts/app-layout";
import { useAuth } from "../../../helpers/context";
import { useTranslation } from "react-i18next";

const MyFavorite = () => {
  const { token } = useAuth();
  const { t } = useTranslation();
  
  return (
    <AppLayout needAuth pageTitle={t("Favorites")}>
      {token && (<MyFavorites userId={token} />)}
    </AppLayout>
  );
};

export default MyFavorite;
