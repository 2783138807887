import { Close } from "@mui/icons-material";
import { Button, Dialog, DialogContent, IconButton } from "@mui/material";
import React from "react";
import { MosquesBG } from "../../constants/backgrounds";
import { confirmAlert } from "react-confirm-alert";
import { getUserProile, saveProfile } from "../../api/userApi";
import { useEffect } from "react";
import { useState } from "react";
import { useAuth } from "../../helpers/context";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export default function ChangeTypePopup({ title, trigger, setOpenChangeTypeModal, openChangeTypeModal, desc }) {
  const [user, setUser] = useState(undefined);
  const { token, setAccountType, setUserType } = useAuth();
  const history = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (openChangeTypeModal && !user) {
      getUserProile({
        userId: token,
        language: i18n.language === 'ar' ? '0' : '1'
      }).then(_userInfo => {
        if (_userInfo.resCode === 0) {
          setUser(_userInfo.response.userProfile);
        } else {
          toast.error(_userInfo.resStr);
        }
      });
    }
  },// eslint-disable-next-line
    [openChangeTypeModal, token]);

  const CompaniesOptions = {
    customUI: ({ onClose }) => {
      return (
        <div className='custom-ui'>
          <h3>{t("ChangeAccountType")}</h3>
          <h5 style={{ marginBottom: "15px" }}>{t("ChangeAccountType1")}</h5>
          <p className="p-0" style={{ color: '#d00404' }}>
            <span style={{ fontWeight: 700, fontSize: '20px', position: 'relative', top: '2px' }}> * </span>
            {t("ChangeAccountType2")}
          </p>
          {/* <p className="p-0" style={{ color: '#d00404' }}>
            <span style={{ fontWeight: 700, fontSize: '20px', position: 'relative', top: '2px' }}> * </span>
            في حال كان قمت بإنشاء علامتك التجاري لدينا, فسوف تعاود ادخال بيانات القالب الخاص بك
          </p> */}
          <div className='btns-wrapper'>
            <button
              className='outlined-btn px-4'
              onClick={() => {
                onClose();
                                              
              }}>
              {t("Cancel")}
            </button>
            <button
              className='primary-btn px-3'
              onClick={() => {
                saveProfile({
                  ...user,
                  userId: token,
                  userType: "4",
                  accountType: "2",
                }).then((_userInfo) => {
                  toast.success(t("EditedSuccessfully"));
                  setAccountType('2');
                  setUserType('4');
                  history("/verify");
                });
                onClose();
              }}
            >
              {t("Continue")}
            </button>
          </div>
          <div>
          </div>
        </div>
      );
    },
  };

  return (
    <>
      {trigger}
      <Dialog
        onClose={() => setOpenChangeTypeModal(false)}
        className="custom-dialog no-padding full-screen2"
        open={openChangeTypeModal}
      >
        <DialogContent sx={{ padding: 0, overflow: 'hidden' }}>
          <div className='modal2 '>
            <IconButton className='close' onClick={() => setOpenChangeTypeModal(false)}>
              <Close />
            </IconButton>
            <div className='login-modal'>
              <div className='col-12 col-sm-9 p-0'>
                <h4>
                  {title || t("ChangeAccountType")}
                </h4>
                <p>{desc}</p>
                <Button
                  onClick={() => {
                    confirmAlert(CompaniesOptions);
                    setOpenChangeTypeModal(false);
                  }}
                  className="mt-4 btn primary-btn px-3 mx-auto w-50 justify-content-center"
                >
                  {title ? t("UpgradeNow") : t("ChangeNow")}
                </Button>
              </div>
            </div>
            <div className="col-12 bottom-image">
              <MosquesBG />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}