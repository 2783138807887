import React, { useState } from "react";
import PropTypes from "prop-types";
import { Alert, Box, FormControl, FormHelperText, IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingButton from "../../global-components/loading-btn";
import { DARK_TEMPLATES } from "../../../constants";
import { useAuth } from "../../../helpers/context";
import { useTranslation } from "react-i18next";

function PasswordForm(props) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { templateId } = useAuth();
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div style={{ maxHeight: '100vh', overflowY: 'auto', padding: props.admin ? 0 : '20px 10% 70px', width: '100%' }}>
      <div className={props.admin ? '' : 'container'} >
        <h4 className={`page-heading ${props.admin ? 'text-center' : ""}`}>{t("SetPassword")}</h4>
        <p>{t("PasswordMustNotBeLessThan6Characters")}</p>
        <form onSubmit={props.onSubmit}>
          {props.admin ? (
            <>
              <div className='col-12 p-0 mt-4'>
                <div className='rld-single-input'>
                  <TextField
                    type={showPassword ? 'text' : 'password'}
                    className="without-label"
                    name="password"
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '100%', bgcolor: DARK_TEMPLATES.includes(+templateId) ? 'var(--main-color-two)' : '#fff', '& input': { border: 'none', '&:hover': { border: 'none' }, '&:focus': { border: 'none' } } }}
                    value={props.user.password}
                    error={props.errors.password ? true : false}
                    helperText={props.errors.password}
                    placeholder={t("EnterThePassword")}
                    onChange={props.onChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
              </div>
              
              <div className='col-12 p-0 mt-4'>
                <div className='rld-single-input'>
                  <TextField
                    type={showConfirmPassword ? 'text' : 'password'}
                    className="without-label"
                    name="confirmpassword"
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '100%', bgcolor: DARK_TEMPLATES.includes(+templateId) ? 'var(--main-color-two)' : '#fff', '& input': { border: 'none', '&:hover': { border: 'none' }, '&:focus': { border: 'none' } } }}
                    value={props.user.confirmpassword}
                    error={props.errors.confirmpassword ? true : false}
                    helperText={props.errors.confirmpassword}
                    placeholder={t("ReEnterThePassword")}
                    onChange={props.onChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
              </div>
              <LoadingButton
                classes='mt-4 btn primary-btn px-3 w-100 justify-content-center'
                label={t("Continue")}
                loading={props.isSubmitting || false}
                handleClick={props.onSubmit}
              />
            </>
          ) : (
            <>
              <div className="col-12 col-md-6 col-lg-7 col-xl-6 mb-4 p-0">
                <FormControl
                  error={props.errors.password && Boolean(props.errors.password)}
                  className="col-12"
                >
                  <TextField
                    id="password"
                    InputLabelProps={{ shrink: true }}
                    label={t("EnterThePassword")}
                    onChange={props.onChange}
                    name="password"
                    size="small"
                    sx={{ width: '100%', '& input': { border: 'none', '&:hover': { border: 'none' }, '&:focus': { border: 'none' } } }}
                    type={showPassword ? 'text' : 'password'}
                    value={props.user.password}
                    error={props.errors.password && Boolean(props.errors.password)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {props.errors.password && Boolean(props.errors.password) &&
                    <FormHelperText component={Box}>
                      <Alert severity="error" className="custom-alert">
                        {props.errors.password}
                      </Alert>
                    </FormHelperText>}
                </FormControl>
              </div>
              <div className="col-12 col-md-6 col-lg-7 col-xl-6 mb-4 p-0">
                <FormControl
                  error={props.errors.confirmpassword && Boolean(props.errors.confirmpassword)}
                  className="col-12"
                >
                  <TextField
                    id="confirmpassword"
                    label={t("ReEnterThePassword")}
                    InputLabelProps={{ shrink: true }}
                    onChange={props.onChange}
                    name="confirmpassword"
                    size="small"
                    sx={{ width: '100%', '& input': { border: 'none', '&:hover': { border: 'none' }, '&:focus': { border: 'none' } } }}
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={props.user.confirmpassword}
                    error={props.errors.confirmpassword && Boolean(props.errors.confirmpassword)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {props.errors.confirmpassword && Boolean(props.errors.confirmpassword) &&
                    <FormHelperText component={Box}>
                      <Alert severity="error" className="custom-alert">
                        {props.errors.confirmpassword}
                      </Alert>
                    </FormHelperText>}
                </FormControl>
              </div>
              <div style={{ textAlign: "center" }}>
                <LoadingButton
                  id="nextFromPass"
                  type="submit"
                  classes={"primary-btn px-5 py-2 d-flex justify-content-center mt-2"}
                  label={t("Next")}
                  loading={props.isSubmitting || false}
                />
              </div>
            </>
          )}
      
        </form>
      </div>
    </div>
  );
}

PasswordForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default PasswordForm;
